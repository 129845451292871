import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { save } from '../components/helpers/disable-save.interceptor';
import { ActionResponse, Customer } from '../models';
import { Response } from '../models/types/common';
import { ResourceService } from './resource.service';

@Injectable()
export class CustomerService  {
  constructor(private resourceService: ResourceService, private http: HttpClient) {
  }

  getCustomersCount(): Observable<number>{
    return this.http.get<number>(this.resourceService.getApiUrl()+"/customer/count")
 }


  getCustomers(page:number,count:number,order='name',direction='asc'): Observable<Customer[]>{
     return this.http.get<Customer[]>(this.resourceService.getApiUrl()+`/customer?page=${page}&count=${count}&order=${order}&direction=${direction}`)
  }

  getCustomer(customerId: number): Observable<Customer> {
    return this.http.get<Customer>(this.resourceService.getApiUrl() + '/customer/' + customerId);
  }

  updateCustomer(customerId:number,customer: Customer) {
    const context = new HttpContext().set(save, true);
    return this.http.post(this.resourceService.getApiUrl() + '/customer/'+ customerId, customer, { context });
  }

  getCustomersByOperatorId(operatorId: any, page: number = 1, count: number = 20,id_list=true): Observable<Response<Customer>> {
    return this.http.get<Response<Customer>>(this.resourceService.getApiUrl() + `/customer?operator_id=${operatorId}&page=${page}&count=${count}&id_list=${id_list}`);
  }

  createCustomer(customer: Customer):Observable<any> {
    const context = new HttpContext().set(save, true);
    return this.http.put(this.resourceService.getApiUrl()+'/customer',customer, { context })
  }

  saveUser(customer: Customer) {
    return this.http.put(this.resourceService.getApiUrl() + '/customers/', customer);
  }

  deleteCustomer(customerId: number): Observable<any> {
    return this.http.delete(this.resourceService.getApiUrl() + '/customer/' + customerId)
  }

  deleteCustomers(customerIds: number[]): Observable<ActionResponse[]> {
    return this.http.post<ActionResponse[]>(this.resourceService.getApiUrl() + '/customers/delete', customerIds);
  }

  getCustomersByProjectId(project_id:number){
    return this.http.get<Customer[]>(this.resourceService.getApiUrl()+`/customer?project_id=${project_id}`)   }
}
