import { SmartProtocol, TraceHostCfg } from './interfaceModel';




export class Mgmt {

    constructor() {
        this.name = 'mgmt0';
        this.type = 'ethernet';
        this['ipv4-prefix'] = '';
        this['ipv6-address'] = '';
        this["link-state-propagation"] = true;
        this.tracehost = new TraceHostCfg();
        this['smart-protocol-passthrough'] = [new SmartProtocol()]
        this.gateway = '';
        this.management = false;
        this.metric = 100;

    }
    name: string;
    type: string;
    'ipv4-prefix': string;
    'ipv6-address': string;
    'link-state-propagation': boolean;
    tracehost: TraceHostCfg;
    ['smart-protocol-passthrough']?: SmartProtocol[]
    gateway: string;
    management: boolean;
    metric: number;
}
