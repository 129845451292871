import { BasicEdgeInterface } from "..";

export class Loopback implements BasicEdgeInterface {

  constructor() {
    this.enable = false;
    this.type = 'loopback';
  }
  loopbackID: string;
  enable: boolean;
  name: string;
  type: string;
  "ipv4-prefix": string;
  "ipv6-prefix": string;
}
