import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppliedFilterService } from 'services/applied-filter.service';
import { LocalStorageService } from 'services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class TableDataService {
  private initialColumnsReturn: string[];
  constructor(
    private appliedFilterService: AppliedFilterService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private cookieService: CookieService) {
  }

  private subject = new Subject<any>();

  sendColumnsInitialData(message: string[], url: string) {
    this.initialColumnsReturn = [ ...message ];
    this.subject.next({ message, url });
  }

  sendToggleColumn(column: string, status: boolean, indexPosition: number) {
    this.subject.next({ column, status, indexPosition });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  initialColumnIndex(initialColumns: string[], columnIndex: string[]): Observable<any> {
    return this.subject.pipe(
      map(x => {
        if (this.initialColumnsReturn.includes(x.column)) {
          this.initialColumnsReturn.splice(this.initialColumnsReturn.indexOf(x.column), 1);
        } else {
          this.initialColumnsReturn = columnIndex.filter(z => this.initialColumnsReturn.includes(z) || z == x.column);
        }

        return this.initialColumnsReturn;
      })
    );
  }

  saveOnSort(event, filterApplied) {
    if (this.appliedFilterService.getFilters(filterApplied)) {
      this.appliedFilterService.saveFilters(filterApplied, event, true);
    } else {
      this.localStorageService.saveSort(this.router.url, this.cookieService.get('user'), 'sortUser', event);
    }
  }
}
