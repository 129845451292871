export class KubernetisConfig {
    isCboxEnable: boolean;
    bindInterface: string;
    hostname: string;
    constructor() {
        this.isCboxEnable = false;
        this.hostname = '';
        this.bindInterface = 'data0';
    }
}

export class OpenstackConfig extends KubernetisConfig {
    routerSpeakerEnabled = false;
    speakerType = '';
    childTsType = 'per-vm';
    hostname = 'controller';
    ipPrefixScope:string;
    openvpnIP='';
    openvpnPort=23000;

    quaggaHost='';
    quaggaLoginPass='';
    quaggaLocalAs='';
    
}
