export class Controller {
    admin_status: string = ''
    description: string = ''
    customer: string = ''
    customer_id: number = null
    hostname: string = ''
    password: string = ''
    id: number = null
    management_ips: string = ''
    operator: string = ''
    operator_id: number = null
    project: string = ''
    project_id: number = null
    status: string = ''
    update_time: string = ''
    version: string = ''
    username: string = ''
    is_k8s: boolean = true;

    public constructor() {
        this.admin_status = ''
        this.description = ''
        this.customer = ''
        this.customer_id = null
        this.hostname = ''
        this.password = ''
        this.id = null
        this.management_ips = ''
        this.operator = ''
        this.operator_id = null
        this.project = ''
        this.project_id = null
        this.status = ''
        this.update_time = ''
        this.version = ''
        this.username = ''

    }
}