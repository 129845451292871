<!-- columns select drop menu -->

<div class="table-container" >
    <table mat-table #table [dataSource]="dataSource" [cdkDropListData]="dataSource" cdkDropList
           cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matSort
           (matSortChange)="sortChange($event)" [matSortActive]="filterActive" [matSortDirection]="filterDirection">
  
      <!--  Columns -->
      <ng-container [matColumnDef]="column" *ngFor="let column of initialColumns">
  
        <div *ngIf="column!='select'">
          <th style="text-transform: capitalize; white-space: nowrap; " mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag>
              {{this.formatColumn(column)}}
  
          </th>
        </div>
        
        <!-- checkbox column , that select all -->
        <div class="table-content" *ngIf="column=='select'">
          <th mat-header-cell *matHeaderCellDef cdkDrag>
            <mat-checkbox [ngStyle]="{'color':'#aaa'}" (change)="selectAllHandle($event)" [indeterminate]="false"
                          [(ngModel)]="isAllChecked">
            </mat-checkbox>
          </th>
        </div>
        <!--  Rows -->
        <td mat-cell *matCellDef="let row">
  
          <div class="table-content" *ngIf="column=='select'">

            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </div>
  
  
          <!--every row-->
          <div class="table-content" *ngIf="column!='select'"
               style="{{(column=='alarm_description' || column=='description')  ? 'max-width: 50rem;' : ''}}">
  
            <!-- if contain severity  *ngIf="column==='severity'" this.formatRow(row[column], column)-->
            <div *ngIf="column!='description' && column!='status' && column!='create_time'" >
                    <span>
                      {{ row[column] }}
                    </span>
            </div>

            <div *ngIf="column=='create_time'">
                {{row[column] | dateTime}}
           </div>

            <div *ngIf="column=='status'" class="severity" [ngClass]="row[column]">
              {{row[column]}}
           </div>

            <div *ngIf="column=='description' && row[column].length<=70">
              <a>  {{row[column]}}</a>
            </div>
            
            <div *ngIf="column=='description' && row[column].length>70">
              <div id='menu_trigger' (click)="openMyMenu($event, row)"
                   style="max-width: 50rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                        <span>
                        <a *ngIf="row[column].length>70" href="">  {{row[column]}}</a>
                        </span>
              </div>
  
              <div style="visibility: hidden; position: fixed;"
                   [style.left]="contextMenuPosition.x"
                   [style.top]="contextMenuPosition.y"
                   [matMenuTriggerFor]="menu_alarm">
              </div>
  
              <mat-menu id='menu_triggered' #menu_alarm="matMenu" overlapTrigger="false" (mouseleave)="closeMyMenu()" [class]="'alertDropDown'">
                <ng-template matMenuContent let-rowData="rowData">
                  <mat-card appearance="outlined" style="min-width: 50rem; height: 100%;" (click)="$event.stopPropagation()">
                    <textarea rows="5" [readonly]="true" matInput placeholder="Alarm Description"
                              value="{{rowData['description']}}" class="form-control" style="width: 48rem;"></textarea> 
                  </mat-card>
                </ng-template>
              </mat-menu>
            </div>
            
          </div>
        </td>
      </ng-container>
  
  
      <tr mat-header-row *matHeaderRowDef="initialColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: initialColumns;" [ngClass]="{'highlight': selection.isSelected(row)}"
          (click)="rowClicked(row)">
      </tr>
    </table>

   
  </div>

  <mat-paginator showFirstLastButtons style="" class="mat-paginator-sticky" [pageSizeOptions]="[15,25,50,100,250]" [length]="pageCount"
  [pageSize]="currentPage?.pageSize" [pageIndex]="currentPage?.pageIndex" (page)="handlePageEvent($event)"
  aria-label="Select page"></mat-paginator>

  
  
  