import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { DiscoveryChartData } from 'src/app/models';
import { colors } from 'src/app/models/colors';

@Component({
  selector: 'app-discovery-chart',
  templateUrl: './discovery-chart.component.html',
  styleUrls: ['./discovery-chart.component.scss']
})
export class DiscoveryChartComponent implements OnChanges, AfterViewInit {

  @Input() customChartData: DiscoveryChartData;
  @Input() typeChart: string = '';

  public customChart: any;
  public colors: typeof colors = colors;
  public liveView: boolean;
  selectedLegend: object;
  stopRecalculteOnRefresh: boolean = false;
  chartHeader: string;
  redirectUrl: string = '';
  chartName: string = ''
  myChart: ECharts;
  entriesSum: number;
  @ViewChild('customChartSelector', { read: ElementRef, static: false }) customChartSelector: ElementRef;

  constructor(private router: Router) {
    this.liveView = false;
    this.entriesSum = 0;
  }

  ngAfterViewInit(): void {
    this.myChart = echarts.init(this.customChartSelector.nativeElement);
    this.myChart.on('legendselectchanged', function (params) {

      if (params['selected']) {
        this.selectedLegend = params['selected'];
        this.vnfTypesSum = 0;
        for (const legentItem in params['selected']) {
          if (params['selected'][legentItem]) {
            const legentItemFormatted = legentItem.slice(0, -2).toLowerCase()
            if (this.customChartData[legentItemFormatted] != undefined) {
              this.vnfTypesSum = this.vnfTypesSum + this.customChartData[legentItemFormatted]
            }
          }
        }
      }

      this.myChart.setOption({
        title: {
          text: this.vnfTypesSum,
          left: this.recenter(this.vnfTypesSum),
          top: "center",
          textStyle: {
            fontSize: 16,
            align: 'center'
          }
        }
      }
      );
    }.bind(this));

    this.resizeChart();
    this.myChart.on('click', function (params) {
      this.router.navigate([this.redirectUrl, params.name])
    }.bind(this));
  }

  ngOnChanges(): void {
    if (this.customChartData != null) {
      this.initHeaderChart()
      this.initChart()

    }
  }

  initHeaderChart() {
    if (this.typeChart) {
      switch (this.typeChart) {
        case 'ucpe':
          this.chartHeader = 'Edge Discovery'
          this.redirectUrl = '/Discovery/DiscoveryEdges'
          this.chartName = 'edgeDiscovery'
          break;
        case 'controller':
          this.chartHeader = 'Controller Discovery'
          this.chartName = 'controllerDiscovery'
          this.redirectUrl = '/Discovery/DiscoveryControllers'
          break
      }
    }
  }

  getStatusUI(status: string) {
    switch (status) {
      case 'Warning': {
        return 'Warning'
      }
      case 'Minor': {
        return 'Minor'
      }

      default: {
        return 'Unknown'
      }
    }
  }

  getStatusUItoSystem(status) {
    switch (status) {
      case 'Blacklisted': {
        return 'blacklist'
      }
      case 'Listed': {
        return 'list'
      }
    }
  }

  calculateInit(edgeData) {
    let initValue = 0;
    if (edgeData['blacklist'] != undefined) {
      initValue = initValue + edgeData['blacklist'];
    }

    if (edgeData['list'] != undefined) {
      initValue = initValue + edgeData['list'];
    }

    return initValue;
  }

  recenter(value: number) {
    if (value > -1 && value < 10) {
      return '22.5%'
    }

    if (value >= 10 && value < 100) {
      return '21.5%'
    }

    if (value >= 100 && value < 1000) {
      return '20.5%'
    }

    if (value >= 1000 && value < 10000) {
      return '19.5%'
    }

    if (value >= 10000 && value < 100000) {
      return '18.5%'
    }

    if (value >= 100000 && value < 1000000) {
      return '17.5%'
    }
  }

  public initChart(): void {
    let newChartData: DiscoveryChartData = new DiscoveryChartData();

    if (this.selectedLegend) {
      for (const key in this.selectedLegend) {
        if (this.selectedLegend[key]) {
          const systemstatus = this.getStatusUItoSystem(key)
          newChartData[systemstatus] = this.customChartData[systemstatus]
        }
      }
    }
    else {
      newChartData = this.customChartData;
    }

    const initCount = this.calculateInit(newChartData);
    this.customChart = {
      title: {
        text: initCount,
        left: this.recenter(initCount),
        top: "center",
        textStyle: {
          fontSize: 16,
          align: 'center',
          color: colors.TEXT,
          fontFamily: colors.FONT,

        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        top: 'center',
        left: 250,
        data: [{ name: 'Blacklisted', itemStyle: { color: colors.RED } }, { name: 'Listed', itemStyle: { color: colors.GREEN } }],
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      series: [{
        type: 'pie',
        radius: ['45%', '90%'],
        center: ['25%', '50%'],
        label: {
          show: true,
          position: 'inner',
          color: colors.TEXT,
          fontFamily: colors.FONT,
          formatter: (params) => {
            return params.value;
          }
        },
        labelLine: {
          normal: {
            show: false,
            //   position: 'inner',
            //   formatter: (params) => {
            //       return 10;
            //   }

          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          // borderWidth: 1
        },

        data: this.convertNameValueArray(this.customChartData)

      }]
    };

    if (this.selectedLegend != null) {
      this.customChart['legend']['selected'] = this.selectedLegend;
      this.customChart['legend']['selectedMode'] = true;
      this.stopRecalculteOnRefresh = true

    }
  }

  convertNameValueArray(chartData: DiscoveryChartData) {
    const newArrayData = [];

    if (chartData.blacklist) {
      newArrayData.push({ name: 'Blacklisted', value: chartData.blacklist, itemStyle: { color: colors.RED } });
    }

    if (chartData.list) {
      newArrayData.push({ name: 'Listed', value: chartData.list, itemStyle: { color: colors.GREEN } });
    }

    this.resizeChart();
    return newArrayData
  }

  onChartClick() {
    //console.log(event.name)
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 382, height: 209 });
    }
  }
}
