import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResourceService } from "./resource.service";
import { map } from "rxjs/operators";




@Injectable()

export class StorageService {


    public constructor(private resourceService: ResourceService, private http: HttpClient) {
    }

    /***get stored file by filename index
    Example: '/quagga/static/9060'
    ***/
    getStoreFileByIndex(fileIndex: string, project_id: number): Observable<any> {
        return this.http.get(this.resourceService.getApiUrl() + `/storage/data?project_id=${project_id}&file_index=${fileIndex}`, { responseType: 'blob', observe: 'response' }).pipe(
            map((result: HttpResponse<Blob>) => {
                return { file: result['body'], name: result.headers.get('Content-Disposition'), type: result['body']['type'] };
            }
            ))
    }
}
