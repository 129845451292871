import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Observable, of } from 'rxjs';
import { AlertDialogGuardComponent } from '../components/custom-components/alert-dialog-guard/alert-dialog-guard.component';
import { AlertDialogComponent } from '../components/custom-components/alert-dialog/alert-dialog.component';


@Injectable()
export class AlertNotificationService {

  public constructor(private dialog: MatDialog) {
  }

  public alertMessage(message: string): Observable<any> {
    return this.openAlertDialog(message, 'Error');
  }

  public alertSaveFilterMessage(message: string): Observable<any> {
    return this.openAlertDialog(message, 'Info', 'Ok');
  }

  public alertError(message: string): Observable<any> {
    return this.openAlertDialog(message, 'Error', 'Ok', '');
  }

  public alertWarning(message: string): Observable<any> {
    return this.openAlertDialog(message, 'Warning', 'Ok', '');
  }

  public alertInfo(message: string): Observable<any> {
    return this.openAlertDialog(message, 'Info', 'Ok', '');
  }

  public warningAlert(message: string): Observable<any> {
    return this.openAlertDialog(message, 'Warning', 'Yes', 'No');
  }

  public alertConfirm(message: string): Observable<any> {
    return this.openAlertDialog(message, 'Confirmation', 'Yes', 'No');
  }
  confirmCancel(message) {
    return this.openAlertDialog(message, 'Warning', 'Force Delete', 'Cancel');
  }
  public alertConfirmWithCondition(message: string, conditionMessage: string): Observable<any> {
    return this.openAlertDialog(message, 'Confirmation', 'Yes', 'No', null, conditionMessage);
  }

  private openAlertDialog(message: string, alert_type: string, confirm?: string, cancel?: string, timeout?: number, conditionText?: string): Observable<any> {
    if (this.dialog.openDialogs.length > 0) {
      return of(EMPTY);
    }
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
        alert_type: alert_type,
        buttonText: {
          confirm: confirm,
          cancel: cancel
        },
        timeout: timeout,
        conditionText: conditionText
      }, disableClose: true,
    },);
    return dialogRef.afterClosed();
  }

  public alertGuardConfirm(message): Observable<boolean> {
    return this.openAlertGuardDialog(`Are you sure you want to leave? ${message} is not saved and your changes will be lost.`, 'Warning', 'Yes', 'No');
  }

  private openAlertGuardDialog(message: string, alert_type: string, confirm?: string, cancel?: string, timeout?: number): Observable<any> {
    if (this.dialog.openDialogs.length > 0) {
      return of(EMPTY);
    }
    const dialogRef = this.dialog.open(AlertDialogGuardComponent, {
      data: {
        message: message,
        alert_type: alert_type,
        buttonText: {
          confirm: confirm,
          cancel: cancel
        },
        timeout: timeout,
      }, disableClose: true,
    },);
    return dialogRef.afterClosed();
  }

}
