export class Site {
	id: number;
	address: string;
	contact: string;
	name: string;
	project: string;
	project_id: number;
	operator: string;
	operator_id: number;
	customer: string;
	customer_id: number;
	service: string
	service_id: number;
	site_type: string;

  constructor() {
		this.name = '';
		this.project='';
		this.project_id=0;
		this.service='';
		this.service_id = 0;
		this.customer='';
		this.customer_id = 0;
		this.operator='';
		this.operator_id = 0;
		this.site_type = '';
		this.address = '';
		this.contact = '';
		this.id = 0;
  }
}