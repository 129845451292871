import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'services/local-storage.service';
import { TableDataService } from 'services/tableDataTransfer.service';

import { Sorted } from '../models';

@Directive({
  selector: '[telcoKeepSort]'
})
export class KeepSortDirective implements OnInit {
  @Input() telcoKeepSort: any;
  @Input() exceptionSorts: string[]=[];

  constructor(
    private messageService: TableDataService,
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const sort = this.localStorageService.getItem(this.cookieService.get('user'))[this.router.url]?.sortUser;
    if (sort && this.telcoKeepSort.tableName != 'alertsDropdown') {
      this.telcoKeepSort.currentSort = new Sorted();
      this.telcoKeepSort.currentSort.order = sort.active;
      this.telcoKeepSort.currentSort.direction = sort.direction;
    }
  }

  @HostListener('matSortChange', ['$event'])
  saveSort(event) {
    this.telcoKeepSort.customTableComponent.clearTableSelection();
    this.telcoKeepSort.tableName != 'alertsDropdown' && !this.exceptionSorts.includes(event.active) && this.messageService.saveOnSort(event, this.telcoKeepSort.filterApplied);
  }
}
