import { AfterViewInit, Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AllowIn, ShortcutInput } from "ng-keyboard-shortcuts";
import { AlertNotificationService } from "src/app/services/alertNotification.service";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements AfterViewInit {
  loginForm: FormGroup;
  shortcuts: ShortcutInput[] = [];
  hide: boolean = true;

  constructor(
    private fb: FormBuilder, 
    public authenticationService: AuthenticationService, 
    private alertNotification: AlertNotificationService
  ) {
    this.loginForm = this.fb.nonNullable.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.loginForm.invalid) {
      this.alertNotification.alertError("Please fill out both username and password!");
      return;
    }
    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;
    this.authenticationService.login(username, password);
  }

  ngAfterViewInit(): void {
    this.shortcuts.push({
      key: "enter",
      preventDefault: true,
      command: () => this.onSubmit(),
      allowIn: [AllowIn.Textarea, AllowIn.Input]
    });
  }
}