
export class View {
    id: number;
    name: string;
    type: string;
    oid: string;
  
    constructor(id: number, name: string) {
      this.id = id;
      this.name = name;
      this.type = "included";
      this.oid = "1.3";
    }
  }
  



  export class UcpeUser {
    id: number;
    'user-name': string;
    'write-view': string = "";
    'remote-engine-id': string = "";
    'security-level': string = "noAuthNoPriv";
    'auth-password': string = "";
    'auth-type': string = "none";
    'read-view': string;
    'message-model': string = "v2c";
    'priv-password': string = "";
    'notify-view': string;
    'priv-type': string = "none";
    constructor(id?: number, username?: string ) {
      this.id = id;

      this["auth-password"]= "",
      this["auth-type"]= "none",
      this.id= id,
      this["message-model"]= "v2c",
      this[ "notify-view"]= "",
      this["priv-password"]=  "",
      this[ "priv-type"]= "none",
      this[ "read-view"]=  "view1",
      this[  "remote-engine-id"]= "",
      this[ "security-level"]= "noAuthNoPriv",
      this[ "user-name"]=  username,
      this[ "write-view"]=  ""
    }
  }


  export class Severities {
    severities = [{ id: 'all', name: "all" },
    { id: 'alert', name: "alert" },
    { id: 'crit', name: "crit" },
    { id: 'debug', name: "debug" },
    { id: 'emerg', name: "emerg" },
    { id: 'error', name: "error" },
    { id: 'info', name: "info" },
    { id: 'notice', name: "notice" },
    { id: 'warning', name: "warning" }];
  }
  
  export class TargetAddress {
     
    enable: boolean;
    'target-addr-name': string;
    'security-level': string = "";
    'retry-count': number = 3;
    'security-name': string = "monitoring";
    'message-model': string = "";
     timeout: number = 1500;
    'dst-port': number = 1062;
    'dst-address': string;
    type: string = "";
    id: number;
    
    constructor(id?: number,enable?:boolean) {

      this["dst-address"]= "auto-manager",
      this["dst-port"]= 1062,
      this.enable= enable,
      this.id=id,
      this["message-model"]= "v2c",
      this[ "retry-count"]= 3,
      this["security-level"]= "noAuthNoPriv",
      this[ "security-name"]= "monitoring",
      this["target-addr-name"]= "auto-manager",
      this.timeout= 1500,
      this.type= "trap"
    }
  }
  
  export class SnmpServer {
    constructor() {
      this.enable = true;
      this['system-contact'] = '';
      this['system-description'] = '';
      this['authentication-failure-trap-enabled'] = false;
      this['system-location'] = '';
      this['bind-address'] = '0.0.0.0';
      this.port = 161;
      this['system-name'] = '';
      this.users = [new UcpeUser(1,'telco'),new UcpeUser(2,'monitoring')];
      this.views = [new View(1,'view1'),new View(2,'monitoring')];
      this['target-address'] = [new TargetAddress(1,true),new TargetAddress(2,false),new TargetAddress(3,false),new TargetAddress(4,false),new TargetAddress(5,false)  ];
    }
  
    enable: boolean;
    'system-contact': string;
    'system-description': string;
    'authentication-failure-trap-enabled': boolean;
    'system-location': string;
    'bind-address': string;
    port: number;
    'system-name': string;

    users: UcpeUser[];
    views: View[];
    'target-address': TargetAddress[];
  }

  export class ViewType {
    views = [{ id: 'included', name: 'included' }, { id: 'excluded', name: 'excluded' }];
  }

  export class ConfigurationTypes {
    types = [{ id: 'running', name: 'running' }, { id: 'startup', name: 'startup' }];
  }
  
  export class MessageModel {
    messages = [{ id: 'v3', name: 'v3' }, { id: 'v2c', name: 'v2c' }, { id: 'v1', name: 'v1' }];
  }
  
  export class SecurityLevel {
    levels = [{ id: 'authNoPriv', name: 'authNoPriv' }, { id: 'authPriv', name: 'authPriv' }, { id: 'noAuthNoPriv', name: 'noAuthNoPriv' }];
  }


export class TypesModel {
  types = [{ id: 'trap', name: 'trap' }, { id: 'inform', name: 'inform' }];
}

export class UserAuthTypes {
  users = [{ id: 'SHA', name: 'SHA' }, { id: 'MD5', name: 'MD5' }, { id: 'none', name: 'none' }];
}

export class UserPrivcyTypes {
  privcies = [{ id: 'none', name: 'none' }, { id: 'AES', name: 'AES' }, { id: 'DES', name: 'DES' }];
}

