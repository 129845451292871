import {
    Component, EventEmitter, OnInit, Output
} from "@angular/core";
import { AlarmsAndEventsService } from "services/alarmsAndEvents.service";
import { TableDataResult } from "src/app/models/interfaces/dataTableResult";

@Component({
    selector: "telco-alerts-dropdown-mobile",
    templateUrl: "./alerts-dropdown-mobile.component.html",
    styleUrls: ["./alerts-dropdown-mobile.component.scss"]
})
export class MobileAlertsDropdownComponent implements OnInit {
    @Output() alertCountChanged = new EventEmitter<number>();

    constructor(private alarmsAndAlarmService: AlarmsAndEventsService) {}

    ngOnInit(): void {
        this.refreshTable();
    }

    refreshTable() {
        this.alarmsAndAlarmService.getAlertsAdvanced(1, 15, "create_time", "desc", true, []).subscribe((result: TableDataResult) => {
            if (result) {
                this.alertCountChanged.emit(result.count);
            }
        });
    }
}
