export class HWType{
	cpu_model: string;
	cpus: number;
	hdd: number;
	id:	number;
	interfaces: number;
	name: string;
	project_id: number;
	project: string;
	ram: number;
    
	public constructor(id?: number, cpu_model?: string, cpus?: number, hdd?: number, interfaces?: number,
		name?: string, project_id?: number, ram?: number) {
		this.cpu_model = cpu_model;
		this.cpus = cpus;
		this.hdd = hdd;
		this.id = id;
		this.interfaces = interfaces;
		this.name = name;
		this.project_id = project_id;
		this.ram = ram;
	}
}