import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from "file-saver";
import { Observable } from 'rxjs/';
import { map } from 'rxjs/operators';
import { save } from '../components/helpers/disable-save.interceptor';
import { ApplicationTemplate } from '../models/ApplicationTemplate';
import { ApplicationServiceTemplate } from '../models/ApplicationTemplateService';
import { Application } from '../models/application';
import { Response } from '../models/types/common';
import { ResourceService } from './resource.service';

const headers = new HttpHeaders().set(
  'Content-Type',
  'application/text; charset=utf-8'
);


@Injectable()
export class ApplicationService {

  constructor(private resourceService: ResourceService, private http: HttpClient) {
  }

  getApplications(page = 1, count = 15, order?: string, direction?: string, node_id?: number, project_id?: number, id_list: boolean = false): Observable<Application[]> {
    let requestString = `/application?page=${page}&count=${count}`;
    if (node_id) {
      requestString += `&node_id=${node_id}`;
    }

    if (project_id) {
      requestString += `&project_id=${project_id}`;
    }

    if (order != undefined) {
      requestString = requestString + `&order=${order}`;
    }

    if (direction != undefined) {
      requestString = requestString + `&direction=${direction}`;
    }

    if (id_list != undefined) {
      requestString = requestString + `&id_list=${id_list}`;
    }

    return this.http.get<Application[]>(this.resourceService.getApiUrl() + requestString);
  }

  getApplicationsAdvanced(page: number, count: number, order: string, direction: string, filterData: any[]): Observable<any> {
    const requestString = `/application/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, {
      'filter': filterData
    });
  }

  getSshTerminalById(nodeId: number, appId: number, app_index) {
    return this.http.get(
      this.resourceService.getApiUrl() + `/node/application/terminal/${nodeId}/${appId}?application_index=${app_index}`,
      { headers, responseType: 'text' }
    );
  }

  getWebGuiById(nodeId: number, appId: number, app_index) {
    return this.http.get(
      this.resourceService.getApiUrl() + `/node/application/web/${nodeId}/${appId}?application_index=${app_index}`,
      { headers, responseType: 'text' }
    );
  }

  getApplication(applicationId: number): Observable<Application> {
    return this.http.get<Application>(this.resourceService.getApiUrl() + '/application/' + applicationId);
  }

  getApplicationByEdgeId(edgeId: number, applicationId: number): Observable<Application> {
    return this.http.get<Application>(this.resourceService.getApiUrl() + `/node/application/${edgeId}/${applicationId}`);
  }

  getApplicationPackage(applicationId: number): Observable<any> {
    return this.http.get(this.resourceService.getApiUrl() + `/application/package/${applicationId}`, { responseType: 'blob', observe: 'response' }).pipe(
      map((result: HttpResponse<Blob>) => {
        const fileName = result.headers.get('content-disposition').split('=')[1];
        saveAs(new File([result['body']], `${fileName}`, { type: result['body']['type'] }));
        return result;
      }
      ))
  }

  deleteApplication(applicationIds: number[]): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + '/application/advanced', { "delete": applicationIds });
  }

  deleteApplications(applicationIds: number[]): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + '/application/template/advanced', { "delete": applicationIds });
  }

  cloneApplicationTemplate(applicationIds: number): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + `/application/template/${applicationIds}/clone`, '');
  }

  uploadApplicationPackage(projectId: number, file: File) {
    const context = new HttpContext().set(save, true);

    const formData: FormData = new FormData();

    formData.append('fileName', file, file.name);

    return this.http.put(this.resourceService.getApiUrl() + `/application?project_id=${projectId}`, formData, { context })
  }

  //Node application operations

  getNodeApplicationsById(nodeId: number): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/node/application/service/${nodeId}`);
  }

  startNodeApplication(nodeId: number, appId: number): any {
    return this.http.post(this.resourceService.getApiUrl() + `/node/application/service/${nodeId}/${appId}/start`, null);
  }

  startNodeApplications(appNodeIds: Array<number>) {
    return this.http.post(this.resourceService.getApiUrl() + `/node/application/service/advanced`, { "start": appNodeIds });
  }

  stopNodeApplication(nodeId: number, appId: number): any {
    return this.http.post(this.resourceService.getApiUrl() + `/node/application/service/${nodeId}/${appId}/stop`, null);
  }

  stopNodeApplications(appNodeIds: Array<number>) {
    return this.http.post(this.resourceService.getApiUrl() + `/node/application/service/advanced`, { "stop": appNodeIds });
  }

  addNodeApplication(nodeId: number, appId: number, app_template: any, user_data: any) {
    return this.http.put(this.resourceService.getApiUrl() + `/node/application/${nodeId}/${appId}`, {
      "app_template": app_template,
      "user_data": user_data
    });
  }

  createNodeApplicationBulk(nodeIds: number[], appId: number, app_template: any, user_data: any) {
    return this.http.post(
      `${this.resourceService.getApiUrl()}/node/application/advanced`,
      { upload: nodeIds, appdata: { app_template, user_data, app_id: appId } }
    );
  }

  deleteNodeApplication(nodeId: number, appId: number) {
    return this.http.delete(this.resourceService.getApiUrl() + `/node/application/service/${nodeId}/${appId}`);
  }

  deleteNodeApplications(appNodeIds: Array<number>) {
    return this.http.post(this.resourceService.getApiUrl() + `/node/application/service/advanced`, { "delete": appNodeIds });
  }

  getNodeApplications(id_only: boolean, page: number, count: number, order: string, direction: string, filterData: any): Observable<any> {
    const requestString = `/node/application/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, { 'id_only': id_only, filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }


  getNodeApplicationsIds(id_only: boolean, filterData: any): Observable<any> {
    const requestString = `/node/application/service/advanced?node_id=0&page=1&count=20&order=hostname`
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, { 'id_only': id_only, 'filter': filterData });
  }

  getApplicationTemplatesAdvanced(page: number, count: number, order: string, direction: string, filterData: any[]): Observable<any> {
    const requestString = `/application/template/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, {
      'filter': filterData
    });
  }


  getApplicationTemplates(page: number, count: number, order: string = 'name', direction: string = 'asc', id_only = false): Observable<Response<ApplicationTemplate>> {
    return this.http.get<Response<ApplicationTemplate>>(this.resourceService.getApiUrl() + `/application/template?page=${page}&count=${count}&order=${order}&direction=${direction}&id_only=${id_only}`);
  }

  getApplicationTemplateById(tempalteId: number): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/application/template/${tempalteId}`);
  }

  createApplicationTemplate(appTemplate: ApplicationTemplate) {
    return this.http.put(this.resourceService.getApiUrl() + '/application/template', appTemplate)
  }

  updateApplicationTemplate(appTemplate: ApplicationTemplate) {
    return this.http.post(this.resourceService.getApiUrl() + `/application/template/${appTemplate.id}`, appTemplate)
  }

  getApplicationServicesAdvanced(page: number, count: number, order: string, direction: string, filterData: any[]): Observable<any> {
    const requestString = `/node/application/service/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, {
      'filter': filterData
    });
  }

  createAppService(appServiceTemplate: ApplicationServiceTemplate, nodeId: number) {
    return this.http.put(this.resourceService.getApiUrl() + `/node/application/service/${nodeId}`, appServiceTemplate);
  }

  modifyAppService(appServiceTemplate: ApplicationServiceTemplate, nodeId: number, appServiceId: number) {
    return this.http.post(this.resourceService.getApiUrl() + `/node/application/service/${nodeId}/${appServiceId}`, appServiceTemplate);
  }

  createAppServiceBulk(appServiceIds: number[], appServiceTemplate: ApplicationServiceTemplate) {
    return this.http.post(`${this.resourceService.getApiUrl()}/node/application/service/advanced?page=1&count=20&id_only=false`, { deploy: appServiceIds, app_service: appServiceTemplate });
  }

  createAndStartAppService(appServiceIds: number[], appServiceTemplate: ApplicationServiceTemplate, is_start = false) {
    return this.http.post(`${this.resourceService.getApiUrl()}/node/application/service/advanced?id_only=false`, { deploy: appServiceIds, app_service: appServiceTemplate, is_start });
  }

  ExecuteApplicationActionScript(nodeId: number, appServiceId: number, application_index: string, application_script: string): Observable<any> {
    return this.http.post(`${this.resourceService.getApiUrl()}/node/application/service/${nodeId}/${appServiceId}/execute?application_index=${application_index}&application_script=${application_script}`, {}, { headers, responseType: 'text' } /* for now body params are in url { application_script, application_index }*/);
  }

  getApplicationServiceById(appServiceId: number) {
    return this.http.get<ApplicationServiceTemplate>(this.resourceService.getApiUrl() + `/application/service/${appServiceId}`);
  }


  startApplicationAction(nodeId: number, application_index: string) {
    return this.http.post(`${this.resourceService.getApiUrl()}/node/application/${nodeId}/start?application_index=${application_index}`, null);
  }

  stopApplicationAction(nodeId: number, application_index: string) {
    return this.http.post(`${this.resourceService.getApiUrl()}/node/application/${nodeId}/stop?application_index=${application_index}`, null);
  }

}