export enum colors {
  YELLOW = '#FFF177',
  BLUE = '#678BAC',
  LIGHT_BLUE = '#B3E6F1',
  DARK_BLUE = '#082246',
  PINK = '#ff4081',
  GREEN = '#BAD878',
  VIOLET = '#B4ADFF',
  RED = '#FF6A67',
  LIME = '#00FF00',
  MAGENTA = '#FF00FF',
  GRAY = '#808080',
  DARK_GRAY = '#343434',
  ORANGE = '#FFAE74',
  OLIVE = '#adad68',
  AQUAMARINE = '#7FFD4',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  TEXT = '#343434',
  FONT = 'Roboto'
}