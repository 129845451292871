<div class="table-container" #scroll [style.width]="width" [style.overflow]="width?'none':'auto'" [ngClass]="{'table-container-extra': width}">
  <table mat-table [dataSource]="dataSource"
      [telcoShiftSelection]="dataSource" [selection]="selection"
      tabindex="0">


    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef cdkDrag>
        <mat-checkbox #checkboxHeader (change)="toggleAllRows($event)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" style="padding-right: 2rem; width: max-content;">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="CbChange(row)"
                      [checked]="selection.isSelected(row)"></mat-checkbox>
      </td>
    </ng-container>
    <ng-content></ng-content>
    <tr mat-header-row *matHeaderRowDef="initialColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: initialColumns;" (click)="rowClicked(row)"
        [ngClass]="{'highlight': selection.isSelected(row)}"></tr>
  </table>
</div>
<mat-divider></mat-divider>
<div style="display: flex;flex-direction: row;justify-content: space-between;" class="paginator-container">
  <div class="select-counter">{{ checkboxHeader?.checked ? pageCount : selection.selected.length }} selected</div>
  <mat-paginator appPaginatorStorage [tableName]="tableName" showFirstLastButtons [pageSizeOptions]="[15,25,50,100,250]" [length]="pageCount"
                 [pageSize]="currentPage?.pageSize" [pageIndex]="currentPage?.pageIndex" (page)="handlePageEvent($event)"
                 (pageStore)="onPageStore($event)"
                 aria-label="Select page"></mat-paginator>
</div>
