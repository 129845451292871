import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { TokenInfo } from '../models/interfaces/tokenInfo';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';


export interface Config {
  PROTOCOL: string;
  API_URL: string;
  IP: string;
  version: string;
  HOSTNAME: string;
}

@Injectable()
export class ResourceService {
  config: Partial<Config>;
  isResourcesLoaded: boolean;
  tokenInfo: TokenInfo;

  constructor(
    private platformLocation: PlatformLocation,
    private cookies: CookieService,
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.config = this.appConfigService.config;
    this.cookies.set('API_URL', this.config.API_URL);
    this.cookies.set('PROTOCOL', this.config.PROTOCOL);
    this.cookies.set('IP', this.config.IP);
    this.cookies.set('version', this.config.version);
    this.cookies.set('HOSTNAME', this.config.HOSTNAME);
    this.isResourcesLoaded = true;
  }

  getTokenUrl() {
    return `${this.config.PROTOCOL}://${this.config.IP}/${this.config.API_URL}/user/token`;
  }

  getApiUrl() {
    return `${this.config.PROTOCOL}://${this.config.IP}/${this.config.API_URL}`;
  }

  getTokenInfo(): Observable<TokenInfo> {
    return this.http.get<TokenInfo>(this.getApiUrl() + '/user/token/info').pipe(tap(val => {
      this.appConfigService.tokenExpired = false;
      this.tokenInfo = val;
    }));
  }

  getVersion() {
    return this.config.version;
  }

  getIPOnly(): string {
    return this.config.IP.split(':')[0];
  }
}
