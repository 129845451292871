import { Component, OnDestroy, OnInit } from "@angular/core";
import { forkJoin, interval, Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { AlertsChartData } from "src/app/models/interfaces/AlertsChartData";

import { AlarmsChartData, EdgeStatuses } from "../../../../models";
import { DashboardService } from "../../../../services";

@Component({
    selector: "telco-dashboard-mobile",
    templateUrl: "./dashboard-mobile.component.html",
    styleUrls: ["./dashboard-mobile.component.scss"]
})
export class DashboardMobileComponent implements OnInit, OnDestroy {
    ucpeChartData$: EdgeStatuses;
    controllerChartData$: EdgeStatuses;
    alarmCharData$: AlarmsChartData;
    alertsCharData$: AlertsChartData;
    activeEventCharData$: any;
    activeOperationsCharData$: any;
    private destroy$ = new Subject<void>();

    constructor(private dashboardService: DashboardService) {}

    ngOnInit(): void {
        interval(10000)
            .pipe(
                startWith(0),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.loadAllChartsData();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    loadAllChartsData() {
        forkJoin([
            this.dashboardService.loadUcpeChartData(),
            this.dashboardService.loadControllerChartData(),
            this.dashboardService.loadAlarmChartData(),
            this.dashboardService.loadContrlActiveEventsChartData(),
            this.dashboardService.loadActiveOpersChartData(),
            this.dashboardService.loadAlertChartData()
        ]).subscribe(([ucpe, controller, alarm, activeEvent, activeOpers, alert]) => {
            this.ucpeChartData$ = ucpe;
            this.controllerChartData$ = controller;
            this.alarmCharData$ = alarm;
            this.activeEventCharData$ = activeEvent;
            this.activeOperationsCharData$ = activeOpers;
            this.alertsCharData$ = alert;
        });
    }
}
