export const ITEMS = [{
    name: "Dashboard",
    icon: "dashboard",
    routerLink: "/mobile/DashboardMobile"
},
{
    name: "Notifications",
    icon: "notification_important",
    theChosen: false,
    showSubmenu: true,
    children: [
        { name: "Edge Events", routerLink: "/mobile/Notifications/EdgeEvents" },
        { name: "Controller Events", routerLink: "/mobile/Notifications/ControllerEvents" },
        { name: "System Events", routerLink: "/mobile/Notifications/SystemEvents" },
        { name: "User Operations", routerLink: "/mobile/Notifications/UserOperations" },
        { name: "Alerts", routerLink: "/mobile/Notifications/Alerts" }
    ]
}
];
