
export class User {
    contact: string;
    group: string;
    group_id: number;
    name: string;
    password: string;
    project: string;
    project_id: number;
    operator_id: number;
    operator_name: string;
    customer_id: number;
    customer_name: string;
    username: string;
    id: number;
    is_readonly: boolean;
    auth_type: number; 
    auth_type_text: 'Local' | 'RADIUS';

    public constructor() {

        this.contact = '';
        this.group = '1';
        this.group_id = 1;
        this.name = '';
        this.password = '';
        this.project = '1';
        this.project_id = 1;
        this.username = '';
        this.id = 0;

        this.operator_id = 0;
        this.customer_id = 0;
        this.is_readonly = false;
        this.auth_type_text = 'Local' 
    }

} 