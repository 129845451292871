<span *ngIf="row[column]?.toString().length<80  && condition">{{ row[column] | rowFormatter: column }}</span>


<div *ngIf="row[column]?.toString().length>79 && condition">
  <div id='menu_trigger' (click)="openMyMenu(menuTrigger, row, $event)">
    <span><a href="javascript:void(0)">{{row[column]}}</a></span>
  </div>
  <div class="menu" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu_alarm"></div>
  <mat-menu id='menu_triggered' #menu_alarm="matMenu" overlapTrigger="false" (mouseleave)="menuTrigger.closeMenu()" [class]="'alertDropDown'">
    <ng-template matMenuContent let-rowData="rowData">
      <mat-card appearance="outlined" (click)="$event.stopPropagation()">
        <textarea rows="5" [readonly]="true" matInput placeholder="Description" [value]="rowData[column]" class="form-control"></textarea>
      </mat-card>
    </ng-template>
  </mat-menu>
</div>
