import {
    AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import * as echarts from "echarts";
import { ECharts } from "echarts";
import { colors } from "src/app/models";
import { AlertsChartData } from "src/app/models/interfaces/AlertsChartData";

@Component({
    selector: "telco-mobile-alerts-chart",
    templateUrl: "./mobile-alerts-chart.component.html",
    styleUrls: ["./mobile-alerts-chart.component.scss"]
})
export class MobileAlertsChartComponent implements OnChanges, AfterViewInit {
    @ViewChild("alertsChartSelector", { read: ElementRef, static: false }) alertsChartSelector: ElementRef;
    @Input() alertsCharData: AlertsChartData;

    alertsChart: any;
    colors: typeof colors = colors;
    myChart: ECharts;
    alertsCount: number;
    isExpanded: boolean = false;
    convertedNameValueArray: object;

    constructor(private router: Router, private ngZone: NgZone) {}

    ngOnChanges(): void {
        if (this.alertsCharData) {
            this.ngZone.runOutsideAngular(() => {
                this.initChart();
            });
        }
    }

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.myChart = echarts.init(this.alertsChartSelector.nativeElement);
            this.setupEventHandlers();
            this.resizeChart();
        });
    }

    setupEventHandlers(): void {
        this.myChart.on("click", this.toggleExpandState.bind(this));
    }

    recenter(value: number) {
        const thresholds = [
            { max: 10, percent: "17.5%" },
            { max: 100, percent: "16%" },
            { max: 1000, percent: "14.5%" },
            { max: 10000, percent: "13.5%" },
            { max: 100000, percent: "12.5%" },
            { max: 1000000, percent: "11.5%" }
        ];

        const foundThreshold = thresholds.find((element) => value < element.max);
        return foundThreshold ? foundThreshold.percent : "17.5%";
    }

    onChartClick() {
        this.toggleExpandState();
    }

    toggleExpandState(): void {
        this.isExpanded = !this.isExpanded;
        this.ngZone.runOutsideAngular(() => {
            this.initChart();
        });
    }

    onButtonClick(): void {
        this.router.navigate(["/mobile/Notifications/Alerts"]);
    }

    convertNameValueArray() {
        this.convertedNameValueArray = this.alertsCharData.count > 0 ? [{ name: "Active Alerts", value: this.alertsCharData.count, itemStyle: { color: colors.RED } }] : [];
        return this.convertedNameValueArray;
    }

    initChart(): void {
        this.alertsCount = this.alertsCharData.count;
        this.alertsChart = {
            title: [{
                text: this.alertsCount,
                top: "center",
                left: this.recenter(this.alertsCount),
                textStyle: {
                    fontSize: 12,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            {
                text: "Alerts",
                top: "center",
                left: 120,
                textStyle: {
                    fontSize: 14,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                },
            }],
            tooltip: {
                trigger: "item",
                textStyle: {
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            legend: {
                show: false,
            },
            series: [{
                type: "pie",
                radius: ["35%", "70%"],
                center: ["20%", "50%"],
                label: {
                    show: true,
                    position: "inner",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                    formatter: (params) => params.value
                },
                labelLine: {
                    normal: {
                        show: false,
                    }
                },
                hoverAnimation: false,
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: "#fff",
                },
                silent: true,

                data: this.convertNameValueArray()
            }]
        };
        this.myChart.setOption(this.alertsChart);
    }

    resizeChart(): void {
        if (this.myChart) {
            this.myChart.resize({ width: 300, height: 150 });
        }
    }
}
