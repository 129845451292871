import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { save } from '../components/helpers/disable-save.interceptor';
import { User } from '../models';
import { TokenInfo } from '../models/interfaces/tokenInfo';
import { ResourceService } from './resource.service';


@Injectable()
export class UserService {


  constructor(private resourceService: ResourceService, private http:HttpClient) {

  }

  getUsersCount(): Observable<number>{
    return this.http.get<number>(this.resourceService.getApiUrl() +"/user/count")
 }

  getUsers(page,count,order,direction): Observable<User[]>{
     return this.http.get<User[]>(this.resourceService.getApiUrl() +`/user?page=${page}&count=${count}&order=${order}&direction=${direction}`)
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<User>(this.resourceService.getApiUrl()  + '/user/' + userId);
  }

  createUser(user: User):Observable<any> {
    const context = new HttpContext().set(save, true);
    return this.http.put(this.resourceService.getApiUrl() +'/user',user, { context })
  }

  updateUser(userId: number, user: User) {
    const context = new HttpContext().set(save, true);
    return this.http.post(this.resourceService.getApiUrl() + '/user/' + userId, user, { context });
  }

  getTokenInfo():Observable<TokenInfo>{
    return this.http.get<TokenInfo>(this.resourceService.getApiUrl()  + '/user/token/info');
  }

  saveUser(user: User) {
    return this.http.put(this.resourceService.getApiUrl()  + '/users/', user);
  }

  deleteUser(userId: number): Observable<any> {
    return this.http.delete(this.resourceService.getApiUrl()  + '/user/' + userId);
  }

  getGroups():Observable<any>{
    return this.http.get<any>(this.resourceService.getApiUrl()+'/user/groups')
  }
}
