export class TokenInfo {
  exp: string;
  is_super_user: number;
  nbf: string;
  project: string;
  role: string;
  role_id: number;
  user: string;
  username: string;
  is_readonly: string;
  project_id?: any;
  customer_id?: any;
  customer: string;
  operator_id?: any;
  operator: string;
  name?: string;
  id?: string;
  operator_name?: string;
  customer_name?: string;

  public constructor() {
    this.exp = '';
    this.is_super_user = 0;
    this.nbf = '';
    this.project = '';
    this.role = '';
    this.role_id = 0;
    this.user = '';
    this.username = '';
    this.is_readonly = 'false';
    this.customer = '';
    this.operator = '';
  }
}
