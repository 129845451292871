import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppConfigService } from 'services/app-config.service';
import { SseService } from 'services/sse.service';
import { ThemeService } from 'services/theme.service';
import { ResourceService } from 'src/app/services/resource.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.scss' ]
})
export class NavbarComponent implements OnInit {
  @ViewChild('toolbarRef', { read: ElementRef }) toolbarRef;
  logPath: string;
  trademark = {
    telcoTheme: 'assets/logos/telcoTheme.png',
    telco: 'assets/svg/logo.svg',
    albisElconTheme: `assets/theme/albisElconTheme/albisElconTheme.png`
  };

  constructor(public resourceService: ResourceService,
              public themeService: ThemeService,
              private config: AppConfigService,
              private sseService: SseService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.logPath = this.trademark[this.themeService.theme] || this.trademark.telco;
    this.logPath = this.config?.config?.theme ? `assets/theme/${this.config.config.theme}/${this.config.config.theme}.png` : this.trademark.telco;

    this.sseService.initEventSourceSingleton();
    this.themeService.primary.subscribe(value => {
      this.toolbarRef.nativeElement.style.setProperty('--primary', value);
    });
    this.themeService.image.pipe(filter(x => x != '1')).subscribe(value => {
      this.logPath = value || this.trademark[this.themeService.theme] || this.trademark.telco;
    });
  }

  navigateToDashboard(): void {
    this.router.navigate(['/Dashboard']);
  }
}
