import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { save } from '../components/helpers/disable-save.interceptor';
import { ActionResponse, Site } from '../models';
import { Response } from '../models/types/common';
import { ResourceService } from './resource.service';


@Injectable()
export class SiteService {


  constructor(private resourceService: ResourceService, private http:HttpClient) {
  }


  getSitesCount(): Observable<number>{
     return this.http.get<number>(this.resourceService.getApiUrl() +"/site/count")
  }

  getSites(page:number,count:number,order='name',direction='asc'): Observable<Site[]>{
     return this.http.get<Site[]>(this.resourceService.getApiUrl() +`/site?page=${page}&count=${count}&order=${order}&direction=${direction}`)
  }

  getSite(projectId: number): Observable<Site> {
    return this.http.get<Site>(this.resourceService.getApiUrl()  + '/site/' + projectId);
  }

  createSite(site: Site):Observable<any> {
    const context = new HttpContext().set(save, true);
  return this.http.put(this.resourceService.getApiUrl() +'/site',site, { context })
  }

  updateSite(siteId:number,site: Site) {
    const context = new HttpContext().set(save, true);
    return this.http.post(this.resourceService.getApiUrl()  + '/site/'+ siteId, site, { context });
  }

  saveSite(site: Site) {
    return this.http.put(this.resourceService.getApiUrl()  + '/sites/', site);
  }

  deleteSite(siteId: number): Observable<any> {
    return this.http.delete(this.resourceService.getApiUrl()  + '/site/' + siteId);
  }

  deleteSites(siteIds: number[]): Observable<ActionResponse[]> {
    return this.http.post<ActionResponse[]>(this.resourceService.getApiUrl()  + '/sites/delete', siteIds);
  }


  getSitesByServiceId(serviceId: number, page = 1, count = 20,id_list:boolean=true): Observable<Response<Site>> {
    return this.http.get<Response<Site>>(this.resourceService.getApiUrl() + `/site?service_id=${serviceId}&page=${page}&count=${count}&id_list=${id_list}`);
  }
}
