import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';
import { ITEMS } from './model/items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  isSideMenuOpen = true;
  displayBreadcrump = true;
  rootUrl: string;
  @ViewChild('drawer', { static: false }) public drawer: MatDrawer;

  readonly items = ITEMS.map(obj => ({ ...obj })).filter(x => {
    if (x.children) {
      x.children = x.children?.filter(z => this.readOnlyXServiceDesigner(z));
    }
    return this.readOnlyXAdministrations(x);
  });

  private breadCrumbRouters = [
    'Edge%20Management',
    'Troubleshoot',
    'Alarms',
    'VPN%20Management',
    'Discovery',
    'Administration',
    'Service%20Management',
    'Controller%20Management',
    'Application%20Management',
    'Notifications'
  ];

  constructor(
    private cookies: CookieService,
    private router: Router
  ) {
  }

  readOnlyXServiceDesigner(x) {
    return !(x.name == 'Service Designer' && this.cookies.get('is_readonly') == 'true');
  }

  readOnlyXAdministrations(x) {
    return !(x.name == 'Administration' && this.cookies.get('role_id') == '2');
  }

  ngOnDestroy(): void {
    this.items.forEach(z => z.showSubmenu = false);
    this.items.forEach(z => {
      z.theChosen = false;
      z.children?.forEach(x => x.theChosenChild = false);
    });
  }

  ngAfterViewInit() {
    this.drawer.openedStart.subscribe(() => this.isSideMenuOpen = true);
    this.drawer.closedStart.subscribe(() => this.isSideMenuOpen = false);

  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe(
      () => {
        this.breadcrumb();
        this.redirectSelection();
      });
    this.breadcrumb();
  }

  redirectSelection() {
    if (this.router.url === '/Kubernetes%20Service%20Management/ServiceTemplateCatalog/ServiceTemplateCatalog') {
      const serviceManagementItem = this.items.find(el => el.name == 'Kubernetes Service Management');
      if (serviceManagementItem) {
        const child = serviceManagementItem.children?.find(el => el.name === 'Service Templates Catalog');
        if (child && child['theChosenChild'] === false) {
          this.onItemChildren(serviceManagementItem, child);
        }
      }
    }
  }

  breadcrumb() {
    this.rootUrl = this.router.url.split('/')[1];
    if (this.breadCrumbRouters.includes(this.rootUrl)) {
      this.displayBreadcrump = true;
    }
    if (this.rootUrl === 'Dashboard') {
      this.displayBreadcrump = false;
    } else this.displayBreadcrump = true;
  }

  onItemSelected(item) {
    // this.items.forEach(x => x.theChosen = false);
    // item.theChosen = true;
    if (item.children) {
      item.showSubmenu = !item.showSubmenu;
    }
    if (!this.isSideMenuOpen && item.children) {
      this.isSideMenuOpen = true;
    }
  }

  onItemChildren(parent, child) {
    this.items.forEach(z => {
      z.theChosen = false;
      z.children?.forEach(x => x.theChosenChild = false);
    });

    parent.theChosen = true;
    child.theChosenChild = true;
  }

}
