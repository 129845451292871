import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable()
export class UtilsService {
  forceRefreshTable = new Subject();

  public constructor() { }

  validateIp4WithMaskStruct(ip: any, needRealMask: boolean) {
    if (this.isEmpty(ip)) {
      return true;
    }

    const splitAddressSubnet = ip.split("/");

    return this.v4MaskValidation(splitAddressSubnet[1], needRealMask) && this.ipv4ValidatorNoMask(splitAddressSubnet[0]);
  }

  ipv4ValidatorNoMask(address: string) {

    if (this.isEmpty(address)) {
      return true;
    }

    const splitAddress = address.split(".");

    if (splitAddress.length !== 4) {
      return false;
    }

    for (let index = 0; index < 4; index++) {
      if (splitAddress[index].length > 3 || splitAddress[index].length < 1) {
        return false;
      }
      const tempNumber = Number(splitAddress[index]);
      if (!(tempNumber >= 0 && tempNumber <= 255)) {
        return false;
      }
    }
    return true;
  }

  v4MaskValidation(mask: string, needMask: boolean) {

    if (this.isUndefined(mask)) {
      if (needMask) {
        return false;
      } else {
        return true;
      }
    }
    //mask = mask.replace(/\s/g, "");

    if (mask.length > 2 || mask.length < 1 || (mask.length > 1 && mask[0] == '0') || mask.trim() == '0') {
      return false;
    }
    const maskValue = Number(mask);
    if (this.isUndefined(maskValue) || maskValue < 0 || maskValue > 32) {
      return false;
    }
    return true;
  }

  isUndefined(element) {
    return element === null || element === undefined || Number.isNaN(element);
  }

  isEmpty(str: any): boolean {
    if (
      str == undefined ||
      str == null ||
      str.length == 0 ||
      str.toString().trim().length == 0
    )
      return true;
    return false;
  }

  validateVLAN(vlanId: number) {
    if (vlanId > 0 && vlanId < 4096) {
      return true
    }
    return false
  }

  validateIpv6WithMask(input: string) {
    let ans = false;

    if (this.isEmpty(input)) {
      return true;
    }

    const splittedInput = input.split("/");
    const address = splittedInput[0];
    const mask = Number(splittedInput[1]);

    if (address && !this.isUndefined(mask) && !(splittedInput[1]== '')) {
      ans = this.validateIpv6Regex(address) && mask >= 0 && mask <= 128;
    }

    return ans;
  }

  validateIpv6NoMask(input: string) {
    let ans = false;
    if (this.isEmpty(input)) {
      return true;
    }

    ans = this.validateIpv6Regex(input);
    return ans;
  }

  validateIpv6Regex(ipv6: string) {
    if (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/.test(ipv6)) {

      return true;
    } else {
      return false;
    }
  }

  validateLoopback(loopback: number) {
    return (Number.isInteger(loopback) && (loopback>=1) && (loopback < 100)) ? true : false;
  }

  compareObjects(o1: any, o2: any): boolean {
    return JSON.stringify(o1) == JSON.stringify(o2)
  }
}
