import { HttpClient, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertNotificationService } from 'services/alertNotification.service';
import { UtilsService } from 'services/utils.service';

export const SKIP_STATUS = new HttpContextToken(() => false);

@Injectable()
export class StatusInterceptor implements HttpInterceptor {
  readonly methods = {DELETE: 'delete', POST: 'post', GET: 'get', PUT: 'put'};

  constructor(private alertNotificationService: AlertNotificationService, private http: HttpClient, private sse: UtilsService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req)
      .pipe(
        tap((res: any) => res.status == 202 && !req.context.get(SKIP_STATUS) && this.alertNotificationService.alertInfo(res.body))
      );
  }
}
