import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { shareReplay } from 'rxjs/operators';
import { cancelPreviousReq } from '../components/helpers/cancelPreviousReq';
import { Alarm } from '../models/Alarm';
import { ControllerEvent } from '../models/interfaces/controllerEvent';
import { UserAction } from '../models/interfaces/userAction';
import { ResourceService } from './resource.service';

@Injectable()
export class AlarmsAndEventsService {
  alarm: Alarm;
  constructor(private resourceService: ResourceService, private http: HttpClient) {
  }

  getAlerts(order: string, direction: string) {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/alert?order=${order}&direction=${direction}`)
  }

  getAlertById(id: number): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/alert/${id}`)
  }

  getControllerEventById(id: number): Observable<ControllerEvent> {
    return this.http.get<ControllerEvent>(this.resourceService.getApiUrl() + `/event/controller/${id}`)
  }

  getAlertsAdvanced(page: number = 1, count: number = 20, order: string, direction: string, is_not_ack: boolean = false, filterData: any = []): Observable<any> {
    if (page == 0) page = 1;
    let requestString = `/alert/advanced?page=${page}&count=${count}`;
    order && order != '' ? requestString += `&order=${order}` : null;
    direction && direction != '' ? requestString += `&direction=${direction}` : null;
    requestString += `&is_not_ack=${is_not_ack}`;
    return this.http.post<any[]>(this.resourceService.getApiUrl() + requestString, { 'filter': filterData }).pipe(shareReplay())
  }

  getControllerEventsAdvanced(page: number, count: number, order: string, direction: string, is_active = false, controller_id: number = 0, severity_id: number = -1, filters: any) {
    if (page == 0) page = 1;
    let requestString = `/event/controller/advanced?page=${page}&count=${count}`
    order && order != '' ? requestString += `&order=${order}` : null;
    direction && direction != '' ? requestString += `&direction=${direction}` : null;
    requestString += `&is_active=${is_active}`
    requestString += `&controller_id=${controller_id}`
    requestString += `&severity_id=${severity_id}`

    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, { 'filter': filters }).pipe(shareReplay())
  }

  doClearAlerts(page: number, count: number, alerts_ids: number[], reason: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/alert/advanced?page=${page}&count=${count}`, { 'clear': alerts_ids, 'clear_reason': reason })
  }

  doResumeAlerts(page: number, count: number, alerts_ids: number[], reason: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/alert/advanced?page=${page}&count=${count}`, { 'resume': alerts_ids, 'resume_reason': reason })
  }

  getUserActions(order: string, direction: string) {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/action?order=${order}&direction=${direction}`)
  }

  getUserActionsAdvanced(page: number, count: number, order: string, direction: string, is_active: boolean, filterData: any): Observable<any> {
    if (page == 0) page = 1;
    let requestString = `/action/advanced?page=${page}&count=${count}`
    order && order != '' ? requestString += `&order=${order}` : null;
    direction && direction != '' ? requestString += `&direction=${direction}` : null;
    requestString += `&is_active=${is_active}`;
    return this.http.post<any[]>(this.resourceService.getApiUrl() + requestString, { 'filter': filterData }).pipe(shareReplay())
  }

  getUserActionById(id: number): Observable<UserAction> {
    return this.http.get<UserAction>(this.resourceService.getApiUrl() + `/action/${id}`)
  }


  getAlarms(page: number, count: number, order: string, direction: string, is_active: boolean = false, node_id: number = 0, severity_id: number = -1, filterData: any): Observable<any> {
    if (page == 0) page = 1;
    let requestString = `/event/node/advanced?page=${page}&count=${count}`
    order && order != '' ? requestString += `&order=${order}` : null;
    direction && direction != '' ? requestString += `&direction=${direction}` : null;
    requestString += `&is_active=${is_active}`
    requestString += `&node_id=${node_id}`
    requestString += `&severity_id=${severity_id}`

    // const headers = new HttpHeaders({ timeout: '600000' });
    return this.http.post<Alarm[]>(
      this.resourceService.getApiUrl() + requestString,
      { filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData })
      .pipe(cancelPreviousReq(), shareReplay());
  }

  getAlarmById(id: number): Observable<Alarm> {
    return this.http.get<Alarm>(this.resourceService.getApiUrl() + `/event/node/${id}`)
  }

  storeAlarm(alarm: Alarm) {
    this.alarm = alarm;
  }
  getAlarm() {
    return this.alarm;
  }
  clearAlarm() {
    this.alarm = null;
  }

  //node events
  getBulkNodeEventIds(id_only: true, filter: any): Observable<number[]> {
    {
      return this.http.post<number[]>(this.resourceService.getApiUrl() + '/event/node/advanced', { "id_only": id_only, "filter": filter })
    }
  }

  doAcknowledgeNodeEvents(page: number, count: number, ack_alarms_ids: number[], ack_reason: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/event/node/advanced?page=${page}&count=${count}`, { 'ack': ack_alarms_ids, 'ack_reason': ack_reason })
  }

  //controller operations
  getBulkControllerEventIds(id_only: true, filter: any): Observable<number[]> {
    {
      return this.http.post<number[]>(this.resourceService.getApiUrl() + '/event/controller/advanced', { "id_only": id_only, "filter": filter })
    }
  }

  doAcknowledgeControllerEvents(page: number, count: number, ack_alarms_ids: number[], ack_reason: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/event/controller/advanced?page=${page}&count=${count}`, { 'ack': ack_alarms_ids, 'ack_reason': ack_reason })
  }

  //user operations

  getBulkUserOperationIds(id_only: true, filter: any): Observable<number[]> {
    {
      return this.http.post<number[]>(this.resourceService.getApiUrl() + '/action/advanced', { "id_only": id_only, "filter": filter })
    }
  }

  doAcknowledgeUserOperations(page: number, count: number, ack_items_ids: number[], ack_reason: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/action/advanced?page=${page}&count=${count}`, { 'ack': ack_items_ids, 'ack_reason': ack_reason })
  }

  doCancelUserOperations(page: number, count: number, items_ids: number[]): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/action/advanced?page=${page}&count=${count}`, { 'cancel': items_ids })
  }

  //alerts

  getBulkAlertIds(id_only: true, filter: any): Observable<number[]> {
    {
      return this.http.post<number[]>(this.resourceService.getApiUrl() + '/alert/advanced', { "id_only": id_only, "filter": filter })
    }
  }

  doAcknowledgeAlerts(page: number, count: number, alerts_ids: number[], reason: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/alert/advanced?page=${page}&count=${count}`, { 'ack': alerts_ids, 'ack_reason': reason })
  }

  //Events system
  getSystemEventsAdvanced(page: number, count: number, order: string, direction: string, is_active: boolean = false, filters: any) {
    if (page == 0) page = 1;
    let requestString = `/event/system/advanced?page=${page}&count=${count}`
    order && order != '' ? requestString += `&order=${order}` : null;
    direction && direction != '' ? requestString += `&direction=${direction}` : null;
    requestString += `&is_active=${is_active}`
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, { 'filter': filters }).pipe(shareReplay())
  }

  getSystemEventById(id: number): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/event/system/${id}`)
  }

  getBulkSystemEventIds(id_only: true, filter: any): Observable<number[]> {
    {
      return this.http.post<number[]>(this.resourceService.getApiUrl() + '/event/system/advanced', { "id_only": id_only, "filter": filter })
    }
  }

  doAcknowledgeSystemEvents(page: number, count: number, ack_items_ids: number[], ack_reason: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/event/system/advanced?page=${page}&count=${count}`, { 'ack': ack_items_ids, 'ack_reason': ack_reason })
  }

}
