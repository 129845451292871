import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { finalize, tap } from 'rxjs/operators';
import { SseService } from 'services/sse.service';
import { ThemeService } from 'services/theme.service';
import { AlertNotificationService } from './alertNotification.service';
import { ResourceService } from './resource.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  apiUrl: string;
  disableBtn = false;

  public constructor(private resourceService: ResourceService,
                     private router: Router,
                     private themeService: ThemeService,
                     private cookieService: CookieService,
                     private alertNotificationService: AlertNotificationService,
                     private http: HttpClient,
                     private sseService: SseService) {
  }

  login(username: string, password: string) {
    this.disableBtn = true;
    if (this.resourceService.isResourcesLoaded == false) {
      this.alertNotificationService.alertWarning('Aplication is not ready.Please try again in a few seconds.');
      return;
    }
    this.apiUrl = this.resourceService.getTokenUrl();

    const headers = new HttpHeaders({ 'content-type': 'application/json' });

    this.http.post(this.apiUrl, { password, username }, { headers, responseType: 'text' }).pipe(finalize(() => this.disableBtn = false))
      .subscribe(token => {
        this.cookieService.set('currentToken', token);
        this.cookieService.set('currentUsername', username);
        this.exportTokenInfo();
      });
  }

  logout() {
    this.sseService.evtSource.close();
    this.cookieService.deleteAll();
    this.themeService.resetThemeVariables()
    this.router.navigate([ 'Login' ]);
  }

  exportTokenInfo() {
    this.resourceService.getTokenInfo()
      .pipe(tap(res => this.themeService.setPerProjectThemeOnLogin(res.project_id)))
      .subscribe(tokenInfo => {
          if (tokenInfo != undefined) {
            this.cookieService.set('is_super_user', tokenInfo.is_super_user.toString());
            this.cookieService.set('project', tokenInfo.project);
            this.cookieService.set('role', tokenInfo.role);
            this.cookieService.set('role_id', tokenInfo.role_id.toString());
            this.cookieService.set('username', tokenInfo.username);
            this.cookieService.set('user', tokenInfo.user);
            this.cookieService.set('id', tokenInfo.id || '0');

            this.cookieService.set('is_readonly', tokenInfo.is_readonly);
            this.cookieService.set('customer_id', tokenInfo.customer_id);
            this.cookieService.set('operator_id', tokenInfo.operator_id);

            if (tokenInfo['project_id']) {
              this.cookieService.set('project_id', tokenInfo['project_id']);
            }

            if (this.router.url === '/Login') {
              this.router.navigate([ 'Dashboard' ]);
            }
          }
        }
      );
  }
}

