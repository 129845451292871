<div class="charts-wrapper">
  <div class="chart" id="ucpe-chart" >
    <telco-mobile-ucpe-charts [ucpeCharData]="ucpeChartData$" [typeChart]="'ucpe'" ></telco-mobile-ucpe-charts>
  </div>

  <div class="chart" id='controller-chart' >
    <telco-mobile-ucpe-charts [ucpeCharData]="controllerChartData$" [typeChart]="'controller'"></telco-mobile-ucpe-charts>
  </div>

  <div class="chart" id='alarm-chart' >
    <telco-mobile-alarms-chart [alarmCharData]="alarmCharData$" ></telco-mobile-alarms-chart>
  </div>

  <div class="chart" id='contr-chart' >
    <telco-mobile-controller-event-chart [activeEventCharData]="activeEventCharData$" ></telco-mobile-controller-event-chart>
  </div>    

  <div class="chart" id='alerts-chart' >
    <telco-mobile-alerts-chart [alertsCharData]="alertsCharData$"></telco-mobile-alerts-chart>
  </div>

  <div class="chart" id='active-oper-chart' >
    <telco-mobile-active-operations-chart [alarmCharData]="activeOperationsCharData$" ></telco-mobile-active-operations-chart>
  </div>
</div>