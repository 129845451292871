import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisableSaveService {

  disableButtons: boolean = false;
  disableButtonsUnique: boolean = false;

}
