
export class TraceHostCfg {
  constructor() {
    this['trace-state-propagation'] = false;
    this.enable = false;
    this.interval = 10;
    this.timeout = 3;
    this.count = 3;
    this['change-count'] = 3;
    this.probes = new Array<TraceHostProbe>();
    this.probes.push(new TraceHostProbe())
  }
  'trace-state-propagation': boolean;
  enable: boolean;
  interval: number;
  timeout: number;
  count: number;
  'change-count': number;
  probes: TraceHostProbe[];
}

export class TraceHostProbe {
  constructor() {
  this.id = 1;
    this.description = '';
    this.endpoint = '';
    this.type = "icmp";
    this['max-rtt'] = 1;
    // this['dst-port'] = null;
    // this['full-path'] = '';
    // this['resolve-hostname'] = '';
    this["min-upload"] = '';
    this["min-download"] = '';
    this.direction = "both";
  }
  id: number;
  description: string;
  endpoint: string;
  type: string;
  'max-rtt': number;
  // 'dst-port': number;
  // 'full-path': string;
  // 'resolve-hostname': string;
  'min-upload': string;
  'min-download': string;
  direction: string;
}

export class SmartProtocol {
  protocol: string;
  enable: boolean;
  'tracehost-interface': string;
  'tracehost-probe-id': number;

  constructor() {
    this.protocol = 'BGP';
    this['tracehost-interface'] = '';
    this['tracehost-probe-id'] = 1;
    this.enable = false;
  }
}

export class interfaceModel {

  constructor(i, bootproto, management) {
    this.name = 'data' + i;
    this.type = 'ethernet';
    this.mode = 'virtio';
    this.bootproto = bootproto;
    this["ip-passthrough"] = false;
    this.dns = '';
    this.dns6 = '';
    this.gateway = '';
    this.gateway6 = '';
    this["ipv4-prefix"] = '';
    this["ipv6-prefix"] = '';
    this.management = management;
    this.metric = 100;
    this["trace-host"] = '';
    this["link-state-propagation"] = true;
    this.vswitch = '';
    this.tracehost = new TraceHostCfg();
    this['smart-protocol-passthrough'] = [new SmartProtocol()];
  }

  management: boolean;
  name: string;
  vlan: number;
  bootproto: string;
  "ip-passthrough": boolean;
  mode: string;
  dns: string;
  dns6: string;
  "ipv4-prefix": string;
  "ipv6-prefix": string;
  type: string;
  gateway: string;
  gateway6: string;
  metric: number;
  "trace-host": string;
  "link-state-propagation": boolean;
  vswitch: string;
  tracehost: TraceHostCfg;
  'smart-protocol-passthrough'?: SmartProtocol[];

}
