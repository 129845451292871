import { Pipe, PipeTransform } from '@angular/core';
import { formatDateTime } from '../../helpers/utilities';

@Pipe({
  name: 'rowFormatter'
})
export class RowFormatterPipe implements PipeTransform {
  transform(row: any, column: string): string {
    const formatRows = {
      is_readonly: () => row ? 'Read-only' : 'Read/Write',
      update_time: () => formatDateTime(row),
      start_time: () => formatDateTime(row),
      end_time: () => formatDateTime(row),
      create_time: () => formatDateTime(row),
      ack_time: () => formatDateTime(row),
      design: () => row ? column : '',
      in_use: () => '',
      is_local: () => '',
      is_active: () => row ? 'Active' : 'Not active',
      is_ignored: () => row ? 'Blacklisted' : 'Listed',
      is_ack: () => row ? 'Acknowledged' : 'Not acknowledged',
      oper_status: () => row.status,
      dns_nameservers: () => row.toString(),
      host_routes: () => row.map(r => { return `destination:${r['destination']}, nexthop:${r['nexthop']} ` }),
      allocation_pools: () => row.map(r => { return `start:${r['start']}, end:${r['end']} ` }),
      max_shared: () => row == -1 ? 'UNLIMITED' : row,
      k8s_service_admin_status: () => row=='stopped' ? 'terminated' : row=='pending stop' ? 'pending terminate' : row=='failed os_service stop' ? 'failed service terminate': row,
      os_service_admin_status:  () => row=='stopped' ? 'terminated' : row=='pending stop' ? 'pending terminate' : row=='failed os_service stop' ? 'failed os_service terminate': row,
    };

    return formatRows[column]?.() || (row !== Object(row) ? row : '');
  }
}


