import {
    Component, EventEmitter, Input, Output, ViewChild
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";

import { ITEMS } from "./model/items";

@Component({
    selector: "telco-sidenav-mobile",
    templateUrl: "./sidenav-mobile.component.html",
    styleUrls: ["./sidenav-mobile.component.scss"]
})
export class SidenavMobileComponent {
    @ViewChild("drawer", { static: false }) public drawer: MatSidenav;
    @Input() isOverlayVisible: boolean;

    @Output() sidenavStateChange = new EventEmitter<boolean>();

    readonly items = ITEMS;

    toggleSidenav() {
        this.drawer.toggle();
        this.sidenavStateChange.emit(this.drawer.opened);
    }

    onItemSelected() {
        this.drawer.close();
        this.sidenavStateChange.emit(false);
    }
}
