import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AdvancedFilterService } from '../components/advanced-filter/advanced-filter.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppliedFilterService {
  user: string;
  filterNameForComponent: string;

  constructor(private localStorageService: LocalStorageService, public cookieService: CookieService, private advancedFilterService: AdvancedFilterService) {
  }

  saveFilters(filterName: string, value: any, sortTableToFilter?: boolean): void {
    this.user = this.cookieService.get('user') || 'admin@admin';
    const state = this.localStorageService.getItem(this.user);
    if (sortTableToFilter) {
      state[filterName] = { sortTable: value, name: this.advancedFilterService.appliedFilterName, filters: state[filterName].filters }
    } else {
      state[filterName] = { name:this.advancedFilterService.appliedFilterName, filters: value };
    }
    this.localStorageService.setItem(this.user, state);
  }

  getFilters(item: string): any {
    this.user = this.cookieService.get('user') || 'admin@admin';
    return this.localStorageService.getItem(this.user)[item];
  }

  removeFilters(item: string): void {
    this.user = this.cookieService.get('user') || 'admin@admin';
    const state = this.localStorageService.getItem(this.user);
    delete state[item];
    this.localStorageService.setItem(this.user, state);
  }
}
