import { Injectable } from "@angular/core";
import {
    CanActivate, CanActivateChild, Router, UrlTree
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";

import { AppConfigService } from "../../services/app-config.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private cookieService: CookieService, private appConfigService: AppConfigService) {
    }

    canActivate() {
        if (this.appConfigService.tokenExpired) {
            this.cookieService.deleteAll();
            return this.router.parseUrl("/Login");
        }
        return true;
    }

    canActivateChild(): true | UrlTree {
        return this.canActivate();
    }
}
