export class Customer {
  id: number;
  name: string;
  market?: string;
  operator?: string;
  operator_id?: number;
  project?: string;
  project_id?: number;

  constructor(id?, name?, market?, operator?, operator_id?, project?, project_id?, public max_shared?: number) {
    this.id = id;
    this.name = name;
    this.market = market;
    this.operator = operator;
    this.operator_id = operator_id;
    this.project = project;
    this.project_id = project_id;
  }
}

