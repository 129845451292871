import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { save } from '../components/helpers/disable-save.interceptor';
import { Operator } from '../models';
import { Response } from '../models/types/common';
import { ResourceService } from './resource.service';

@Injectable()
export class OperatorService {


  constructor(private resourceService: ResourceService, private http: HttpClient) {

  }


  getOperators(page: number, count: number, order: string = 'name', direction: string = 'asc', id_only = false): Observable<Response<Operator>> {
    return this.http.get<Response<Operator>>(this.resourceService.getApiUrl() + `/operator?page=${page}&count=${count}&order=${order}&direction=${direction}&id_only=${id_only}`);
  }


  getOperatorsByProjectId(project_id: number,page:number=1,count:number=20,order:string='name',direction='asc',id_only=false,id_list=true): Observable<Response<Operator>> {
    return this.http.get<Response<Operator>>(this.resourceService.getApiUrl() + `/operator?project_id=${project_id}&page=${page}&count=${count}&order=${order}&direction=${direction}&id_only=${id_only}&id_list=${id_list}`);
  }

  getOperator(operatorId: number): Observable<Operator> {
    return this.http.get<Operator>(this.resourceService.getApiUrl() + '/operator/' + operatorId);
  }

  createOperator(operator: Operator): Observable<any> {
    const context = new HttpContext().set(save, true);
    return this.http.put(this.resourceService.getApiUrl() + '/operator', operator, { context });
  }

  updateOperator(operatorId: number, operator: Operator) {

    const context = new HttpContext().set(save, true);
    return this.http.post(this.resourceService.getApiUrl() + '/operator/' + operatorId, operator, { context });
  }


  deleteOperator(operatorId: number): Observable<any> {
    return this.http.delete(this.resourceService.getApiUrl() + '/operator/' + operatorId);
  }

  getOperatorsCount(): Observable<number> {
    return this.http.get<number>(this.resourceService.getApiUrl() + '/operator/count');
  }
}
