import { CommonModule, TitleCasePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterLinkActive } from "@angular/router";
import { NgxEchartsModule } from "ngx-echarts";

import { AdvancedFilterComponent } from "../components/advanced-filter/advanced-filter.component";
import { ChartsHeaderComponent } from "../components/charts/charts-header/charts-header.component";
import { DiskChartComponent } from "../components/charts/disk-chart/disk-chart.component";
import { EchartsComponent } from '../components/charts/echarts/echarts.component';
import { InterfacesChartComponent } from "../components/charts/interfaces-chart/interfaces-chart.component";
import { MemoryChartComponent } from "../components/charts/memory-chart/memory-chart.component";
import { MultiCpuChartComponent } from "../components/charts/multi-cpu-chart/multi-cpu-chart.component";
import { NoDataAvailableComponent } from "../components/charts/no-data-available/no-data-available.component";
import { GpuChartComponent } from "../components/charts/status-chart/gpu-chart/gpu-chart.component";
import { ColumnFormatterPipe } from "../components/custom-components/custom-table/column-formatter.pipe";
import { CustomTableComponent } from "../components/custom-components/custom-table/custom-table.component";
import { DescriptionCaseComponent } from "../components/custom-components/custom-table/description-case/description-case.component";
import { DoneDirective } from "../components/custom-components/custom-table/done.directive";
import { RowFormatterPipe } from "../components/custom-components/custom-table/row-formatter.pipe";
import { UnderscoreRemoverPipe } from '../components/custom-components/custom-table/underscore-remover.pipe';
import { DisabledButtonComponent } from "../components/custom-components/disabled-button/disabled-button.component";
import { EditorModule } from "../components/custom-components/editor/editor.module";
import { FileUploadComponent } from "../components/custom-components/file-upload/file-upload.component";
import { NgVisViewComponent } from "../components/custom-components/ng-vis/ng-vis-view/ng-vis-view.component";
import { TelcoDatePickerComponent } from "../components/custom-components/telco-date-picker/telco-date-picker.component";
import { IperfTestComponent } from "../components/pages/troubleshooting/iperf-test/iperf-test.component";
import { AdvancedFilterDirective } from "../directive/advanced-filter.directive";
import { CustomTableDirective } from "../directive/custom-table.directive";
import { DataTimePipe } from "../directive/dataTime.directive";
import { KeepSortDirective } from "../directive/keep-sort.directive";
import { LiveModeDirective } from "../directive/live-mode.directive";
import { PaginatorStorageDirective } from "../directive/paginator-storage.directive";
import { ShiftSelectionDirective } from "../directive/shift-selection.directive";
import { MobileCustomTableComponent } from "../mobile/mobile-custom-table/mobile-custom-table.component";
import { HumanReadablePipe } from '../modules/Administration/projects-managment/project-settings/human-readable.pipe';
import { CustomDatePipePipe } from "../modules/EdgeManagement/Devices/custom-date-pipe.pipe";
import { FilterEmptyLabelPipe } from "../modules/EdgeManagement/Devices/filter-empty-label.pipe";
import { licensePipe } from "../modules/licenses/licences/license-pipe.pipe";
import { MyMaterialModule } from "../modules/my-material/my-material.module";
import { AppTabsComponent } from "./UI/app-tabs/app-tabs.component";
import { InputComponent } from "./UI/input/input.component";
import { SelectComponent } from './UI/select/select.component';
import { ValidationsErrorsComponent } from "./UI/validations-errors/validations-errors.component";
import { ControlValueAccessorDirective } from "./directives/control-value-accessor.directive";
import { FormatNamePipe } from './pipes/format-name.pipe';
import { TelcoUnalloyedReadRoleDirective } from "./telco-unalloyed-read-role.directive";
import { NewLineFormatterPipe } from "../components/custom-components/cluster-status/new-line-formatter.pipe";

@NgModule({
    declarations: [
        CustomTableComponent,
        MobileCustomTableComponent,
        DescriptionCaseComponent,
        PaginatorStorageDirective,
        DisabledButtonComponent,
        TelcoUnalloyedReadRoleDirective,
        ControlValueAccessorDirective,
        ColumnFormatterPipe,
        RowFormatterPipe,
        AdvancedFilterDirective,
        AdvancedFilterComponent,
        ShiftSelectionDirective,
        LiveModeDirective,
        CustomTableDirective,
        KeepSortDirective,
        CustomDatePipePipe,
        FilterEmptyLabelPipe,
        licensePipe,
        TelcoDatePickerComponent,
        FileUploadComponent,
        NgVisViewComponent,
        DataTimePipe,
        DoneDirective,
        MultiCpuChartComponent,
        NoDataAvailableComponent,
        GpuChartComponent,
        InterfacesChartComponent,
        MemoryChartComponent,
        DiskChartComponent,
        ChartsHeaderComponent,
        EchartsComponent,
        HumanReadablePipe,
        IperfTestComponent,
        UnderscoreRemoverPipe,
        AppTabsComponent,
        InputComponent,
        FormatNamePipe,
        ValidationsErrorsComponent,
        SelectComponent,
        NewLineFormatterPipe
    ],
    imports: [
        CommonModule,
        EditorModule,
        MyMaterialModule,
        RouterLinkActive,
        NgxEchartsModule,
        NgxEchartsModule.forRoot({
            echarts: () => import("echarts")
        }),
    ],
    exports: [
        ShiftSelectionDirective,
        MyMaterialModule,
        CustomTableComponent,
        MobileCustomTableComponent,
        DescriptionCaseComponent,
        PaginatorStorageDirective,
        DisabledButtonComponent,
        TelcoUnalloyedReadRoleDirective,
        ControlValueAccessorDirective,
        ColumnFormatterPipe,
        RowFormatterPipe,
        CommonModule,
        AdvancedFilterComponent,
        AdvancedFilterDirective,
        LiveModeDirective,
        CustomTableDirective,
        KeepSortDirective,
        CustomDatePipePipe,
        licensePipe,
        TelcoDatePickerComponent,
        FilterEmptyLabelPipe,
        FileUploadComponent,
        NgVisViewComponent,
        DataTimePipe,
        DoneDirective,
        MultiCpuChartComponent,
        NoDataAvailableComponent,
        GpuChartComponent,
        InterfacesChartComponent,
        MemoryChartComponent,
        DiskChartComponent,
        EchartsComponent,
        HumanReadablePipe,
        IperfTestComponent,
        UnderscoreRemoverPipe,
        AppTabsComponent,
        InputComponent,
        SelectComponent,
        FormatNamePipe,
        ValidationsErrorsComponent,
        NewLineFormatterPipe
    ],
    providers: [TitleCasePipe]
})
export class SharedModule {
}
