import { BasicEdgeInterface } from "..";

export class Vxlan implements BasicEdgeInterface {

  constructor() {
    this.enable = false;
    this.type = 'vxlan';
    this.management = false;
    this['tunnel-type'] = "vxlan";
    this.shg = 100;
    this.bootproto = 'none';
    this.local = new VtepAddr();
    this.remote = new RemoteVtep();
    this.dns = '';
    this.dns6 = '';
  }
  vxlanID: number;
  enable: boolean;
  name: string;
  type: string;
  management: boolean;
  vni: number;
  shg: number;
  "tunnel-type": 'vxlan' | 'vxlan-gpe'
  local: LocalVtep;
  remote: RemoteVtep;
  bootproto: 'dhcp' | 'dhcp6' | 'dhcp46' | 'none';
  "ipv4-prefix": string;
  "ipv6-prefix": string;
  dns: string;
  dns6: string;
}

export interface LocalVtep {
}

export class VtepAddr implements LocalVtep {
  "vtep-addr": string;
}

export class VtepInterface implements LocalVtep {
  "vtep-if": string;
}

export class RemoteVtep {
  "mcast-vtep-addr": string;
  "vtep-addrs": VtepAddr[];

  constructor() {
    this["vtep-addrs"] = new Array<VtepAddr>();

    //this["vtep-addrs"].push(new VtepAddr());
  }
}