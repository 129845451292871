import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

  message: string = ""
  alert_type: string = ""
  confirmButtonText = ""
  cancelButtonText = ""
  conditionText = ""

  restoreDefaultFormControl: FormControl = new FormControl(false)

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.alert_type = data.alert_type || this.alert_type;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        if (data.buttonText.confirm) {
          this.confirmButtonText = data.buttonText.confirm
        }

        if (data.buttonText.cancel) {
          this.cancelButtonText = data.buttonText.cancel
        }

        if (data.timeout != undefined) {
          setInterval(() => {
            this.dialogRef.close(true)
          }, data.timeout)
        }
        if (data.conditionText != undefined) {
          this.conditionText = data.conditionText
        }
      }
    }

    this.dialogRef.afterClosed().subscribe()
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }


}
