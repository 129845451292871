import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { SKIP_STATUS } from '../components/helpers/status.interceptor';
import { ActionResponse } from '../models';
import { ProjectProperties } from '../models/interfaces/ProjectProperties';
import { Project } from '../models/project';
import { Response } from '../models/types/common';
import { ResourceService } from './resource.service';
import { save } from '../components/helpers/disable-save.interceptor';

@Injectable()
export class ProjectService {


  constructor(private resourceService: ResourceService, private http: HttpClient) {

  }

  getProjectsCount(): Observable<number> {
    return this.http.get<number>(this.resourceService.getApiUrl() + '/project/count');
  }

  getProjects(page: number, count: number, order: string = 'name', direction: string = 'asc', idList = true): Observable<Response<Project>> {
    return this.http.get<Response<Project>>(this.resourceService.getApiUrl() + `/project?page=${page}&count=${count}&order=${order}&direction=${direction}&id_list=${idList}`);
  }

  getProject(projectId: number): Observable<Project> {
    return this.http.get<Project>(this.resourceService.getApiUrl() + '/project/' + projectId);
  }

  createProject(project: Project): Observable<any> {
    const context = new HttpContext().set(save, true);
    return this.http.put(this.resourceService.getApiUrl() + '/project', project, { context });
  }

  updateProject(projectId: number, project: Project) {
    const context = new HttpContext().set(save, true);
    return this.http.post(this.resourceService.getApiUrl() + '/project/' + projectId, project, { context });
  }

  saveProject(project: Project) {
    return this.http.put(this.resourceService.getApiUrl() + '/projects/', project);
  }

  deleteProject(projectId: number): Observable<any> {
    return this.http.delete(this.resourceService.getApiUrl() + '/project/' + projectId);
  }

  deleteProjects(projectIds: number[]): Observable<ActionResponse[]> {
    return this.http.post<ActionResponse[]>(this.resourceService.getApiUrl() + '/v2/projects/delete', projectIds);
  }

  getProjectPropertiesById(projectId: number): Observable<ProjectProperties> {
    return this.http.get<ProjectProperties>(this.resourceService.getApiUrl() + `/project/properties/${projectId}`);
  }

  updateProjectProperties(projectId: number, projectProperties: ProjectProperties) {
    return this.http.post(this.resourceService.getApiUrl() + `/project/properties/${projectId}`, projectProperties);
  }

  uploadImageLogo(projectId: number, file: File) {
    const formData: FormData = new FormData();
    formData.append('fileName', file, file.name);
    return this.http.post(this.resourceService.getApiUrl() + `/project/image/` + projectId, formData);
  }

  getImageLogo(projectId: number, scip_error: boolean = false): Observable<Blob> {
    const context = new HttpContext().set(SKIP_STATUS, scip_error);
    return this.http.get(this.resourceService.getApiUrl() + '/project/image/' + projectId, { responseType: 'blob', context });
  }
}
