import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "dateTime"
})

export class DataTimePipe implements PipeTransform {
    transform(dateTimeTimestamp: string, utc: boolean = false) {
        const date = new Date(dateTimeTimestamp);
        if (date.toString() != "Invalid Date") {
            date.getDate();
            if (utc) {
                const month = (date.getUTCMonth() + 1).toString().length === 1 ? `0${(date.getUTCMonth() + 1)}` : (date.getUTCMonth() + 1).toString();
                const day = date.getUTCDate().toString().length === 1 ? `0${(date.getUTCDate())}` : (date.getUTCDate()).toString();
                const hours = date.getUTCHours().toString().length === 1 ? `0${(date.getUTCHours())}` : (date.getUTCHours()).toString();
                const minutes = date.getUTCMinutes().toString().length === 1 ? `0${(date.getUTCMinutes())}` : (date.getUTCMinutes()).toString();
                const seconds = date.getUTCSeconds().toString().length === 1 ? `0${(date.getUTCSeconds())}` : (date.getUTCSeconds()).toString();
                return `${date.getUTCFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }
            const month = (date.getMonth() + 1).toString().length === 1 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1).toString();
            const day = date.getDate().toString().length === 1 ? `0${(date.getDate())}` : (date.getDate()).toString();
            const hours = date.getHours().toString().length === 1 ? `0${(date.getHours())}` : (date.getHours()).toString();
            const minutes = date.getMinutes().toString().length === 1 ? `0${(date.getMinutes())}` : (date.getMinutes()).toString();
            const seconds = date.getSeconds().toString().length === 1 ? `0${(date.getSeconds())}` : (date.getSeconds()).toString();
            return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
        return "";
    }
}
