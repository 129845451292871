import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { save } from "../components/helpers/disable-save.interceptor";
import { SKIP_STATUS } from "../components/helpers/status.interceptor";
import { HWType } from "../models/HWType";
import { Response } from '../models/types/common';
import { ResourceService } from "./resource.service";



@Injectable({
    providedIn: 'root'
 })
export class HardwareTypeService{

    apiUrl: string;

    constructor(private resourceService: ResourceService,private http:HttpClient) {
    }

    getHWTypesCount():Observable<number>{
      return this.http.get<number>(this.resourceService.getApiUrl()+"/hwtype/count")
   }

    // getHWTypes(page:number,count:number): Observable<HWType[]>{
    //    return this.httpService.get<HWType[]>(this.resourceService.getApiUrl()+`/hwtype?page=${page}&count=${count}`)
    // }


    getHWTypes(page:number,count:number,order?:string,direction?:string): Observable<HWType[]>{
      if(page==0) page=1;
      let requestString=`/hwtype?page=${page}&count=${count}`
      if(order && order !=''){
        requestString+=`&order=${order}`
      }
      if(direction && direction !=''){
        requestString+= `&direction=${direction}`
      }
      return this.http.get<HWType[]>(this.resourceService.getApiUrl()+requestString)
   }

   getHWTypesByProjectId(project_id:number,id_list:boolean=false){
    return this.http.get<Response<HWType>>(this.resourceService.getApiUrl()+`/hwtype?project_id=${project_id}&id_list=${id_list}`)
   }

    getHWType(hwTypeId:number): Observable<HWType>{
        return this.http.get<HWType>(this.resourceService.getApiUrl()+`/hwtype/${hwTypeId}`)
     }

    createHWType(HWType: HWType): Observable<any>  {
      const context = new HttpContext().set(save, true);
        return this.http.put(this.resourceService.getApiUrl()+'/hwtype',HWType, { context });
      }

    updateHWType(HWTypeId:number,HWType: HWType) {
      const context = new HttpContext().set(save, true);
        return this.http.post(this.resourceService.getApiUrl() + '/hwtype/'+ HWTypeId, HWType, { context });
      }

    deleteHWType(HWTypeId: number): Observable<any> {
        return this.http.delete(this.resourceService.getApiUrl() + '/hwtype/' + HWTypeId);
      }


    uploadImageToHWType(HWTypeId:number,file:File){

      const formData: FormData = new FormData();

      formData.append('fileName', file, file.name);


      return this.http.post(this.resourceService.getApiUrl()+ '/hwtype/image/'+ HWTypeId,formData)
    }
//headers:{'Content-Type': 'application/x-www-form-urlencoded'}
    getImageFromHWType( HWTypeId:number,scip_error:boolean=false):Observable<Blob>{
        const context = new HttpContext().set(SKIP_STATUS, scip_error);
        return this.http.get(this.resourceService.getApiUrl() + '/hwtype/image/'+ HWTypeId,{ responseType: 'blob',context })
    }


}

