
<div class="app-login-dropdown">
	<div [matMenuTriggerFor]="menu" class="icons-wrapper">
		<mat-icon class="icon">person</mat-icon>
		<div class="current-user">
			{{this.currentTokenInfo.user}}
		</div>
	</div>
	<div class="mat-menu-login">
		<mat-menu #menu="matMenu" class="menu">
			<div class="login-dropdown">
				<mat-card class="login-menu-card">
					<div style="min-width: 20rem;">
						<p><b>Edgility Edge Management and Orchestration</b></p>
						<br>
						<p><b>Name:</b> {{this.currentTokenInfo.username}} </p>
						<p><b>Project:</b> {{this.currentTokenInfo.project}} </p>
						<p><b>Role:</b> {{this.currentTokenInfo.role}} </p>
						<p *ngIf="currentTokenInfo.role_id>0"><b>Operator:</b> {{this.currentTokenInfo?.operator_name}} </p>
						<p *ngIf="currentTokenInfo.role_id>1"><b>Customer:</b> {{this.currentTokenInfo?.customer_name}} </p>
						<p *ngIf="customer!=''"><b>Customer:</b> {{this.customer}} </p>
						<p *ngIf="operator!=''"><b>Operator:</b> {{this.operator}} </p>
						<p><b>Privilege level:</b>
							{{currentTokenInfo.is_super_user==1 ? 'Super user' : 'Project user'}}
							<span *ngIf="currentTokenInfo.is_readonly"> (RO)</span>
						</p>
						<p><b>Version:</b> {{version}}</p>
						<mat-divider></mat-divider>
						<br>
						<button mat-button class="logout" (click)="logout()">Logout</button>
					</div>
				</mat-card>
			</div>
		</mat-menu>
	</div>
</div>
