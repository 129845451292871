import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { CheckBoxProps, PaginatorInfo } from 'src/app/models';
import { formatDateTime } from '../../../helpers/utilities';

export interface GenericModel {
  k8s_service_id?: boolean;
  hostname?: any;
  app_node_id?: any;
  id?: number;
  uid?: string;
  select?: string;
  name?: string;
}

@Component({
  selector: 'telco-custom-dropdown-table',
  templateUrl: './custom-dropdown-table.component.html',
  styleUrls: ['./custom-dropdown-table.component.scss']
})

export class CustomDropdownTableComponent implements OnChanges, OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() showPaginator = true;
  @Input() pageCount;
  @Input() initialColumns: string[] = [];
  @Input() tableData: any;
  @Output() newItemEvent = new EventEmitter<object[]>();
  @Output() sortChangeEvent = new EventEmitter<any>();
  @Output() allItemCheckedEvent = new EventEmitter<CheckBoxProps>();
  @Output() pageChangeEvent = new EventEmitter<PaginatorInfo>();
  currentPage: PaginatorInfo;

  _dataSource = new MatTableDataSource<GenericModel>();

  selection = new SelectionModel<GenericModel>(true, [], true, (c1, c2) => {

    if (c1.id) {
      return c1.id === c2.id;
    }

  });

  filterDirection: string;
  filterActive: string;
  isAllChecked: boolean;
  contextMenuPosition = { x: '0px', y: '0px' };

  private updateTable = true;

  get dataSource(): any {
    return this._dataSource;
  }

  @Input()
  set dataSource(value: any) {
    if (this.updateTable) {
      this._dataSource = value;
    }
  }

  clearTableSelection() {
    this.updateTable = true;
    this.selection.clear();
  }

  ngOnInit(): void {
    this.selection.changed.subscribe(() => {
      const allSelected = this.selection.selected;
      this.newItemEvent.emit(allSelected);
      this.isAllChecked = this.isAllSelected() && this._dataSource.data && this._dataSource.data.length != 0;
      this.allItemCheckedEvent.emit({ isAllChecked: this.isAllChecked });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource != undefined && changes.dataSource.currentValue != undefined) {
      this.fillTableData();
    }
  }

  fillTableData(): void {
    if (this._dataSource == undefined) {
      this._dataSource = new MatTableDataSource<GenericModel>();
    }

    this._dataSource.data = this.dataSource;
    // if (this.dataSource.paginator == undefined && this.paginator != undefined) {

    //   this.dataSource.paginator = this.paginator;
    // }

    // if (this.dataSource.sort == undefined && this.sort != undefined) {
    //   this.dataSource.sort = this.sort;
    //   this.sort.disableClear = true;
    // }
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this._dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: GenericModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} `;
  }

  sortChange(event: Sort): void {
    if (this.selection && this.selection.hasValue() && this.selection.selected.length > 0) {
      this.selection.clear();
    }
    const page = this.currentPage != undefined ? this.currentPage.pageIndex : 1;
    const size = this.currentPage != undefined ? this.currentPage.pageSize : 15;
    const order = event.active;
    const direction = event.direction;

    this.sortChangeEvent.emit([page, size, order, direction]);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.initialColumns, event.previousIndex, event.currentIndex);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this._dataSource.data.length;
    return numSelected === numRows;
  }

  selectAllHandle(event: any): void {
    const isChecked = event['checked'];
    const isAllSelected = this.isAllSelected();
    if (!isAllSelected && isChecked) {
      this.selection.clear();
      this._dataSource.data.forEach(row => this.selection.select(row));
    }
    if (this.selection.hasValue() && !isChecked) {
      this.selection.clear();
    }

  }

  openMyMenu(event: MouseEvent, row: any): void {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';

    this.trigger.menuData = { rowData: row };
    this.trigger.openMenu();
  }

  closeMyMenu(): void {
    this.trigger.closeMenu();
  }

  formatColumn(column: string) {
    let displayed = column;
    switch (column) {
      case 'create_time':
        displayed = 'Create Time';
        break;
    }
    return displayed;
  }

  formatRow(row: string, column: string): string {
    let displayed = row;
    switch (column) {

      case 'update_time':
        displayed = formatDateTime(row);
        break;
      case 'create_time':
        displayed = formatDateTime(row);
        break;
    }

    return displayed;
  }

  toggle(item: { id: number; }): void {
    const newItemWithSameKey = { ...item };
    if (this.selection.compareWith && this.selection.isSelected(newItemWithSameKey)) {
      const matchingRow = this.selection.selected.find((selectedRow) => this.selection.compareWith!(selectedRow, newItemWithSameKey));
      if (matchingRow) {
        this.selection.toggle(matchingRow);
      }
    } else {
      this.selection.toggle(newItemWithSameKey);
    }
  }

  rowClicked(row) {
    if (this.selection.isEmpty()) {
      this.updateTable = false;
    }
    if (this.selection.selected.length == 1 && row.id == (this.selection.selected[0] as any).id) {
      this.updateTable = true;
    }

    this.toggle(row);
  }

  handlePageEvent(event: PaginatorInfo): void {
    if (this.selection.hasValue()) {
      this.selection.clear();
      this.allItemCheckedEvent.emit({ isAllChecked: false });
    }
    setTimeout(() => {
      this.currentPage = { ...event };
      this.pageChangeEvent.emit(this.currentPage);
    }, 0);
  }
}
