import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SseService } from "services/sse.service";
import { ThemeService } from "services/theme.service";
import { ResourceService } from "src/app/services/resource.service";

import { SidenavMobileComponent } from "../sidenav-mobile/sidenav-mobile.component";
import { MobileLoginDropdownComponent } from "./login-dropdown/login-dropdown-mobile.component";

@Component({
    selector: "telco-navbar-mobile",
    templateUrl: "./navbar-mobile.component.html",
    styleUrls: ["./navbar-mobile.component.scss"]
})
export class MobileNavbarComponent implements OnInit {
    @ViewChild("sidenavMobile") sidenavMobile: SidenavMobileComponent;
    @ViewChild("MobileLoginDropdown") MobileLoginDropdown: MobileLoginDropdownComponent;

    isOverlayVisible: boolean = false;
    alertCount: number = 0;

    logPath: string;
    trademark = {
        telcoTheme: "assets/logos/telcoTheme.png",
        telco: "assets/svg/logo.svg",
        albisElconTheme: "assets/theme/albisElcon/albisElcon.png",
    };

    constructor(public resourceService: ResourceService, private themeService: ThemeService, private sseService: SseService, private router: Router) { }

    ngOnInit(): void {
        this.logPath = this.trademark[this.themeService.theme] || this.trademark.telco;
        this.sseService.initEventSourceSingleton();
    }

    handleAlertCount(count: number) {
        this.alertCount = count;
    }

    redirectToAlertsMobile() {
        this.router.navigate(["/mobile/Notifications/Alerts"]);
    }

    redirectToDashboard() {
        this.router.navigate(["/mobile/DashboardMobile"]);
    }

    toggleMainSidenav() {
        if (this.MobileLoginDropdown.drawer.opened) {
            this.MobileLoginDropdown.toggleSidenav();
        }
        this.sidenavMobile.toggleSidenav();
    }

    toggleLoginSidenav() {
        if (this.sidenavMobile.drawer.opened) {
            this.sidenavMobile.toggleSidenav();
        }
        this.MobileLoginDropdown.toggleSidenav();
    }

    updateOverlayState(state: boolean) {
        this.isOverlayVisible = state;
    }

    overlayClick() {
        this.MobileLoginDropdown.drawer.opened ? this.MobileLoginDropdown.toggleSidenav() : this.sidenavMobile.toggleSidenav();
    }
}
