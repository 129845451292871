import { Injectable } from "@angular/core";
import {
    CanActivate, Router
} from "@angular/router";
import { DeviceDetectorService } from "services/device-detector.service";

@Injectable({
    providedIn: "root"
})
export class DeviceGuard implements CanActivate {
    constructor(private deviceService: DeviceDetectorService, private router: Router) {
    }

    canActivate(): any {
        if (this.deviceService.isMobile()) {
            this.router.navigate(["mobile/DashboardMobile"]);
            return false;
        }
        return true;
    }
}
