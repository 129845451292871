<mat-dialog-content class='content'>
    <mat-card class="mat-card-content">
        <div class="container_header">
            <mat-icon [ngStyle]="{'color':'#ede06b', 'height':'28px'}">warning</mat-icon>

            <mat-card-title>
                {{alert_type}}
            </mat-card-title>
        </div>
         <div class='body-text'>
            <div style="padding-left: 5px">{{message}}</div>

            <mat-dialog-actions align="end">
                <button *ngIf="cancelButtonText != ''" mat-raised-button class="alert-button" [mat-dialog-close]="false">{{cancelButtonText}}</button>
                <button *ngIf="confirmButtonText != ''"  mat-raised-button class="alert-button" [mat-dialog-close]="true" tabindex="-1" >{{confirmButtonText}}</button>
            </mat-dialog-actions>
        </div>

    </mat-card>

</mat-dialog-content>

