import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DisableSaveService } from 'services/disable-save.service';

export const save = new HttpContextToken((): boolean | string => false);

@Injectable()
export class DisableSaveInterceptor implements HttpInterceptor {

  constructor(private disableSaveService: DisableSaveService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(save)) {
      if (request.context.get(save) == 'unique') {
        this.disableSaveService.disableButtonsUnique = true;
      } else {
        this.disableSaveService.disableButtons = true;
      }
      
    }
    return next.handle(request).pipe(finalize(() => {
      this.disableSaveService.disableButtons = false;
      this.disableSaveService.disableButtonsUnique = false;
    }));
  }
}
