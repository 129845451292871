import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AppConfigService } from 'services/app-config.service';
import { ProjectService } from 'services/project.service';
import { ProjectProperties } from '../models/interfaces/ProjectProperties';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  theme: string;
  title: string;
  changeThemeSettings$ = new Subject<any>();
  themeChanged = new Subject<void>();
  primary = new Subject<string>();
  fontFamily = new Subject<string>();
  backGround = new Subject<string>();
  selectedInTable = new BehaviorSubject<string>(null);
  navigationItemColor = new Subject<string>();
  navigationHeaderWeight = new Subject<string>();
  image = new Subject<any>();
  private projectTheme = {
    primary: '',
    fontFamily: '',
    backGround: '',
    selectedInTable: '',
    navigationHeaderWeight: '',
    navigationItemColor: '',
    image: ''
  };

  constructor(private appConfigService: AppConfigService, private overlayContainer: OverlayContainer, private projectService: ProjectService, private cookieService: CookieService, private activatedRoute: ActivatedRoute) {
    this.theme = this.appConfigService.config.theme;
    this.title = this.appConfigService.themeConfig?.title;
    document.body.classList.add(this.theme);
    this.overlayContainer.getContainerElement().classList.add(this.theme);
    this.fontFamily.subscribe(fontFamily => document.documentElement.style.setProperty('--font-family', fontFamily));
    this.navigationItemColor.subscribe(color => document.documentElement.style.setProperty('--navigationItemColor', color));
    this.navigationHeaderWeight.subscribe(weight => document.documentElement.style.setProperty('--navigationHeaderWeight', weight));

    this.backGround.subscribe(color => document.documentElement.style.setProperty('--backGround', color));
    this.selectedInTable.subscribe(color => document.documentElement.style.setProperty('--active', color));
    this.primary.subscribe(color => document.documentElement.style.setProperty('--primary', color));

    this.changeThemeSettings$.pipe(filter(() => this.cookieService.get('project_id') == this.activatedRoute.snapshot.params.id), take(1)).subscribe(value => this.setThemeColor(value));
    if (this.cookieService.get('currentToken')) {
      this.setPerProjectThemeOnLogin(this.cookieService.get('project_id'));
    }
  }


  setPerProjectThemeOnLogin(projectId: any) {
    const imageLogo$ = this.projectService.getImageLogo(projectId).pipe(
      tap((blob: Blob) => {
        const reader = new FileReader();
        reader.onload = (event: any) => this.image.next(event.target.result);
        reader.readAsDataURL(blob);
      })
    );

    this.projectService.getProjectPropertiesById(projectId).pipe(
      filter(value => !!value.theme),
      tap((value: ProjectProperties) => {
        this.themePerProject(this.cookieService.get('project'))
        this.setThemeColor(value.theme);
      }),
      filter(value => !!(value.theme.image != '1' && value?.theme?.image)),
      switchMap(() => imageLogo$)
    ).subscribe(() => this.themeChanged.next());


  }

  themePerProject(role: string) {
    document.body.classList.remove(this.theme);
    this.theme = role;
    document.body.classList.add(this.theme);
    this.overlayContainer.getContainerElement().classList.add(this.theme);
  }

  setThemeColor(themePerProject) {
    const whichOne = Object.keys(this.projectTheme).filter(key => this.projectTheme[key] != themePerProject[key]);
    this.projectTheme = themePerProject;
    whichOne.forEach(key => this[key].next(themePerProject[key]));
    this.projectTheme = themePerProject;
  }

  resetThemeVariables() {
    [ '--font-family', '--navigationItemColor', '--navigationHeaderWeight', '--backGround', '--primary' ]
      .forEach(variable => document.documentElement.style.removeProperty(variable));
    this.selectedInTable.next('#c6e4ff')

    this.projectTheme = {
      primary: '',
      fontFamily: '',
      backGround: '',
      selectedInTable: '',
      navigationHeaderWeight: '',
      navigationItemColor: '',
      image: ''
    };
  }
}
