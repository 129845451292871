import { BasicEdgeInterface, WanInterface } from '..';
import { SmartProtocol, TraceHostCfg } from './interfaceModel';

export class Wifi implements BasicEdgeInterface {
    name: string; // WLAN interface
    bootproto: 'dhcp' | 'dhcp6' | 'dhcp46' | 'none';
    enable: boolean; // WIFI Enabled
    metric: number;
    'radio-settings': RadioSettings;
    security: SecuritySettings;
    ssid: string;
    // 'tracehost': string;
    type: string;
    'wifi-mode': 'client' | 'AP';
    'ip-passthrough': boolean;
    'ipv4-prefix': string;
    'ipv6-prefix': string;
    nat: boolean; // Enable NAT
    'wifi-wan-interface': WanInterface[]; // WAN List // NEED TO ADD!
    management: boolean;
    gateway: string;
    gateway6: string;
    dns: string;
    dns6: string;
    tracehost:TraceHostCfg;
   'smart-protocol-passthrough': SmartProtocol[];

    constructor() {
        this.name = 'wifi0';
        this.bootproto = 'dhcp';
        this.enable = false;
        this.metric = 120;
        this['radio-settings'] = new RadioSettings();
        this.security = new SecuritySettings();
        this.ssid = '';
        // this['trace-host'] = '';
        this.type = 'wifi';
        this['wifi-mode'] = 'client';
        this['ip-passthrough'] = false;
        this['ipv4-prefix'] = '';
        this['ipv6-prefix'] = '';
        this.nat = false;
        this['wifi-wan-interface'] = [];
        this.management = false;
        this.tracehost=new TraceHostCfg();
        this['smart-protocol-passthrough'] = [new SmartProtocol()];
    }

}

export class RadioSettings {
    band: string; // WLAN band
    channel: number; // WLAN Channel [1-13]
    'transmit-power': number; // Transit Power / Power Tx [1-255 dBm]
    country: string; // Country Code

    constructor() {
        this.band = '2.4GHz';
        this['transmit-power'] = 100;
        this.country = 'US';
        this.channel = 4;
    }
}

export class SecuritySettings {
    hidden: boolean; // SSID Broadcast hidden
    'key-mgmt': string; // Security Type
    passphrase: string; // passcode
    isolation: boolean; // ? same as hidden ? // NEED TO ADD!
    'max-clients': number; // Max Clients [1 - 2007]
    'mac-acl': 'none' | 'allow' | 'deny'; // MAC Access Control;
    'mac-allow': MacObject[]; // Allowed MACs // NEED TO ADD!
    'mac-deny': MacObject[]; // Denied MACs // NEED TO ADD!

    constructor() {
        this.hidden = false;
        this['key-mgmt'] = 'AUTO';
        this.passphrase = '';
        this.isolation = false;
        this['max-clients'] = 255;
        this['mac-acl'] = 'none';
        this['mac-allow'] = [];
        this['mac-deny'] = [];
    }
}

export class MacObject {
    mac: string;

    constructor(mac) {
        this.mac = mac;
    }
}

