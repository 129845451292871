import {
    AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import * as echarts from "echarts";
import { ECharts } from "echarts";
import { SessionService } from "services/session.service";

import { AlarmsChartData, colors } from "../../../../models";

@Component({
    selector: "telco-mobile-active-operations-chart",
    templateUrl: "./mobile-active-operations-chart.component.html",
    styleUrls: ["./mobile-active-operations-chart.component.scss"]
})
export class MobileActiveOperationsChartComponent implements AfterViewInit, OnChanges {
    @ViewChild("activeOpsChartSelector", { read: ElementRef, static: false }) activeOpsChartSelector: ElementRef;
    @Input() alarmCharData: AlarmsChartData;

    alarmChart: any;
    colors: typeof colors = colors;
    myChart: ECharts;
    alertsCount: number;
    isExpanded: boolean = false;
    convertedNameValueArray: object;

    constructor(private router: Router, private sessionService: SessionService) {}

    ngOnChanges(): void {
        if (this.activeOpsChartSelector) {
            this.initChart();
        }
    }

    ngAfterViewInit(): void {
        this.myChart = echarts.init(this.activeOpsChartSelector.nativeElement);
        this.setupEventHandlers();
        this.resizeChart();
    }

    setupEventHandlers(): void {
        this.myChart.on("click", this.onChartClick);
    }

    onChartClick() {
        this.toggleExpandState();
    }

    toggleExpandState(): void {
        this.isExpanded = !this.isExpanded;
        this.initChart();
    }

    onButtonClick(itemName: string): void {
        this.sessionService.setItemName(itemName);

        this.router.navigate(["/mobile/Notifications/UserOperations"]);
    }

    convertNameValueArray(chartData:AlarmsChartData) {
        const statusColorMap = {
            Pending: { name: "Pending", color: colors.YELLOW },
            "In Progress": { name: "In progress", color: colors.GREEN },
        };

        const newArrayData = Object.keys(statusColorMap)
            .filter((status) => chartData[status])
            .map((status) => ({
                name: statusColorMap[status].name,
                value: chartData[status],
                itemStyle: { color: statusColorMap[status].color }
            }));

        this.resizeChart();
        this.convertedNameValueArray = newArrayData;
        return newArrayData;
    }

    calculateInit(edgeData: any): number {
        const keysOfInterest = [
            "Pending", "In Progress"
        ];

        return keysOfInterest.reduce((acc, key) => acc + (edgeData[key] || 0), 0);
    }

    recenter(value: number) {
        const thresholds = [
            { max: 10, percent: "17.5%" },
            { max: 100, percent: "15.5%" },
            { max: 1000, percent: "14.5%" },
            { max: 10000, percent: "13.5%" },
            { max: 100000, percent: "12.5%" },
            { max: 1000000, percent: "11.5%" }
        ];

        for (const threshold of thresholds) {
            if (value < threshold.max) {
                return threshold.percent;
            }
        }
        return "17.5%"; // Default value return
    }

    initChart(): void {
        let newChartData: AlarmsChartData = new AlarmsChartData();
        newChartData = this.alarmCharData;
        const initCount = this.calculateInit(newChartData);

        this.alarmChart = {
            title: [{
                text: initCount,
                top: "center",
                left: this.recenter(initCount),
                textStyle: {
                    fontSize: 12,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            {
                text: "Active Operations",
                top: "center",
                left: 120,
                textStyle: {
                    fontSize: 14,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                },
            }],
            tooltip: {
                trigger: "item",
                textStyle: {
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            legend: {
                show: false,
            },
            series: [{
                type: "pie",
                radius: ["35%", "70%"],
                center: ["20%", "50%"],
                label: {
                    show: true,
                    position: "inner",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                    formatter: (params) => params.value
                },
                labelLine: {
                    normal: {
                        show: false,
                    }
                },
                hoverAnimation: false,
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: "#fff",
                },
                silent: true,

                data: this.convertNameValueArray(this.alarmCharData)
            }]
        };
        this.myChart.setOption(this.alarmChart);
    }

    resizeChart(): void {
        if (this.myChart) {
            this.myChart.resize({ width: 300, height: 150 });
        }
    }
}
