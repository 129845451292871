export class Project {
    id: number;
    name: string;
    description:string;
 

    constructor() {
        this.id = 0;
        this.name = '';
        this.description='';

    }
}