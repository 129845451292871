
import { BasicEdgeInterface } from '..';
export class Nat implements BasicEdgeInterface {
    constructor(i) {
        this.enable = false;
        this.name = 'nat' + i;
        this["wan-interface"] = [];
        this.type = 'nat';
        this["ipv4-prefix"] = "";
        this["ipv6-prefix"] = "";
        this["port-forwarding-all"] = true;
    }
    enable: boolean;
    'port-forwarding-all': boolean;
    name: string;
    'wan-interface': WanInterface[];
    'ipv4-prefix': string;

    'ipv6-prefix': string;
    type: string;
}

export class WanInterface {
    constructor(name) {
        this.name = name;
    }
    name: string;
}