import { MonoTypeOperatorFunction, pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let cancelSubject = new Subject();
export function cancelPreviousReq<T>(): MonoTypeOperatorFunction<T> {
  cancelSubject.next();
  cancelSubject.complete();
  cancelSubject = new Subject();
  return pipe(takeUntil(cancelSubject));
}
