import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'telco-description-case',
  templateUrl: './description-case.component.html',
  styleUrls: [ './description-case.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionCaseComponent {
  @Input() row;
  @Input() column;
  @Input() condition = true;

  openMyMenu(trigger, row, event): void {
    event.stopPropagation();
    trigger.menuData = { rowData: row };
    trigger.openMenu();
  }
}
