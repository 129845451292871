
export class Service {
    id: number;
    name: string;
    topology: string;
    customer:string;
    customer_id: number;
    project:string;
    project_id:number;
    service_type:string;
    operator:string;
    operator_id: number;


    constructor(id?, name?, topology?,customer?, customerId?,project?,project_id?,service_type?,operator?, operator_id?) {
        this.id = id;
        this.name = name;
        this.topology= topology;
        this.customer=customer;
        this.customer_id = customerId;
        this.project=project;
        this.project_id=project_id;
        this.service_type=service_type;
        this.operator=operator;
        this.operator_id = operator_id;
    }
}

