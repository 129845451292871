<div style="height: 100%;">
  <div class="customize-bar-box" >
    <app-customize-bar (eventChartView)="onChangeChartView($event)" ></app-customize-bar>
  </div>

  <div class="charts-wrapper">
    <div *ngIf="this.ucpeStatusChartShow" class="chart" id='ucpe-chart' >
      <app-ucpe-charts [ucpeCharData]="ucpeChartData$" [typeChart]="'ucpe'" ></app-ucpe-charts>
    </div>

    <div *ngIf="this.controllerStatusChartShow" class="chart" id='controller-chart' >
      <app-ucpe-charts [ucpeCharData]="controllerChartData$" [typeChart]="'controller'"></app-ucpe-charts>
    </div>

    <div *ngIf="alarmsChartShow" class="chart"  id='alarm-chart' >
      <app-alarms-chart [alarmCharData]="alarmCharData$" ></app-alarms-chart>
    </div>

    <div *ngIf="controllerEventChartShow" class="chart" id='contr-chart' >
      <telco-controller-event-chart [activeEventCharData]="activeEventCharData$" [title]="'Controller Events'"></telco-controller-event-chart>
    </div>

    <div *ngIf="alertsChartShow" class="chart" id='alerts-chart' >
      <telco-alerts-chart [alertsCharData]="alertsCharData$"></telco-alerts-chart>
    </div>

    <div *ngIf="activeOperationsChartShow" class="chart" id='active-oper-chart' >
      <telco-active-operations-chart [alarmCharData]="activeOperationsCharData$" ></telco-active-operations-chart>
    </div>

    <div *ngIf="adminStatusChartShow" class="chart" id='adminStatuschart' >
      <app-admin-status-chart [adminStatusData]="adminStatusChartData$"></app-admin-status-chart>
    </div>

    <div *ngIf="vnfTypesChartShow" class="chart" id='vnfTypeschart' >
      <telco-deployed-serv-admin-chart [servAdminTypesData]="vnfTypesChartData$" [typeChart]="'donut'" [serviceType]="'application'"></telco-deployed-serv-admin-chart>
    </div>

    <div *ngIf="discoveryChartShow" class="chart"  id='discovery-chart' >
      <app-discovery-chart [customChartData]="discoveryChartData$"  [typeChart]="'ucpe'"></app-discovery-chart>
    </div>

    <div *ngIf="controllerDiscoveryChartShow" class="chart"  id='discovery-chart' >
      <app-discovery-chart [customChartData]="controllerDiscoveryChartData$" [typeChart]="'controller'"></app-discovery-chart>
    </div>

    <div *ngIf="servicesChartShow" class="chart" id='servicesChart' >
      <telco-deployed-services-chart [vnfTypesData]="servicesTypesChartData$"></telco-deployed-services-chart>
    </div>

    <div *ngIf="servicesChartAdminShow" class="chart" id='servicesChartAdmin' >
      <telco-deployed-serv-admin-chart [servAdminTypesData]="servicesAdminTypesChartData$" [typeChart]="'donut'" [serviceType]="'kubernetes'"></telco-deployed-serv-admin-chart>
    </div>

    <div *ngIf="kubernetesServicesChartShow" class="chart" id='k8s-chart' >
      <telco-controller-event-chart [activeEventCharData]="K8sServiceEventCharData" [title]="'Kubernetes Service Events'" ></telco-controller-event-chart>
    </div>
    
    <div *ngIf="osServicesChartShow" class="chart" id='os-chart' >
      <telco-controller-event-chart [activeEventCharData]="osServiceEventCharData" [title]="'Openstack Service Events'" ></telco-controller-event-chart>
    </div>

  </div>
</div>
