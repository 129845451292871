import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  alarm: string;
  private STORAGE_KEY = 'selectedItemName';

  constructor() {
    const itemName = sessionStorage.getItem(this.STORAGE_KEY);
    if (!this.alarm && itemName) {
      this.alarm = itemName;
    }
  }

  setItemName(itemName: string): void {
    this.alarm = itemName;
    sessionStorage.setItem(this.STORAGE_KEY, itemName);
  }

  removeItemName(): void {
    this.alarm = '';
    sessionStorage.removeItem(this.STORAGE_KEY);
  }
}
