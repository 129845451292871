import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interfaceName'
})
export class InterfaceNamePipe implements PipeTransform {

  transform(name: string): unknown {
    const rExp: RegExp = /\D+/;
    const interfaces = name.match(rExp);

    switch (interfaces[0]) {
      case 'data':
        name = name.replace('data', 'Data ');
        break;
      case 'lte':
        name = name.replace('lte', 'LTE ');
        break;
      case 'wifi':
        name = name.replace('wifi', 'Wi-Fi ');
        break;
      case 'tun':
        name = name.replace('tun', 'Tun ');
        break;
      case 'mgmt':
        name = name.replace('mgmt', 'MGMT ');
        break;
      case 'nat':
        name = name.replace('nat', 'NAT ');
        break;
      case 'vti':
        name = name.replace('vti', 'VTI ');
        break;
      case 'lo':
        name = name.replace('lo', 'Lo ');
        break;
      case 'pppoe':
        name = name.replace('pppoe', 'PPPoE ');
        break;
      case 'vxlan':
        name = name.replace('vxlan', 'VxLAN ');
        break;
      case 'mirror':
        name = name.replace('mirror', 'Mirroring ');
        break;
    }

    return name;
  }

}
