import {
    AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import * as echarts from "echarts";
import { ECharts } from "echarts";
import { SessionService } from "services/session.service";

import { AlarmsChartData, colors } from "../../../../models";

@Component({
    selector: "telco-mobile-alarms-chart",
    templateUrl: "./mobile-alarms-chart.component.html",
    styleUrls: ["./mobile-alarms-chart.component.scss"]
})
export class MobileAlarmsChartComponent implements AfterViewInit, OnChanges {
    @ViewChild("alarmChartSelector", { read: ElementRef, static: false }) edgeChartSelector:ElementRef;
    @Input() alarmCharData: AlarmsChartData;

    colors: typeof colors = colors;
    isExpanded: boolean = false;
    alarmChart: any;
    myChart: ECharts;
    convertedNameValueArray: object;

    constructor(private router: Router, private sessionService: SessionService, private ngZone: NgZone) {}

    ngOnChanges(): void {
        if (this.alarmCharData) {
            this.ngZone.runOutsideAngular(() => {
                this.initChart();
            });
        }
    }

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.myChart = echarts.init(this.edgeChartSelector.nativeElement);
            this.setupEventHandlers();
            this.resizeChart();
        });
    }

    setupEventHandlers(): void {
        this.myChart.on("click", this.toggleExpandState.bind(this));
    }

    calculateInit(edgeData: any): number {
        const keysOfInterest = [
            "Warning", "Minor", "Major", "Critical", "Normal",
            "Info"
        ];

        return keysOfInterest.reduce((acc, key) => acc + (edgeData[key] || 0), 0);
    }

    recenter(value: number) {
        const thresholds = [
            { max: 10, percent: "17.5%" },
            { max: 100, percent: "16%" },
            { max: 1000, percent: "14.5%" },
            { max: 10000, percent: "13.5%" },
            { max: 100000, percent: "12.5%" },
            { max: 1000000, percent: "11.5%" }
        ];

        const foundThreshold = thresholds.find((element) => value < element.max);
        return foundThreshold ? foundThreshold.percent : "17.5%";
    }

    convertNameValueArray(chartData:AlarmsChartData) {
        const statusColorMap = {
            Warning: { name: "Warning", color: colors.YELLOW },
            Minor: { name: "Minor", color: colors.PINK },
            Major: { name: "Major", color: colors.ORANGE },
            Critical: { name: "Critical", color: colors.RED },
            Info: { name: "Info", color: colors.BLUE },
            Normal: { name: "Normal", color: colors.GREEN },
        };

        const newArrayData = Object.keys(statusColorMap)
            .filter((status) => chartData[status])
            .map((status) => ({
                name: statusColorMap[status].name,
                value: chartData[status],
                itemStyle: { color: statusColorMap[status].color }
            }));

        this.resizeChart();
        this.convertedNameValueArray = newArrayData;
        return newArrayData;
    }

    onChartClick() {
        this.toggleExpandState();
    }

    toggleExpandState(): void {
        this.isExpanded = !this.isExpanded;
        this.ngZone.runOutsideAngular(() => {
            this.initChart();
        });
    }

    onButtonClick(itemName: string): void {
        this.sessionService.setItemName(itemName);
        this.router.navigate(["/mobile/Notifications/EdgeEvents"]);
    }

    initChart(): void {
        let newChartData:AlarmsChartData = new AlarmsChartData();
        newChartData = this.alarmCharData;
        const initCount = this.calculateInit(newChartData);

        this.alarmChart = {
            title: [{
                text: initCount,
                top: "center",
                left: this.recenter(initCount),
                textStyle: {
                    fontSize: 12,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            {
                text: "Edge Events",
                top: "center",
                left: 120,
                textStyle: {
                    fontSize: 14,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                },
            }],
            tooltip: {
                trigger: "item",
                textStyle: {
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            legend: {
                show: false,
            },
            series: [{
                type: "pie",
                radius: ["35%", "70%"],
                center: ["20%", "50%"],
                label: {
                    show: true,
                    position: "inner",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                    formatter: (params) => params.value
                },
                labelLine: {
                    normal: {
                        show: false,
                    }
                },
                hoverAnimation: false,
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: "#fff",
                },
                silent: true,

                data: this.convertNameValueArray(this.alarmCharData)
            }]
        };
        this.myChart.setOption(this.alarmChart);
    }

    resizeChart() {
        if (this.myChart) {
            this.myChart.resize({ width: 300, height: 150 });
        }
    }
}
