import { DataSet } from 'vis-data';
import { EdgeModel } from './EdgeTypes';
import { HWResources } from './HWResources';
import { HWType } from './HWType';
import { ProjectEntries } from './interfaces/ProjectEntries';
import { VisElement } from './interfaces/visElement';

export class ServiceDesign implements ProjectEntries{
    id:number=0;
    serviceType:string;
    serviceName:string;
    serviceDescription:string;
    design:string='';
    // edgeType:string;
    edgeModel: EdgeModel;
    cloudInitInputText:string;
    hwType:HWType;
    editMode:boolean=false;
    hw_resources:HWResources=new HWResources();
    Nodes:DataSet<any>;
    Edges:DataSet<any>;
    logicalPorts:VisElement[];
    physicalPorts:VisElement[];
    layer2Ports:VisElement[];
    resetTopology:boolean=false;

    constructor(serviceName?:string,serviceDescription?:string){
    this.serviceName=serviceName || '';
    this.serviceDescription=serviceDescription || '';
    }
    params: any;
    customer_id: number;
    service_id: number;
    project_id: number;
    site_id: number;
    operator_id: number;
    customer: string;
    service: string;
    project: string;
    site: string;
    operator: string;
}