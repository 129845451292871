<mat-card appearance="outlined" class="revenue-chart">
    <mat-card-content class="revenue-chart__content">
      <div class="canvas" (click)="toggleExpandState()">
        <div class="revenue-chart__content-chart" (chartClick)="onChartClick()" #alertsChartSelector></div>
        <mat-icon class="icons">{{isExpanded ? 'keyboard_arrow_up_icon' : 'keyboard_arrow_down'}}</mat-icon>
      </div>
      <div class="expanded-selection" *ngIf="isExpanded">
        <button mat-raised-button *ngFor="let item of this.convertedNameValueArray" (click)="onButtonClick()">
          <div class="button-content">
            <div [style.background-color]="item.itemStyle.color" class="alarm-color"></div>
            <div>{{item.name}}</div>
            <div id="value">{{item.value}}</div>
          </div>
        </button>
      </div>
    </mat-card-content>
</mat-card>