import { SelectionModel } from '@angular/cdk/collections';
import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericModel } from './clear-selection.directive';

@Directive({
  selector: '[telcoShiftSelection]'
})
export class ShiftSelectionDirective implements OnInit, OnDestroy {
    private firstSelectedIndex: number | null = null;
    private shiftHolding = false;
    private dataSourceSubscription: Subscription;
    private _dataSource: any[];

    @Input() selection: SelectionModel<GenericModel>;

    @Input('telcoShiftSelection')
    set dataSource(value: any[]) {
        this._dataSource = value;
        this.setupSelectionChange();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Shift' && this._dataSource) {
            this.shiftHolding = true;
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Shift' && this._dataSource) {
            this.shiftHolding = false;
        }
    }

    ngOnInit(): void {
        this.setupSelectionChange();
    }

    ngOnDestroy() {
        this.dataSourceSubscription?.unsubscribe();
    }

    private setupSelectionChange() {
        if (this._dataSource && this.selection) {
            this.dataSourceSubscription = this.selection.changed.pipe(
                tap(change => {
                    if (this.shiftHolding && change.added.length > 0) {
                        const addedIndex = this._dataSource.findIndex(item => item.id === change.added[0].id);
                        if (this.firstSelectedIndex !== null) {
                            this.selectRange(this.firstSelectedIndex, addedIndex);
                        }
                        this.firstSelectedIndex = this.firstSelectedIndex === null ? addedIndex : this.firstSelectedIndex;
                    } else {
                        this.firstSelectedIndex = null;
                    }
                })
            ).subscribe();
        }
    }

    private selectRange(startIndex: number, endIndex: number) {
        const start = Math.min(startIndex, endIndex);
        const end = Math.max(startIndex, endIndex);
        for (let i = start; i <= end; i++) {
            const item = this._dataSource[i];
            if (item && !this.selection.isSelected(item)) {
                this.selection.select(item);
            }
        }
    }
}
