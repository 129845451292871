import { Injectable } from '@angular/core';
import { DeviceDetectorService as NgxDeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {

  constructor(private deviceService: NgxDeviceDetectorService) { }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }
}