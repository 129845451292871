
    <mat-dialog-content [ngClass]="alert_type === 'Waiting' ?  'transparent' : 'content'">
        <mat-card *ngIf="alert_type != 'Waiting'" class="mat-card-content">
            <div class="container_header">
                <mat-icon *ngIf="alert_type == 'Error'" [ngStyle]="{'color':'red', 'height':'28px'}">error</mat-icon>
                <mat-icon *ngIf="alert_type == 'Warning'" [ngStyle]="{'color':'#ede06b', 'height':'28px'}">warning</mat-icon>
                <mat-icon *ngIf="alert_type == 'Confirmation'" [ngStyle]="{'color':'#ede06b', 'height':'28px'}">warning</mat-icon>
                <mat-icon *ngIf="alert_type == 'Info'" [ngStyle]="{'color':'blue', 'height':'28px'}">info</mat-icon>

                <mat-card-title>
                    {{alert_type}}
                </mat-card-title>
            </div>
             <div class='body-text'>
                <div style="padding-left: 5px">{{message}}</div>

                <div style="padding: 3%; padding-right: 0">
                    <mat-checkbox *ngIf="conditionText != ''" class="restoreDefault" [formControl]="restoreDefaultFormControl" > {{conditionText}} </mat-checkbox>
                </div>

                <mat-dialog-actions align="end" style="padding-right: 0">
                    <button *ngIf="cancelButtonText != ''" mat-raised-button class="alert-button" [mat-dialog-close]="'cancel'" tabindex="-1" [mat-dialog-close]='cancel'>{{cancelButtonText}}</button>
                    <button *ngIf="confirmButtonText != ''"  mat-raised-button class="alert-button" [mat-dialog-close]="{result: 'confirm', condition: this.restoreDefaultFormControl.getRawValue()}" tabindex="-1" >{{confirmButtonText}}</button>
                </mat-dialog-actions>

            </div>

        </mat-card>

    </mat-dialog-content>

