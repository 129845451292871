import { BasicEdgeInterface } from "..";

export class Mirroring implements BasicEdgeInterface {
  name: string;
  'src-port': string;
  'dst-port': string;
  direction: 'rx' | 'tx' | 'rxtx';
  enable: boolean;
  mirroringID: string;

  type: string;

  constructor() {
    this.name = 'mirroring';
    this.direction = 'rxtx';
    this['src-port'] = undefined; // e.g. 'wifi0'
    this['dst-port'] = undefined; // e.g. 'data5'
    this.enable = false;
    this.type='mirroring'
  }

}
