
<mat-drawer-container class="example-container customize-container">

    <section class="customize-section">
      <div class="customize-header">Customize dashboard
        <mat-checkbox class="customize-margin" [(ngModel)]="ucpeStatusChart" (change)="onChangeCheckbox($event,'ucpeStatusChart')">Edge Oper Statuses</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="controllerStatusChart" (change)="onChangeCheckbox($event,'controllerStatusChart')">Controller Oper Statuses</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="alarmsChart" (change)="onChangeCheckbox($event,'alarmsChart')">Edge Events</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="controllerEventChart" (change)="onChangeCheckbox($event,'controllerEventChart')">Controller Events</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="alertsChart" (change)="onChangeCheckbox($event,'alertsChart')">Alerts</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="activeOperChart" (change)="onChangeCheckbox($event,'activeOperations')">Active Operations</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="edgeAdminStatusChart" (change)="onChangeCheckbox($event,'edgeAdminStatusChart')">Edge Admin Statuses</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="vnfTypesChart" (change)="onChangeCheckbox($event,'vnfTypesChart')">Application Edge Services by Admin Statuses </mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="discoveryChart" (change)="onChangeCheckbox($event,'discoveryChart')">Edge Discovery</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="controllerDiscoveryChart" (change)="onChangeCheckbox($event,'controllerDiscoveryChart')">Controller Discovery</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="servicesChart" (change)="onChangeCheckbox($event,'servicesChart')">Edge Services by Template</mat-checkbox>
        <mat-checkbox class="customize-margin" [(ngModel)]="servicesAdminChart" (change)="onChangeCheckbox($event,'servicesAdminChart')">Kubernetes Edge Services by Admin Status</mat-checkbox>
        <mat-checkbox class="customize-margin" checked [(ngModel)]="kubernetesServicesChart" (change)="onChangeCheckbox($event,'kubernetesServicesChart')">Kubernetes Service Events</mat-checkbox>
        <mat-checkbox class="customize-margin" checked  [(ngModel)]="osServicesChart" (change)="onChangeCheckbox($event,'osServicesChart')">Openstack Service Events</mat-checkbox>
      </div>
    </section>

</mat-drawer-container>
