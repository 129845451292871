import { AfterViewInit, Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppliedFilterService } from 'services/applied-filter.service';
import { LocalStorageService } from 'services/local-storage.service';

@Directive({
  selector: '[appPaginatorStorage]'
})
export class PaginatorStorageDirective implements AfterViewInit {
  @Output()
  pageStore = new EventEmitter();
  @Input()
  tableName = '';

  constructor(private localStorageService: LocalStorageService, public cookieService: CookieService, private router: Router, private appliedFilterService: AppliedFilterService) {
  }

  @HostListener('page', [ '$event' ])
  onPage(page: any) {
    if (this.tableName == 'alertsDropdown') {
      return;
    }
    const currentPage = this.router.url;
    this.localStorageService.patchItemValue(this.cookieService.get('user'), 'paginatorHistoryPage', { [currentPage]: page }, currentPage);
  }

  ngAfterViewInit(): void {
    const page = this.localStorageService.getItem(this.cookieService.get('user'))[this.router.url]?.paginatorHistoryPage;
    if (!this.appliedFilterService.getFilters(this.appliedFilterService.filterNameForComponent)?.length) {
      this.pageStore.emit(page);
    }
  }
}
