export const ITEMS = [{
  name: 'Dashboard',
  icon: 'dashboard',
  routerLink: '/Dashboard',
  theChosen: false
}, {
  name: 'Edge Management',
  icon: 'cloud',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Devices', theChosenChild: false, routerLink: 'Edge Management/EdgeDevices' },
    { name: 'Operators', theChosenChild: false, routerLink: 'Edge Management/Operators' },
    { name: 'Customers', theChosenChild: false, routerLink: 'Edge Management/Customers' },
    { name: 'Services', theChosenChild: false, routerLink: 'Edge Management/Services' },
    { name: 'Sites', theChosenChild: false, routerLink: 'Edge Management/Sites' },
    { name: 'Hardware Types', theChosenChild: false, routerLink: 'Edge Management/HWTypes' },
    { name: 'Templates', theChosenChild: false, routerLink: 'Edge Management/EdgeTemplates' }
  ]
}, {
  name: 'Controller Management',
  icon: 'dns',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Controllers', theChosenChild: false, routerLink: 'Controller Management/Controllers' },
  ]
}, {
  name: 'VPN Management',
  icon: 'wifi_tethering',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Concentrators', theChosenChild: false, routerLink: 'VPN Management/VPN' },
  ]
}, {
  name: 'Application Management',
  icon: 'extension',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Application Catalog', theChosenChild: false, routerLink: 'Application Management/ApplicationsCatalog' },
    { name: 'Service Templates Catalog', theChosenChild: false, routerLink: 'Application Management/ApplicationTemplates' },
    { name: 'Edge Services', theChosenChild: false, routerLink: 'Application Management/ApplicationsManage' },
    { name: 'Service Designer', theChosenChild: false, routerLink: 'Application Management/ServiceDesign' },

  ]
}, {
  name: 'Kubernetes Service Management',
  svgIcon: 'kubernetes',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Images Catalog', theChosenChild: false, routerLink: 'Kubernetes Service Management/ServiceImageCatalog' },
    { name: 'Service Templates Catalog', theChosenChild: false, routerLink: 'Kubernetes Service Management/ServiceTemplateCatalog' },
    { name: 'Backup Profiles', theChosenChild: false, routerLink: 'Kubernetes Service Management/BackupProfiles' },
    { name: 'Backup History', theChosenChild: false, routerLink: 'Kubernetes Service Management/BackupHistory' },
    { name: 'Restore Backups', theChosenChild: false, routerLink: 'Kubernetes Service Management/RestoreHistory' },
    { name: 'Edge Services', theChosenChild: false, routerLink: 'Kubernetes Service Management/DeployedServices' },
    { name: 'Service Designer', theChosenChild: false, routerLink: 'Kubernetes Service Management/ServiceDesign' },
  ]
}, {
  name: 'Openstack Service Management',
  svgIcon: 'openstack',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Images Catalog', theChosenChild: false, routerLink: '/Openstack Service Management/OpenstackImageCatalog' },
    { name: 'Flavors Catalog', theChosenChild: false, routerLink: '/Openstack Service Management/OpenstackFlavorCatalog' },
    { name: 'Networks Catalog', theChosenChild: false, routerLink: '/Openstack Service Management/OpenstackNetworkCatalog' },
    { name: 'Service Templates Catalog', theChosenChild: false, routerLink: '/Openstack Service Management/OpenstackTemplateCatalog' },
    { name: 'Edge Services', theChosenChild: false, routerLink: '/Openstack Service Management/OpenstackServices' },
    { name: 'Service Designer', theChosenChild: false, routerLink: 'Openstack Service Management/OpenstackServiceDesign' },
  ]
}, {
  name: 'License Management',
  icon: 'done_all',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Licenses', theChosenChild: false, routerLink: 'License Management/Licenses/' },
    { name: 'Summary', theChosenChild: false, routerLink: 'License Management/Summary' }
  ]
}, {
  name: 'Notifications',
  icon: 'notification_important',
  theChosen: false,
  children: [
    { name: 'Edge Events', theChosenChild: false, routerLink: 'Notifications/EdgeEvents' },
    { name: 'Controller Events', theChosenChild: false, routerLink: 'Notifications/ControllerEvents' },
    { name: 'Kubernetes Service Events', theChosenChild: false, routerLink: 'Notifications/ServiceEvents' },
    { name: 'Openstack Service Events', theChosenChild: false, routerLink: 'Notifications/OpenstackServiceEvents' },
    { name: 'System Events', theChosenChild: false, routerLink: 'Notifications/SystemEvents' },
    { name: 'User Operations', theChosenChild: false, routerLink: 'Notifications/UserOperations' },
    { name: 'Alerts', theChosenChild: false, routerLink: 'Notifications/Alerts' }
  ]
}, {
  name: 'Discovery',
  icon: 'search',
  children: [
    { name: 'Edge Devices ', theChosenChild: false, routerLink: 'Discovery/DiscoveryEdges' },
    { name: 'Controllers', theChosenChild: false, routerLink: 'Discovery/DiscoveryControllers' },
  ]
}, {
  name: 'Administration',
  icon: 'people',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Projects', theChosenChild: false, routerLink: 'Administration/Projects' },
    { name: 'Project Settings', theChosenChild: false, routerLink: 'Administration/ProjectSettings' },
    { name: 'System Monitoring', theChosenChild: false, routerLink: 'Administration/SystemMonitoring' },
    { name: 'Users', theChosenChild: false, routerLink: 'Administration/UsersManagement' },
  ]
}];
