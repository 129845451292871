import { Injectable, signal } from "@angular/core";
import { IdType } from "vis-network";
import { DataSet } from 'vis-data';
import { HWResources } from "../models/HWResources";
import { HWType } from "../models";
import { VisElement } from "../models/interfaces/visElement";
import { DesignUnsavedChanges, initialDesignUnsavedChanges } from "../models/interfaces/DesignUnsavedChanges";
import { AlertNotificationService } from "./alertNotification.service";
import { Subject } from "rxjs";
const CPU_KUBER_REGEX = /^[+]?\d+([.]\d+)?[m]$/

export interface IdValue {
    id: number;
    value: number;
}
interface IdResourceValue {       // this.saveTemplate([edgeObjects, nodeObjects]);

    id: number;
    value: {
        cpus: number;
        ram: number;
        disk: number;
    }
}

@Injectable()
export class ServiceDesignerService {
    cpu_quota: number;
    memory_quota: number;
    disk_quota: number;
    cpu_occuped: IdValue[] = new Array<IdValue>();

    resources_occupied = Array<IdResourceValue>();

    Nodes = new DataSet<any>([]);
    Edges = new DataSet<any>([]);

    serviceName: string = '';
    serviceDescription: string = '';
    design: string = '';
    templatePreview: string = '';

    id: number = 0;

    editMode: boolean = false;

    hw_resources: HWResources = new HWResources();
    hwType: HWType;

    customer_id: number;
    service_id: number;
    project_id: number;
    site_id: number;
    operator_id: number;

    customer: string;
    service: string;
    project: string;
    site: string;
    operator: string;

    logicalPorts: VisElement[];
    physicalPorts: VisElement[];
    layer2Ports: VisElement[];
    resetTopology: boolean = false;

    designUnsavedChanges = signal<DesignUnsavedChanges>(initialDesignUnsavedChanges);

    onDesignGuidelinesClickSubject = new Subject();
    dragStartTourSubject = new Subject();
    dropTourSubject = new Subject();
    selectedNode: any;

    isTourGuideOpen = signal<boolean>(false);

    constructor(private alertNotificationService: AlertNotificationService) {
    }

    assignProcessorCPU(id, value) {
        if ([id, value].includes(undefined))
            return
        if (CPU_KUBER_REGEX.test(value)) {
            value = 1;
        }
        const val = +value;

        const el = this.cpu_occuped.find(item => item.id == id);
        if (el == undefined) {
            this.cpu_occuped.push({ id: id, value: val })
        }

        if (el && value && el['value'] != value) {
            el['value'] = +val;
        }
    }

    assignProcess(id: number, resource: Partial<{ cpus: number; ram: number; disk: number; }>) {
        if ([id, resource].includes(undefined)) return;

        const el = this.resources_occupied.find(item => item.id === id);

        if (!el) {
            this.resources_occupied.push({
                id: id,
                value: {
                    cpus: resource.cpus || 0,
                    ram: resource.ram || 0,
                    disk: resource.disk || 0
                }
            });
        } else {
            el.value.cpus = (resource.cpus !== undefined) ? resource.cpus : el.value.cpus;
            el.value.ram = (resource.ram !== undefined) ? resource.ram : el.value.ram;
            el.value.disk = (resource.disk !== undefined) ? resource.disk : el.value.disk;
        }
    }

    checkLimit() {
        const sum = this.cpu_occuped.length > 0 ? this.cpu_occuped.map(item => item.value).reduce((sum, current) => sum + current) : 0;
        return this.cpu_quota >= sum;
    }

    checkLimitReached() {
        if (this.resources_occupied.length < 1) return;

        let totalCpus = 0, totalRam = 0, totalDisk = 0;

        this.resources_occupied.forEach(resource => {
            totalCpus += resource.value.cpus;
            totalRam += resource.value.ram;
            totalDisk += resource.value.disk;
        });

        return {
            cpus: totalCpus >= this.cpu_quota,
            ram: totalRam >= (this.memory_quota * 1000),
            disk: totalDisk >= this.disk_quota
        };
    }

    releaseCPUSById(freeCPUbyNodeId: IdType) {
        const indexForRemove = this.cpu_occuped.findIndex(el => el.id === freeCPUbyNodeId);
        indexForRemove != undefined && indexForRemove > -1 ? this.cpu_occuped.splice(indexForRemove, 1) : null;
    }


    exportTopologyToJson() {
        const edgeObjects = [];
        const nodeObjects = [];

        this.Edges.forEach((edge) => {
            edgeObjects.push(edge);
        });

        this.Nodes.forEach((node) => {
            if (node.type == 'Pod') {
                if (node.params['preStop'] && Object.values(node.params.preStop.httpGet).length == 1 && node.params.preStop['exec'] == undefined) {
                    delete node.params.preStop;
                }
                if (node.params['postStart'] && Object.values(node.params.postStart.httpGet).length == 1 && node.params.postStart['exec'] == undefined) {
                    delete node.params.postStart;
                }
                if (node.params['preStop'] && node.params['preStop']['httpGet']['httpHeaders'] && node.params['preStop']['httpGet']['httpHeaders'].length == 0) {
                    delete node.params['preStop']['httpGet']['httpHeaders']
                }
                if (node.params['postStart'] && node.params['postStart']['httpGet']['httpHeaders'] && node.params['postStart']['httpGet']['httpHeaders'].length == 0) {
                    delete node.params['postStart']['httpGet']['httpHeaders']
                }
            }
            nodeObjects.push(node);
        });
        const designStringfy = JSON.stringify([edgeObjects, nodeObjects])
        this.design = designStringfy;
        return designStringfy
    }

    clearService() {
        this.Nodes?.clear();
        this.Edges?.clear();
        this.serviceName = '';
        this.serviceDescription = '';
        this.editMode = false;
        this.templatePreview = undefined;

        this.project = undefined;
        this.customer = undefined;
        this.operator = undefined;
        this.hwType = undefined;
        this.designUnsavedChanges.set(initialDesignUnsavedChanges);
        this.isTourGuideOpen.set(false);
    }

    checkTemplateSaved() {
        const { isStep1FormTouched, isTempalateSaved } = this.designUnsavedChanges();
        return !isTempalateSaved && isStep1FormTouched || !isTempalateSaved && (this.Nodes.length > 0 || this.Edges.length > 0) ?
            this.alertNotificationService.alertGuardConfirm('Design') : true;
    }
}