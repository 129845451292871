
export class EdgeStatuses{
    busy: number;
    running: number;
    starting: number;
    unreachable: number;
    rebooting:number;
    degraded:number;
    unlicensed:number;
    upgrading:number;
    'not contacted':number;
    stopping:number;
}

