import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { save } from '../components/helpers/disable-save.interceptor';
import { ActionResponse, Service } from '../models';
import { Response } from '../models/types/common';
import { ResourceService } from './resource.service';


@Injectable()
export class ServiceService {

  apiUrl: string;

  constructor(private resourceService: ResourceService, private http:HttpClient) {
  }

  getServicesCount():Observable<number>{
    return this.http.get<number>(this.resourceService.getApiUrl()+"/service/count")
 }

  getServices(page:number,count:number, order='name', direction='asc'): Observable<Service[]>{
     return this.http.get<Service[]>(this.resourceService.getApiUrl()+`/service?page=${page}&count=${count}&order=${order}&direction=${direction}`)
  }

  getServicesByCustomerId(customerId: number, page: number = 1, count: number = 20,order='name',direction='asc', id_list:boolean=true): Observable<Response<Service>> {
    return this.http.get<Response<Service>>(this.resourceService.getApiUrl()+`/service?customer_id=${customerId}&page=${page}&count=${count}&order=${order}&direction=${direction}&id_list=${id_list}`);
  }

  getService(servicetId: number): Observable<Service> {
    return this.http.get<Service>(this.resourceService.getApiUrl() + '/service/' + servicetId);
  }


  createService(service: Service):Observable<any> {
    const context = new HttpContext().set(save, true);
    return this.http.put(this.resourceService.getApiUrl()+'/service',service, { context })
  }

  updateService(serviceId:number,service: Service) {
    const context = new HttpContext().set(save, true);
    return this.http.post(this.resourceService.getApiUrl() + '/service/'+ serviceId, service, { context });
  }

  saveService(service: Service) {
    return this.http.put(this.resourceService.getApiUrl()+ '/services/', service);
  }

  deleteService(serviceId: number): Observable<any> {
    return this.http.delete(this.resourceService.getApiUrl() + '/service/' + serviceId);
  }

  deleteServices(userIds: number[]): Observable<ActionResponse[]> {
    return this.http.post<ActionResponse[]>(this.resourceService.getApiUrl() + '/projects/delete', userIds);
  }
}
