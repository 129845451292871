import { BasicEdgeInterface } from "..";
import { SmartProtocol, TraceHostCfg } from './interfaceModel';
export class Pppoe implements BasicEdgeInterface{
  name: string;
  type: string;
  'ip-passthrough': boolean;
  management: boolean;
  metric: number;
  'auth-type': 'pap' | 'chap';
  username: string;
  password: string;
  'ethernet-interface': string;
  enable: boolean;
  'idle-timeout': number;
  'retry-timeout': number;
  ipv6: boolean;
  mtu: number;
  'ac-name': string;
  //"trace-host": string;
  tracehost:TraceHostCfg;
  'smart-protocol-passthrough': SmartProtocol[];
  pppoeID: string;

  constructor() {
    this.name = 'ppp';
    this.type = 'pppoe';
    this['ip-passthrough'] = false;
    this.management = false;
    this.metric = 130;
    this['auth-type'] = 'pap';
    this.username = ''; // 64 chars limit
    this.password = ''; // 64 chars limit
    this['ethernet-interface'] = undefined; // e.g. 'data0'
    this.enable = false;
    this['idle-timeout'] = 0; // range: 0-4294967295
    this['retry-timeout'] = 0; // range: 0-4294967295
    this.ipv6 = false; // disabled until IPv6 is fully supported
    this.mtu = 1492; // range: 0-1500
    this['ac-name'] = '';
    // this["trace-host"] = '';
    this.tracehost=new TraceHostCfg();
    this['smart-protocol-passthrough'] = [new SmartProtocol()];
  }
}
