import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { save } from '../components/helpers/disable-save.interceptor';
import { SKIP_STATUS } from '../components/helpers/status.interceptor';
import { FilterChip } from '../models';
import { EdgeNode, TableModelEdgeNode } from '../models/edgeNode';
import { ResourceService } from './resource.service';

const headers = new HttpHeaders().set(
  'Content-Type',
  'application/text; charset=utf-8'
);
@Injectable({
  providedIn: 'root'
})
export class UcpeDataService {
  //  private SERVER_ADDR: string = "http://localhost:3000";
  // apiUrl: string;
  private edgeIds: Array<number> = [];
  private edges: EdgeNode[] = [];
  private filters: FilterChip[] = [];
  private _selectedEdge: EdgeNode;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService
  ) {
    this.filters = []
  }

  getNodeById(nodeId: number) {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/node/${nodeId}`)
  }

  getUcpes(page: number, count: number, order?: string, direction?: string): Observable<TableModelEdgeNode> {
    let requestString = `/node?page=${page}&count=${count}`
    if (order != undefined && direction != undefined) {
      requestString = requestString + `&order=${order}&direction=${direction}`
    }
    return this.http.get<TableModelEdgeNode>(this.resourceService.getApiUrl() + requestString)
  }

  getUcpesCount(): Observable<number> {
    return this.http.get<number>(this.resourceService.getApiUrl() + "/node/count")
  }

  getSshTerminalById(nodeId: number) {
    return this.http.get(
      this.resourceService.getApiUrl() + `/node/terminal/${nodeId}`,
      { headers, responseType: 'text' }
    );
  }

  getSshTerminalControllerById(nodeId: number): Observable<string> {
    return this.http.get(this.resourceService.getApiUrl() + `/controller/terminal/${nodeId}`,
      { headers, responseType: 'text' });
  }

  filterUcpes(filterData: any): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced?page=1&count=50', { filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }

  filterUcpesPaged(filterData, page?: number, count?: number): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/node/advanced?page=${page}&count=${count}`, { filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }
  filterUcpesOrdered(filterData, order: string, direction: string): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/node/advanced?order=${order}&direction=${direction}`, { filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }

  filterUcpesPagedOrdered(filterData, page: number, count: number, order: string, direction: string): Observable<any> {
    if (direction == '') {
      direction = 'asc';
    }
    return this.http.post<any>(this.resourceService.getApiUrl() + `/node/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`, { filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }

  deleteEdgeById(edgeId: number): Observable<any> {
    return this.http.delete<any>(this.resourceService.getApiUrl() + `/node/${edgeId}`)
  }

  getDiscoveredEdges(page?: number, count?: number, status?: string, is_ignored?: boolean, order?: number, direction?: string): Observable<any> {
    if (page == undefined) page = 1
    if (count == undefined) count = 20;
    let requestString = `/status/discover?`
    requestString = requestString + `page=${page}&count=${count}`

    if (status != undefined) {
      requestString = requestString + `&status=${status}`
    }

    if (is_ignored != undefined) {
      requestString = requestString + `&is_ignored=${is_ignored}`
    }

    if (order != undefined && direction != undefined) {
      requestString = requestString + `&order=${order}&direction=${direction}`
    }
    return this.http.get<any>(this.resourceService.getApiUrl() + requestString)
  }


  getDiscoveredEdgesAdvanced(id_only: boolean = false, filterData: any, page: number, count: number, order: string, direction: string): Observable<any> {
    if (page == undefined || page == 0) page = 1
    if (count == undefined) count = 20;
    if (filterData == undefined) filterData = []
    if (order == undefined) order = 'uid'
    if (direction == undefined) direction = 'asc'
    return this.http.post<any>(this.resourceService.getApiUrl() + `/status/discover/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`, { "id_only": id_only, filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }



  addDiscoveredEdges(serialEdges: string[], siteId: number, templateId: number): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/status/discover/approve`, {
      "uid": serialEdges,
      "site_id": siteId,
      "template_id": templateId
    })
  }

  blacklistManageEdges(is_ignored: boolean, serialEdges: string[]): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/status/discover/blacklist`, {
      "is_ignored": is_ignored,
      "uid": serialEdges
    })
  }

  getEdgeConfigById(edgeId: number): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/node/config/${edgeId}`)
  }

  UpdateEdgeConfigById(edgeId: number, updatedConfig: object): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/node/config/${edgeId}`, updatedConfig)
  }

  ApplyEdgeConfigById(edgeId: number, updatedConfig: object): Observable<any> {
    return this.http.put<any>(this.resourceService.getApiUrl() + `/node/apply/${edgeId}`, updatedConfig)
  }

  ApplySavedConfigById(edgeId: number, observe = 'body'): Observable<any> {
    return this.http.put<any>(this.resourceService.getApiUrl() + `/node/apply/${edgeId}`, { observe: observe })
  }

  SyncEdgeConfigById(edgeId: number, updatedConfig: object): Observable<any> {
    return this.http.put<any>(this.resourceService.getApiUrl() + `/node/sync/${edgeId}`, updatedConfig)
  }
  /**
   *
   * @param edgeId=0 default kubernetes device config
   * @param eddgeId=-1  default openstack device config
   * @param edgeId=X
   * @returns device config
   */
  getEdgeConfig(edgeId: number): Observable<any> {
    {
      return this.http.get<any>(this.resourceService.getApiUrl() + `/node/config/${edgeId}`)
    }
  }

  getEdgeDefinition(edgeId: number, add_config: boolean = false, add_uptime: boolean = false): Observable<any> {
    {
      return this.http.get<any>(this.resourceService.getApiUrl() + `/node/${edgeId}?add_config=${add_config}&add_uptime=${add_uptime}`)
    }
  }

  updateEdgeConfig(edgeId: number, newConfig: object): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + `/node/config/${edgeId}`, newConfig)
    }
  }

  updatEdgeJson(edgeId, newConfig: object): Observable<any> {

    return this.http.post<any>(this.resourceService.getApiUrl() + `/node/json/${edgeId}`, newConfig)

  }

  updateEdgeDefinition(edgeId: number, newConfig: object, quagga_bgpd: File, quagga_ospfd: File, quagga_ospfd6: File, quagga_zebrad: File, observe = 'body' as any, skipStatus = false, services: any, applications: any): Observable<any> {
    const formData: FormData = new FormData();

    if (quagga_bgpd != undefined) {
      formData.append('quaggaBgp', quagga_bgpd, quagga_bgpd.name);
    }
    if (quagga_ospfd != undefined) {
      formData.append('quaggaOspf', quagga_ospfd, quagga_ospfd.name);
    }
    if (quagga_ospfd6 != undefined) {
      formData.append('quaggaOspf6', quagga_ospfd6, quagga_ospfd6.name);
    }
    if (quagga_zebrad != undefined) {
      formData.append('quaggaStatic', quagga_zebrad, quagga_zebrad.name);
    }

    formData.append('jsonData', JSON.stringify(newConfig));
    formData.append('ztp_services', JSON.stringify(services))
    formData.append('ztp_apps', JSON.stringify(applications))

    const context = new HttpContext().set(SKIP_STATUS, skipStatus).set(save, true);
    return this.http.post<any>(this.resourceService.getApiUrl() + `/node/${edgeId}`, formData, { observe, context });
  }

  //  createEdge(createConfig:{}):Observable<any>{
  //   {
  //     return this.httpService.put<any>(this.resourceService.getApiUrl() +`/node`,createConfig)
  //    }
  //  }
  //this.edgeConfig.quagga.quagga_zebrad,this.edgeConfig.quagga.quagga_ospfd,this.edgeConfig.quagga.quagga_ospfd6,this.edgeConfig.quagga.quagga_bgpd
  createEdge(createConfig: object, quagga_bgpd: File, quagga_ospfd: File, quagga_ospfd6: File, quagga_zebrad: File, services: any, applications: any): Observable<any> {
    {
      const context = new HttpContext().set(save, true);
      const formData: FormData = new FormData();

      if (quagga_bgpd != undefined) {
        formData.append('quaggaBgp', quagga_bgpd, quagga_bgpd.name);
      }
      if (quagga_ospfd != undefined) {
        formData.append('quaggaOspf', quagga_ospfd, quagga_ospfd.name);
      }
      if (quagga_ospfd6 != undefined) {
        formData.append('quaggaOspf6', quagga_ospfd6, quagga_ospfd6.name);
      }
      if (quagga_zebrad != undefined) {
        formData.append('quaggaStatic', quagga_zebrad, quagga_zebrad.name);
      }

      formData.append('jsonData', JSON.stringify(createConfig))

      formData.append('ztp_services', JSON.stringify(services))
      formData.append('ztp_apps', JSON.stringify(applications))

      return this.http.put<any>(this.resourceService.getApiUrl() + `/node`, formData, { context });
    }
  }


  createEdgeJson(createConfig: object): Observable<any> {

    return this.http.put<any>(this.resourceService.getApiUrl() + `/node/json`, createConfig)

  }
  getEdgeConfigTree(edgeId: number): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced', { "edit_config": [edgeId] })
    }
  }

  updateEdgeConfigTree(edgeId: number, newConfig: object): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + `/node/advanced`, { "save_config": [edgeId], 'config': newConfig })
    }
  }


  getCountriesCodesList(): Observable<any> {
    return this.http.get('../../assets/countriesCodes.json');
  }


  //working for text responce
  nodeExecuteCommand1(nodeId: number, command: string, timeout: number = 60): Observable<any> {
    {
      return this.http.post(this.resourceService.getApiUrl() + `/node/execute/${nodeId}`,
        {
          "command": command,
          "timeout": timeout
        }, { responseType: 'text' }
      )
    }
  }

  getMonitoringData(edgeId: number, type: string, is_average: boolean): Observable<any> {
    {
      return this.http.get<any>(this.resourceService.getApiUrl() + `/monitor/node/${edgeId}?type=${type}&is_average=${is_average}`)
    }
  }

  getControllerMonitoringData(edgeId: number, type: string, is_average: boolean): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/monitor/controller/${edgeId}?type=${type}&is_average=${is_average}`);
  }

  getControllerDetails(edgeId: number, add_uptime: boolean = false): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/controller/${edgeId}?add_uptime=${add_uptime}`);
  }

  getServiceMonitorData(serviceId: number, vmName: string, type: string): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + `/monitor/service/${serviceId}/${vmName}/${type}`);// ?is_average=${is_average}
  }

  deleteBuilkNodesByIds(edgeIds: Array<number>, restore: boolean): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced', { "delete": edgeIds, "restore": restore })
    }
  }

  rebootBuilkNodesByIds(edgeIds: Array<number>, observe = 'body' as const): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced', { "reboot": edgeIds }, { observe })
    }
  }

  applyBuilkNodesByIds(edgeIds: Array<number>, observe = 'body' as any): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced', { "apply": edgeIds }, { observe })
    }
  }

  synkBuilkNodesByIds(edgeIds: Array<number>, observe = 'body' as any): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced', { "sync": edgeIds }, { observe })
    }
  }

  editBulkNodesByIds(edgeIds: Array<number>, jsonConfig: object): Observable<any> {
    {
      const context = new HttpContext().set(save, true);
      return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced', { 'bulk_edit': edgeIds, 'config': jsonConfig }, { context })
    }
  }


  getBulkNodeIds(id_only: true, filter: any): Observable<any> {
    {
      return this.http.post<any>(this.resourceService.getApiUrl() + '/node/advanced', { "id_only": id_only, "filter": filter })
    }
  }

  getBulkNodeByColumn(page, count, order, direction, id_list: [], filter: any): Observable<any> {
    {
      if (page == undefined) page = 1
      if (count == undefined) count = 20;
      let requestString = `/node/advanced?`
      requestString = requestString + `page=${page}&count=${count}`

      if (order != undefined) {
        requestString = requestString + `&order=${order}`;
      }

      if (direction != undefined) {
        requestString = requestString + `&direction=${direction}`;
      }

      return this.http.post<any>(this.resourceService.getApiUrl() + requestString, { "id_list": id_list, "filter": filter })
    }
  }

  storeEdgeIds(edgeIds: Array<number>) {
    this.edgeIds = edgeIds
  }

  getEdgeIds() {
    return this.edgeIds
  }

  clearEdgeIds() {
    this.edgeIds = []
  }

  storeEdges(edges: Array<EdgeNode>) {
    this.edges = edges
  }

  getEdges() {
    return this.edges
  }

  clearEdges() {
    this.edges = []
  }

  // selectedEdges: EdgeNode[] = [];
  storeFilters(filters: FilterChip[]) {
    this.filters = filters
  }

  getFilters() {
    return this.filters
  }

  clearFilters() {
    this.filters = []
  }

  setSelectedEdge(edge: EdgeNode) {
    this._selectedEdge = edge;
  }

  getSelectedEdge() {
    return this._selectedEdge;
  }

  clearSelectedEdge() {
    this._selectedEdge = undefined;
  }

}


