import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'services/theme.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent {
  constructor(public router: Router, private themeService: ThemeService, private breadcrumbService: BreadcrumbService, 
    private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer) {
    this.matIconRegistry
      .addSvgIcon(
        "openstack",
        this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/openstack-icon.svg")
      )
      .addSvgIcon(
        "kubernetes",
        this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/kubernetes-svgrepo-com.svg")
      );
      
  }
 
  title = 'Manager 2.0';
}
