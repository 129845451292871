<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #logindrawer mode="side" position="end">
        <mat-nav-list>
            <mat-card appearance="outlined" class="login-menu-card">
                <div class="menu-content">
                    <h3>Edgility Edge Management and Orchestration</h3>
                    <mat-divider></mat-divider>
                    <dl>
                        <dt>Name</dt>
                        <dd>{{ currentTokenInfo.username }}</dd>
                        <dt>Project</dt>
                        <dd>{{ currentTokenInfo.project }}</dd>         
                        <dt>Role</dt>
                        <dd>{{ currentTokenInfo.role }}</dd>        
                        <ng-container *ngIf="currentTokenInfo.role_id">
                            <dt *ngIf="currentTokenInfo.operator_name">Operator</dt>
                            <dd *ngIf="currentTokenInfo.operator_name">{{ currentTokenInfo.operator_name }}</dd>
                            
                            <dt *ngIf="currentTokenInfo.customer_name">Customer</dt>
                            <dd *ngIf="currentTokenInfo.customer_name">{{ currentTokenInfo.customer_name }}</dd>
                        </ng-container>             
                        <dt *ngIf="customer">Customer</dt>
                        <dd *ngIf="customer">{{ customer }}</dd>
                        <dt *ngIf="operator">Operator</dt>
                        <dd *ngIf="operator">{{ operator }}</dd>
                        <dt>Privilege level</dt>
                        <dd>{{ currentTokenInfo.is_super_user == 1 ? 'Super user' : 'Project user' }} <span *ngIf="currentTokenInfo.is_readonly"> (RO)</span></dd>
                        <dt>Version</dt>
                        <dd>{{ version }}</dd>
                    </dl>
                    <mat-divider></mat-divider>
                    <button mat-button class="logout" (click)="logout()">Logout</button>
                </div>
            </mat-card>
        </mat-nav-list>
    </mat-sidenav>
</mat-sidenav-container>