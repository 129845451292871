import { Component } from "@angular/core";

@Component({
    selector: "telco-entry",
    templateUrl: "./entry.component.html",
    styleUrls: ["./entry.component.css"]
})
export class EntryComponent {

}
