import { BasicEdgeInterface } from '..';
import { SmartProtocol, TraceHostCfg } from './interfaceModel';


export class Lte implements BasicEdgeInterface{
    constructor() {
        this.bootproto = 'dhcp';
        this.apn = '';
        this.name = 'lte0';
        this.pass = '';
        this.management = false;
        this.enable = true;
        this.type = 'lte';
        this.user = '';
        this.metric = 110;
        this["trace-host"] = '';
        this["ip-passthrough"] = false;
        this.tracehost = new TraceHostCfg();
        this['smart-protocol-passthrough'] = [new SmartProtocol()]
    }
    bootproto: string;
    apn: string;
    name: string;
    pass: string;
    management: boolean;
    enable: boolean;
    type: string;
    user: string;
    metric: number;
    "trace-host": string;
    "ip-passthrough": boolean;
    tracehost: TraceHostCfg;
  'smart-protocol-passthrough'?: SmartProtocol[]
}
