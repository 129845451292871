import { Component } from "@angular/core";

@Component({
    selector: "telco-entry-mobile",
    templateUrl: "./entry-mobile.component.html",
    styleUrls: ["./entry-mobile.component.scss"]
})
export class MobileEntryComponent {

}
