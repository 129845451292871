<mat-drawer-container class="example-container mat-typography" autosize>
  <mat-drawer #drawer mode="side" disableClose="true" opened="true">
    <div  [style.margin-left]="isSideMenuOpen ? '80%' : '20%'" [matTooltip]="isSideMenuOpen ? 'Hide Menu' : 'Show Menu'"
    matTooltipClass="tooltipLook" aria-label="test">
      <button mat-icon-button (click)="isSideMenuOpen=!isSideMenuOpen">
        <mat-icon color="primary" class='mat-icon-sidebar'>{{isSideMenuOpen ? 'arrow_left' : 'arrow_right'}}</mat-icon>
      </button>
    </div>
    <mat-nav-list *ngFor="let item of items">
      <mat-list-item [routerLink]="item.routerLink ? [item.routerLink] : []" (click)="onItemSelected(item)">
        <div *ngIf="isSideMenuOpen; else icon " style="display: flex">
          <mat-icon matListItemIcon aria-hidden="false" [svgIcon]="item.svgIcon || ''">{{item.icon}}</mat-icon>
          <span style="display:flex; align-items: center; margin: 0 1rem 0 .5rem;font-size: 16px; color:#000000de">{{item.name}}</span>
        </div>
        <ng-template #icon>
          <mat-icon aria-hidden="false" [svgIcon]="item.svgIcon || ''">{{item.icon}}</mat-icon>
        </ng-template>
      </mat-list-item>
      <div class="submenu" *ngIf="isSideMenuOpen && item.showSubmenu && item.children">
        <ng-container *ngFor="let child of item.children">
          <a id="childItem" *ngIf="child.name | ifRole" (click)="onItemChildren(item, child)"
             [class.active]="child.theChosenChild" mat-list-item [routerLink]="child.routerLink">{{child.name}}</a>
        </ng-container>
      </div>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content class="content"
                      style="display: flex; flex-direction: column; {{!isSideMenuOpen ? 'margin-left:7rem' : 'margin: 0;'}}">
    <ng-template #separatorTemplate>
      <mat-icon [ngStyle]="{'color': 'black'}">arrow_right</mat-icon>
    </ng-template>

    <div class="breadcrumbs-container" *ngIf="displayBreadcrump">
      <xng-breadcrumb [separator]="separatorTemplate" class="custom-breadcrumb"></xng-breadcrumb>
    </div>

    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
