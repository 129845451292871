

export const initialDesignUnsavedChanges = {
    isTempalateSaved: false,
    isStep1FormTouched: false
}

export interface DesignUnsavedChanges {
    isStep1FormTouched?: boolean;
    isTempalateSaved?: boolean;
}