import {
  ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import {
    interval, Observable, Subscription
} from "rxjs";
import { ThemeService } from "services/theme.service";
import { AlertsChartData } from "src/app/models/interfaces/AlertsChartData";
import { ServicesChartData } from "src/app/models/ServicesChartData";

import {
    AlarmsChartData, CpuChartData, DiscoveryChartData, EdgeStatuses, HddChartData, ManagerHealthChartData, RamChartData, VnfChartData
} from "../../../models";
import { DashboardService } from "../../../services";
import { AdvancedFilterService } from '../../advanced-filter/advanced-filter.service';
import { CustomizeBarComponent } from '../../UI/customize-bar/customize-bar.component';

export class ServicesAllChartData {
    "not started": number;
    "started": number;
}

@Component({
    selector: 'app-dashboard',
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(CustomizeBarComponent) customizeBarComponent: CustomizeBarComponent;

  readonly filterLocation = this.router.url.split("/")[this.router.url.split("/").length - 1];
    readonly loggedInUserID = parseInt(this.cookieService.get("id"));
    public ucpeChartData$: EdgeStatuses;
    public controllerChartData$: EdgeStatuses;
    public alarmCharData$: AlarmsChartData;
    public alertsCharData$: AlertsChartData;
    public discoveryChartData$: DiscoveryChartData;
    public vnfTypesChartData$: VnfChartData;
    public controllerDiscoveryChartData$: DiscoveryChartData;
    public servicesTypesChartData$: ServicesChartData;
    public servicesAdminTypesChartData$: ServicesAllChartData;
    public activeEventCharData$: any;
    public activeOperationsCharData$: any;
    K8sServiceEventCharData: any;
    osServiceEventCharData: any;

    public serviceChartData$:Observable<ServicesChartData>;
    public vnfChartData$:Observable<VnfChartData>;
    public cpuChartData$:Observable<CpuChartData>;
    public ramChartData$:Observable<RamChartData>;
    public hddChartData$:Observable<HddChartData>;
    public managerHealthChartData$: Observable<ManagerHealthChartData>;
    public adminStatusChartData$: Observable<any>;

    adminStatusChartShow: boolean;
    ucpeStatusChartShow: boolean;
    discoveryChartShow: boolean;
    managerHealthChartShow: boolean;
    vnfStatusChartShow: boolean;
    vnfTypesChartShow: boolean;
    alarmsChartShow: boolean;
    controllerEventChartShow: boolean;
    kubernetesServicesChartShow = true;
    osServicesChartShow:boolean= true;
    alertsChartShow: boolean;
    activeOperationsChartShow: boolean;
    controllerStatusChartShow: boolean;
    controllerDiscoveryChartShow: boolean;
    servicesChartShow: boolean;
    servicesChartAdminShow: boolean;

    allChartDataSubscriber:Subscription;
    private checkboxes = {};

    constructor(private dashboardService: DashboardService, private cdRef: ChangeDetectorRef, private themeService: ThemeService,
                private advancedFilterService: AdvancedFilterService, private router: Router,  private cookieService: CookieService) {
        this.ucpeStatusChartShow = true;
        this.managerHealthChartShow = true;
        this.vnfStatusChartShow = true;
        this.vnfTypesChartShow = true;
        this.alarmsChartShow = true;
        this.controllerEventChartShow = true;
        this.alertsChartShow = true;
        this.activeOperationsChartShow = true;
        this.controllerStatusChartShow = true;
        this.discoveryChartShow = true;
        this.adminStatusChartShow = true;
        this.controllerDiscoveryChartShow = true;
        this.servicesChartShow = false;
        this.servicesChartAdminShow = true;
        this.checkboxes = {
          servicesChart: this.servicesChartShow,
          servicesAdminChart: this.servicesChartAdminShow,
          ucpeStatusChart: this.ucpeStatusChartShow,
          edgeAdminStatusChart: this.adminStatusChartShow,
          managerHealthChart: this.managerHealthChartShow,
          vnfStatusChart: this.vnfStatusChartShow,
          vnfTypesChart: this.vnfTypesChartShow,
          alarmsChart: this.alarmsChartShow,
          activeOperations: this.activeOperationsChartShow,
          controllerEventChart: this.controllerEventChartShow,
          kubernetesServicesChart: this.kubernetesServicesChartShow,
          osServicesChart: this.osServicesChartShow,
          alertsChart: this.alertsChartShow,
          controllerStatusChart: this.controllerStatusChartShow,
          controllerDiscoveryChart: this.controllerDiscoveryChartShow,
          discoveryChart: this.discoveryChartShow
        }
    }
    readonly checkboxesShow = {
      servicesChart: 'servicesChartShow',
      servicesAdminChart: 'servicesChartAdminShow',
      ucpeStatusChart: 'ucpeStatusChartShow',
      edgeAdminStatusChart: 'adminStatusChartShow',
      managerHealthChart: 'managerHealthChartShow',
      vnfStatusChart: 'vnfStatusChartShow',
      vnfTypesChart: 'vnfTypesChartShow',
      alarmsChart: 'alarmsChartShow',
      activeOperations: 'activeOperationsChartShow',
      controllerEventChart: 'controllerEventChartShow',
      kubernetesServicesChart: 'kubernetesServicesChartShow',
      osServicesChart: 'osServicesChartShow',
      alertsChart: 'alertsChartShow',
      controllerStatusChart: 'controllerStatusChartShow',
      controllerDiscoveryChart: 'controllerDiscoveryChartShow',
      discoveryChart: 'discoveryChartShow'
    }

    ngOnDestroy(): void {
        this.allChartDataSubscriber?.unsubscribe();
    }

    ngOnInit(): void {
        this.themeService.themeChanged.subscribe(() => {
            this.cdRef.detectChanges();
        });
        this.dashboardService.loadUcpeChartData().subscribe((result) => {
            if (result) {
                this.ucpeChartData$ = result;
            }
        });

        this.dashboardService.loadControllerChartData().subscribe((result) => {
            if (result) {
                this.controllerChartData$ = result;
            }
        });

        this.dashboardService.loadAlarmChartData().subscribe((result) => {
            if (result) {
                this.alarmCharData$ = result;
            }
        });

        this.dashboardService.loadContrlActiveEventsChartData().subscribe((result) => {
            if (result) {
                this.activeEventCharData$ = result;
            }
        });

        this.dashboardService.loadK8sServiceEventsChartData().subscribe((result) => {
            if (result) {
                this.K8sServiceEventCharData = result;
            }
        });

        this.dashboardService.loadOsServiceEventsChartData().subscribe((result) => {
            if (result) {
                this.osServiceEventCharData = result;
            }
        });


        this.dashboardService.loadActiveOpersChartData().subscribe((result) => {
            if (result) {
                this.activeOperationsCharData$ = result;
            }
        });

        this.dashboardService.loadAlertChartData().subscribe((result) => {
            if (result) {
                this.alertsCharData$ = result;
            }
        });

        this.dashboardService.loadDiscoveryChartData().subscribe((result) => {
            if (result) {
                this.discoveryChartData$ = result;
            }
        });

        this.dashboardService.loadVnfChartData().subscribe((result) => {
            if (result) {
                this.vnfTypesChartData$ = result;
            }
        });

        this.dashboardService.loadServiceChartData().subscribe((result) => {
            if (result) {
                this.servicesTypesChartData$ = result;
            }
        });

        this.dashboardService.loadServiceAdminChartData().subscribe((result) => {
            if (result) {
                this.servicesAdminTypesChartData$ = result;
            }
        });

        this.dashboardService.loadControllerDiscoveryChartData().subscribe((result) => {
            if (result) {
                this.controllerDiscoveryChartData$ = result;
            }
        });

        this.refreshAdminStatusChartData();

        this.allChartDataSubscriber = interval(10000).subscribe(() => {
            this.dashboardService.loadUcpeChartData().subscribe((result) => {
                if (result) {
                    this.ucpeChartData$ = result;
                }
            });

            this.dashboardService.loadControllerChartData().subscribe((result) => {
                if (result) {
                    this.controllerChartData$ = result;
                }
            });

            this.dashboardService.loadAlarmChartData().subscribe((result) => {
                if (result) {
                    this.alarmCharData$ = result;
                }
            });

            this.dashboardService.loadActiveOpersChartData().subscribe((result) => {
                if (result) {
                    this.activeOperationsCharData$ = result;
                }
            });

            this.dashboardService.loadContrlActiveEventsChartData().subscribe((result) => {
                if (result) {
                    this.activeEventCharData$ = result;
                }
            });

            this.dashboardService.loadAlertChartData().subscribe((result) => {
                if (result) {
                    this.alertsCharData$ = result;
                }
            });

            this.dashboardService.loadDiscoveryChartData().subscribe((result) => {
                if (result) {
                    this.discoveryChartData$ = result;
                }
            });

            this.dashboardService.loadVnfChartData().subscribe((result) => {
                if (result) {
                    this.vnfTypesChartData$ = result;
                }
            });

            this.dashboardService.loadServiceChartData().subscribe((result) => {
                if (result) {
                    this.servicesTypesChartData$ = result;
                }
            });

            this.dashboardService.loadServiceAdminChartData().subscribe((result) => {
                if (result) {
                    this.servicesAdminTypesChartData$ = result;
                }
            });

            this.refreshAdminStatusChartData();

            this.dashboardService.loadControllerDiscoveryChartData().subscribe((result) => {
                if (result) {
                    this.controllerDiscoveryChartData$ = result;
                }
            });
        });
      this.advancedFilterService.getDashboardFilter(this.filterLocation).subscribe((res) => {
        if (!res.data[0]){
          return
        }
        this[this.checkboxesShow.servicesChart] = res.data[0].data.checkboxes.servicesChart;
        this.customizeBarComponent.servicesChart = res.data[0].data.checkboxes.servicesChart;
        (this.checkboxes as any).servicesChart = res.data[0].data.checkboxes.servicesChart;
       Object.keys(res.data[0].data.checkboxes)
          .filter(key => !res.data[0].data.checkboxes[key]).forEach(x => {
          this[this.checkboxesShow[x]] = false;
          this.customizeBarComponent[x] = false;
          this.checkboxes[x] = false;
        });
      });
    }

    refreshAdminStatusChartData() {
        this.dashboardService.loadAdminStatusChartData().subscribe((result) => {
            if (result) {
                this.adminStatusChartData$ = result;
            }
        });
    }

  onChangeChartView(event: any) {
    const state = event[0];
    const charName = event[1];

    switch (charName) {
      case 'servicesChart':
        this.servicesChartShow = state;
        break;
      case 'servicesAdminChart':
        this.servicesChartAdminShow = state;
        break;
      case 'ucpeStatusChart':
        this.ucpeStatusChartShow = state;
        break;
      case 'edgeAdminStatusChart':
        this.adminStatusChartShow = state;
        break;
      case 'managerHealthChart':
        this.managerHealthChartShow = state;
        break;
      case 'vnfStatusChart':
        this.vnfStatusChartShow = state;
        break;
      case 'vnfTypesChart':
        this.vnfTypesChartShow = state;
        break;
      case 'alarmsChart':
        this.alarmsChartShow = state;
        break;
      case 'activeOperations':
        this.activeOperationsChartShow = state;
        break;
      case 'controllerEventChart':
        this.controllerEventChartShow = state;
        break;
      case 'kubernetesServicesChart':
        this.kubernetesServicesChartShow = state;
        break;
      case 'osServicesChart':
        this.osServicesChartShow = state;
        break;
      case 'alertsChart':
        this.alertsChartShow = state;
        break;
      case 'controllerStatusChart':
        this.controllerStatusChartShow = state;
        break;
      case 'controllerDiscoveryChart':
        this.controllerDiscoveryChartShow = state;
        break;

      case 'discoveryChart':
        this.discoveryChartShow = state;
        break;
    }

    this.checkboxes[event[1]] = event[0];

    const body = {
      data: { checkboxes: this.checkboxes },
      name: 'Dashboard',
      type: this.filterLocation,
      user_id: this.loggedInUserID
    };

    this.advancedFilterService.saveFilter(this.filterLocation, body).subscribe();
  }
}
