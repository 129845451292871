import { RouterModule, Routes } from "@angular/router";

import { EntryComponent } from "./components/entry/entry.component";
import { AuthGuard } from "./components/guards/auth.guard";
import { DeviceGuard } from "./components/guards/device.guard";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { MobileEntryComponent } from "./mobile/components/mobile-entry/entry-mobile.component";
import { DashboardMobileComponent } from "./mobile/components/pages/dashboard/dashboard-mobile.component";

const routes: Routes = [
    { path: "", redirectTo: "Login", pathMatch: "full" },
    { path: "Login", component: LoginComponent },
    {
        path: "",
        component: EntryComponent,
        canActivate: [AuthGuard],
        children: [

            {
                path: "Dashboard",
                component: DashboardComponent,
                canActivate: [DeviceGuard],
                data: { breadcrumb: { skip: true } },
                children: []
            },
            {
                path: "Edge Management",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                children: [
                    {
                        path: "EdgeDevices",
                        data: { breadcrumb: "Devices" },
                        loadChildren: () => import("./modules/EdgeManagement/Devices/devices.module").then((m) => m.DevicesModule)
                    }, {
                        path: "Operators",
                        loadChildren: () => import("./modules/EdgeManagement/operators/operators.module").then((m) => m.OperatorsModule)
                    }, {
                        path: "Customers",
                        loadChildren: () => import("./modules/EdgeManagement/customers/customers.module").then((m) => m.CustomersModule)
                    }, {
                        path: "Services",
                        loadChildren: () => import("./modules/EdgeManagement/services/services.module").then((m) => m.ServicesModule)
                    }, {
                        path: "Sites",
                        loadChildren: () => import("./modules/EdgeManagement/sites/sites.module").then((m) => m.SitesModule)
                    }, {
                        path: "HWTypes",
                        data: { breadcrumb: "Hardware Types" },
                        loadChildren: () => import("./modules/EdgeManagement/hwtypes/hwtypes.module").then((m) => m.HwtypesModule)
                    }, {
                        path: "EdgeTemplates",
                        data: { breadcrumb: "Templates" },
                        loadChildren: () => import("./modules/EdgeManagement/edge-templates/edge-templates.module").then((m) => m.EdgeTemplatesModule)
                    },

                ]
            },
            {
                path: "Notifications",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/Notifications/notifications.module").then((m) => m.NotificationsModule)
            },
            {
                path: "Administration",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/Administration/administration.module").then((m) => m.AdministrationModule)
            },
            {
                path: "Application Management",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/ApplicationManagement/application-management.module").then((m) => m.ApplicationManagementModule)
            },
            {
                path: "Kubernetes Service Management",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/ServiceManagement/service-management.module").then((m) => m.ServiceManagementModule)
            },
            {
                path: "Openstack Service Management",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/OpenstackServiceManagement/openstack-service-mng.module").then((m) => m.OpenstackServiceMngModule)
            },
            {
                path: "License Management",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/licenses/licenses.module").then((m) => m.LicensesModule)
            },
            {
                path: "Controller Management",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/ControllerManagement/controller-management.module").then((m) => m.ControllerManagementModule)
            },
            {
                path: "VPN Management",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/VPNManagement/vpn-management.module").then((m) => m.VpnManagementModule)
            },
            {
                path: "Discovery",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./modules/Discovery/discovery.module").then((m) => m.DiscoveryModule)
            }
        ],
    }, {
        path: "mobile",
        component: MobileEntryComponent,
        children: [
            {
                path: "DashboardMobile",
                canActivate: [AuthGuard],
                component: DashboardMobileComponent,
                data: { breadcrumb: { skip: true } },
                children: []
            },
            {
                path: "Notifications",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: { breadcrumb: { disable: true } },
                loadChildren: () => import("./mobile/Notifications/notifications-mobile.module").then((m) => m.NotificationsMobileModule)
            }
        ]
    }
];

export const routing = RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: "reload",
    canceledNavigationResolution: 'computed',
    paramsInheritanceStrategy: 'emptyOnly',
    urlUpdateStrategy: 'deferred',
});