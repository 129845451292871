import { BasicEdgeInterface } from "./BasicEdgeInterface";

export class OvpnCfg {
    constructor() {
        this['concentrator-endpoint'] = 'concentrator';
        this['concentrator-port'] = '1194';
    }
    'concentrator-endpoint': string;
    'concentrator-port': string;
}

export class Vpn implements BasicEdgeInterface {
    constructor() {
        this.management = true;
        this.enable = false;
        this.type = 'openvpn';
        this.name = 'tun0';
        this['ovpn-cfg'] = new OvpnCfg();
    }
    management: boolean;
    enable: boolean;
    type: string;
    name: string;
    'ovpn-cfg': OvpnCfg;
}