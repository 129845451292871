import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'telco-alert-dialog-guard',
  templateUrl: './alert-dialog-guard.component.html',
  styleUrl: './alert-dialog-guard.component.scss'
})
export class AlertDialogGuardComponent {
  message: string = ""
  alert_type: string = ""
  confirmButtonText = ""
  cancelButtonText = ""
  conditionText = ""

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertDialogGuardComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.alert_type = data.alert_type || this.alert_type;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        if (data.buttonText.confirm) {
          this.confirmButtonText = data.buttonText.confirm
        }
        
        if (data.buttonText.cancel) {
          this.cancelButtonText = data.buttonText.cancel
        }

        if (data.timeout != undefined) {
          setInterval(() => {
            this.dialogRef.close(true)
          }, data.timeout)
        }
      }
    }

    this.dialogRef.afterClosed().subscribe()
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
