import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TokenInfo } from 'src/app/models/interfaces/tokenInfo';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ResourceService } from 'src/app/services/resource.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-dropdown',
  templateUrl: './login-dropdown.component.html',
  styleUrls: ['./login-dropdown.component.scss']
})
export class LoginDropdownComponent implements OnInit {
  currentTokenInfo: TokenInfo;
  version = '';
  customer = '';
  operator = '';

  constructor(private cookieService: CookieService, private authenticationService: AuthenticationService,
    private userService: UserService, private resourceService: ResourceService) {
      this.currentTokenInfo = new TokenInfo();
  }

  ngOnInit(): void {
    const currentToken = this.cookieService.get('currentToken');

    if (this.cookieService.get('customer_name') != '') {
      this.customer = this.cookieService.get('customer_name')
    }

    if (this.cookieService.get('operator_name')!=''){
      this.operator = this.cookieService.get('operator_name')
    }

    if (currentToken != '') {
      this.userService.getTokenInfo().subscribe(tokenInfo => {
        if (tokenInfo != undefined) {
          this.currentTokenInfo = tokenInfo;
        }
    })}

    if (this.resourceService.isResourcesLoaded) {
      this.version = this.resourceService.getVersion();
    }
  }

  logout(): void {
    this.authenticationService.logout()
  }
}