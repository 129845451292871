<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer mode="side" position="start">
    <mat-nav-list *ngFor="let item of items">
      <!-- Main Menu -->
      <mat-list-item (click)="item.children ? item.showSubmenu = !item.showSubmenu : onItemSelected()" [routerLink]="item.routerLink ? [item.routerLink] : []">
        <div style="display: flex">
          <mat-icon class="menu-icons" matListItemIcon aria-hidden="false">{{item.icon}}</mat-icon>
          <span class="menu-name">{{item.name}}</span>
        </div>
      </mat-list-item>
      <!-- Sub Menu -->
      <div class="submenu" *ngIf="item.showSubmenu && item.children">
        <ng-container *ngFor="let child of item.children">
          <a id="childItem" (click)="onItemSelected(child)" mat-list-item [routerLink]="child.routerLink">
            <span>{{child.name}}</span>
          </a>
        </ng-container>
      </div>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>