import {
    Component, EventEmitter, Input, OnInit, Output, ViewChild
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { CookieService } from "ngx-cookie-service";
import { TokenInfo } from "src/app/models/interfaces/tokenInfo";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ResourceService } from "src/app/services/resource.service";
import { UserService } from "src/app/services/user.service";

@Component({
    selector: "telco-login-dropdown-mobile",
    templateUrl: "./login-dropdown-mobile.component.html",
    styleUrls: ["./login-dropdown-mobile.component.scss"]
})
export class MobileLoginDropdownComponent implements OnInit {
    @ViewChild("logindrawer", { static: false }) public drawer: MatSidenav;

    @Input() isOverlayVisible: boolean;
    @Output() loginSidenavStateChange = new EventEmitter<boolean>();

    currentTokenInfo:TokenInfo;
    version:string;
    customer:string = "";
    operator:string = "";

    constructor(private cookieService:CookieService, private authenticationService:AuthenticationService, private userService:UserService, private resourceService:ResourceService) {
        this.currentTokenInfo = new TokenInfo();
    }

    ngOnInit(): void {
        const currentToken: any = this.cookieService.get("currentToken");

        if (this.cookieService.get("customer_name") != "") {
            this.customer = this.cookieService.get("customer_name");
        }

        if (this.cookieService.get("operator_name") != "") {
            this.operator = this.cookieService.get("operator_name");
        }

        if (currentToken != "") {
            this.userService.getTokenInfo().subscribe((tokenInfo) => {
                if (tokenInfo != undefined) {
                    this.currentTokenInfo = tokenInfo;

                    // this.cookieService.set('is_super_user',tokenInfo.is_super_user.toString());
                    // this.cookieService.set('project', tokenInfo.project);
                    // this.cookieService.set('role',tokenInfo.role);
                    // this.cookieService.set('username',tokenInfo.username);
                }
            });
        }
        if (this.resourceService.isResourcesLoaded) {
            this.version = this.resourceService.getVersion();
        }
    }

    toggleSidenav() {
        this.drawer.toggle();
        this.loginSidenavStateChange.emit(this.drawer.opened);
    }

    logout() {
        this.authenticationService.logout();
        this.drawer.close();
        this.loginSidenavStateChange.emit(false);
    }
}
