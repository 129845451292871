import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { save } from "../components/helpers/disable-save.interceptor";
import { Controller } from "../models/controller";
import { ResourceService } from "./resource.service";


@Injectable()
export class ControllerService {
  constructor(private resourceService: ResourceService, private http: HttpClient) {
  }


  getControllers(id_only: boolean, page: number, count: number, order: string, direction: string, filterData: any, k8sImageId?): Observable<any> {
    let requestString = `/controller/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    if (k8sImageId) {
      requestString += `&k8s_image_id=${k8sImageId}`;
    }
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, {
      'id_only': id_only,
      filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData
    });
  }

  getNodeApplicationsIds(filterData: any): Observable<any> {
    const requestString = `/controller/advanced`
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, { 'id_only': true, 'filter': filterData });
  }

  createController(controller: Controller): Observable<any> {
    const context = new HttpContext().set(save, true);
    return this.http.put(this.resourceService.getApiUrl() + '/controller', controller, { context })
  }

  getControllersByProjectId(projectId: number): Observable<Controller> {
    return this.http.get<Controller>(this.resourceService.getApiUrl() + `/controller?project_id=${projectId}`);
  }

  updateController(controllerId: number, controller: Controller) {
    const context = new HttpContext().set(save, true);
    return this.http.post(this.resourceService.getApiUrl() + '/controller/' + controllerId, controller, { context });
  }

  deleteController(controllerId: number): Observable<any> {
    return this.http.delete(this.resourceService.getApiUrl() + '/controller/' + controllerId);
  }

  deleteControllers(controllerIds: number[]): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + '/controller/advanced', { "delete": controllerIds});
  }

  getController(controllerId: number): Observable<Controller> {
    return this.http.get<Controller>(this.resourceService.getApiUrl() + '/controller/' + controllerId);
  }

  //discovery operations
  getControllerAllStatuses(): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + '/status/controller');
  }

  getControllerDiscoveredStatuses(): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + '/status/controller/discover');
  }

  getControllerDiscoveredStatusesAdvanced(id_only: boolean, page: number, count: number, order: string, direction: string, filterData: any): Observable<any> {
    const requestString = `/status/controller/discover/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    return this.http.post<Controller[]>(this.resourceService.getApiUrl() + requestString, { 'id_only': id_only, filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }

  addDiscoveredControllers(hostname: string[], customer_id: number): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/status/controller/discover/approve`, {
      "hostname": hostname,
      "customer_id": customer_id
    })
  }

  getDiscoveredControllerAdvanced(id_only: boolean = false, filterData: any, page: number, count: number, order: string, direction: string): Observable<any> {
    if (page == undefined || page == 0) page = 1
    if (count == undefined) count = 20;
    if (filterData == undefined) filterData = []
    if (order == undefined) order = 'asc'
    if (direction == undefined) direction = 'uid'
    return this.http.post<any>(this.resourceService.getApiUrl() + `/status/controller/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`, { "id_only": id_only, filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData });
  }

  getControllerByCustomerId(customerId: number, is_local: boolean): Observable<Controller> {
    return this.http.get<Controller>(this.resourceService.getApiUrl() + `/controller?is_local=${is_local}&customer_id=${customerId}`);
  }

  getControllersAdvanced(page: number, count: number, order: string, direction: string, filterData: any[], id_only: boolean, id_list: number[]): Observable<any> {
    const requestString = `/controller/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    return this.http.post<any>(this.resourceService.getApiUrl() + requestString, {
      'id_only': id_only,
      'id_list': id_list,
      'filter': filterData
    });
  }

  blacklistManageControllers(is_ignored: boolean, hostnames: string[]): Observable<any> {
    return this.http.post<any>(this.resourceService.getApiUrl() + `/status/controller/discover/blacklist`, {
      "is_ignored": is_ignored,
      "hostname": hostnames
    })
  }
}
