import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, filter, mergeMap, retryWhen, scan } from 'rxjs/operators';
import { AlertNotificationService } from 'services/alertNotification.service';
import { AppConfigService } from 'services/app-config.service';
import { UtilsService } from 'services/utils.service';
import { SKIP_STATUS } from './status.interceptor';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  readonly methods = { DELETE: 'delete', POST: 'post', GET: 'get', PUT: 'put' };

  constructor(
    private alertNotificationService: AlertNotificationService,
    private cookieService: CookieService,
    private http: HttpClient,
    private sse: UtilsService,
    private appConfigService: AppConfigService,
    private router: Router) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req)
      .pipe(
        catchError((err: any) => {
          const scip_error = req.context.get(SKIP_STATUS);

          switch (err.status) {
            case 401:
              if (err.error == 'Invalid token!') {
                this.cookieService.deleteAll();
                this.appConfigService.tokenExpired = true;
                this.router.navigate([ 'Login' ]).then(() => window.location.reload());
              } else {
                this.alertNotificationService.alertError(err.error);
                this.cookieService.deleteAll();
              }
              break;

            case 403:
              //TODO: workaround when waiting for Blob, find way to return real responce
              this.alertNotificationService.alertError(
                err.error == null ? 'Could not perform the current command. The request timed out.' :
                  err.error instanceof Blob ? 'Remote image can’t be exported.' :
                    err.error
              );
              break;

            // case 404: //not found
            //     break;

            case 409:
              return this.alertNotificationService.warningAlert(`${err.error}\n \n Do you wish to proceed?`).pipe(
                filter(res => res?.result == 'confirm'),
                mergeMap(() => {
                  this.sse.forceRefreshTable.next(true);
                  const request: HttpRequest<any> = err.url.includes('?') ? req.clone({ url: `${err.url}&force=true` }) : req.clone({ url: `${err.url}?force=true` });
                  return next.handle(request).pipe(
                    catchError(error => {
                      this.defaultErrorHandler(error);
                      return throwError(error);
                    })
                  );
                })
              );

            // return EMPTY;
            case 503:
              return next.handle(req).pipe(
                retryWhen(errors =>
                  errors.pipe(
                    delay(1000),  // Delay retry by 1 second
                    scan((retryCount, err) => {
                      if (retryCount >= 1) {  // On the 2nd retry attempt (0-indexed)
                        this.alertNotificationService.alertError(err.error);
                        throw err;  // This will break out of the retryWhen
                      }
                      return retryCount + 1;
                    }, 0)
                  )
                )
              );

            default:
              if (scip_error) {
                return throwError(err);
              }
              if (err.status.toString().startsWith('4') || err.status == 500) {
                this.defaultErrorHandler(err);
              } else {
                this.alertNotificationService.alertError('Error Occurred with Connection');
                this.router.navigate([ 'Login' ]);
              }
              break;
          }
          return throwError(err);
        })
      );
  }

  defaultErrorHandler(err: any) {
    const strError =  err.error instanceof Blob ? 'Image can’t be exported.' : typeof err.error == 'object' ? JSON.stringify(err.error) : err.error;
    if (strError.slice(1, -1) == 'operator_id') {
      this.alertNotificationService.alertError('Operator should be configured if there is Customer configured.');
      return;
    }
    if (err.error.detail != undefined) {
      this.alertNotificationService.alertError(String(err.error.detail).replace(/<[^>]*>/g, ''));
    } else {
      this.alertNotificationService.alertError(strError.replace(/<[^>]*>/g, ''));
    }
  }
}
