import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

export const configFactory = (config: AppConfigService) => () => config.loadConfig();

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  config: any;
  tokenExpired: boolean;
  themeConfig: any;
  private renderer: Renderer2;

  constructor(private http: HttpClient,
              private rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }


  loadConfig(): Observable<any> {
    return this.http.get('/assets/config.json').pipe(
      tap((x: any) => {
        if (x.theme) {
          const themeLink = this.renderer.createElement('link');
          this.renderer.setAttribute(themeLink, 'rel', 'stylesheet');
          this.renderer.setAttribute(themeLink, 'href', `assets/theme/${x.theme}/${x.theme}.css`);
          this.renderer.appendChild(this.document.head, themeLink);
          const favicon: HTMLLinkElement = this.document.querySelector('link[rel="icon"]');
          this.renderer.setAttribute(favicon, 'href', `assets/theme/${x.theme}/favicon.ico`);
        }
      }),
      tap(data => this.config = data),
      filter(() => this.config.theme),
      switchMap(configData => this.http.get(`/assets/theme/${configData.theme}/configTheme.json`).pipe(
        tap((data: any) => {
          this.themeConfig = data;
          const titleText: HTMLTitleElement = this.document.querySelector('title');
          this.renderer.setProperty(titleText, 'textContent', `${data.title || 'Edge'} Management and Orchestration`);
        })))
    );
  }
}