import { Customer, Ipsec, Loopback, Mgmt, Mirroring, Nat, Operator, Pppoe, Service, Site, Vpn } from ".";
import { Controller } from "./controller";
import { BasicEdgeInterface } from "./EdgeModels/BasicEdgeInterface";
import { SmartProtocol, TraceHostCfg } from './EdgeModels/interfaceModel';
import { Lte } from "./EdgeModels/lte";
import { SnmpServer } from "./EdgeModels/snmp";
import { Vxlan } from "./EdgeModels/vxlan";
import { Wifi } from "./EdgeModels/wifi";
import { HWType } from "./HWType";
import { KubernetisConfig, OpenstackConfig } from "./kubernetisConfig";
import { Project } from "./project";

export class interfaceModel implements BasicEdgeInterface {

  constructor(i, bootproto, management) {
    this.name = 'data' + i;
    this.vlan = 0;
    this.type = 'ethernet';
    this.mode = 'virtio';
    this.bootproto = bootproto;
    this["ip-passthrough"] = false;
    this.dns = '';
    this.dns6 = '';
    this.gateway = '';
    this.gateway6 = '';
    this["ipv4-prefix"] = '';
    this["ipv6-prefix"] = '';
    this.management = management;
    this.metric = 100;
    this["trace-host"] = '';
    this["link-state-propagation"] = true;
    this.vswitch = '';
    this.tracehost = new TraceHostCfg();
    this['smart-protocol-passthrough'] = [new SmartProtocol()];
  }

  management: boolean;
  name: string;
  vlan: number;
  bootproto: string;
  "ip-passthrough": boolean;
  mode: string;
  dns: string;
  dns6: string;
  "ipv4-prefix": string;
  "ipv6-prefix": string;
  type: string;
  gateway: string;
  gateway6: string;
  metric: number;
  "trace-host": string;
  "link-state-propagation": boolean;
  vswitch: string;
  tracehost: TraceHostCfg;
  'smart-protocol-passthrough': SmartProtocol[]
}

export class DHCPRelay {
  dhcp_relay_interface: string
  dhcp_relay_server: string
  enabled: boolean;
  link_selection_sub_option: string;
  public constructor() {
    this.dhcp_relay_interface = '';
    this.dhcp_relay_server = '';
    this.enabled = false;
    this.link_selection_sub_option = '';

  }
}

export class DHCPServer {
  default_lease_time: number;
  dhcp_bind_interface: string
  dhcp_range: string
  enabled: boolean
  max_lease_time: number
  router: string
  domain_name_servers: string;
  public constructor() {
    this.default_lease_time = 3600;
    this.dhcp_bind_interface = '';
    this.dhcp_range = '';
    this.enabled = false;
    this.max_lease_time = 9000;
    this.router = '';
  }
}

export class SyslogModel {
  constructor(enable: boolean, sev: string, fac: string, ip: string, port: number, id: number) {
    this.enable = enable;
    this.severity = sev;
    this.facility = fac;
    this['ip-address'] = ip;
    this.port = port;
    this.id = id;
  }
  enable: boolean;
  severity: string;
  facility: string;
  'ip-address': string;
  port: number;
  id: number;
}

export class Homecalling {
  enabled: boolean;
  timeout: number;
  public constructor() {
    this.enabled = true;
    this.timeout = 600;
  }
}

export class ConfigServer {
  server: string;
  public constructor() {
    this.server = '';
    //TODO FIND WAY TO GET MANAGER HOSTNAME
  }
}

export class VirtualRouter {
  id: number;
  priority: number;
  interface: string;
  peer: string;
  vip: string;
  enable: boolean;
}

export class VrrpConfig {
  'virtual-routers': VirtualRouter[] = new Array<VirtualRouter>();
}

export class EthernetInterface implements BasicEdgeInterface {
  bootproto: string
  dns: string
  dns6: string
  gateway: string
  gateway6: string
  ip_passthrough: boolean
  ipv4_prefix: string
  ipv6_prefix: string
  link_state_propagation: boolean;
  management: boolean;
  metric: number;
  mode: string;
  name: string;
  trace_host: string;
  type: string;

  public constructor() {
    this.bootproto = "none"
    this.dns = ''
    this.dns6 = ''
    this.gateway = ''
    this.gateway6 = ''
    this.ip_passthrough = false;
    this.ipv4_prefix = '';
    this.ipv6_prefix = '';
    this.link_state_propagation = true;
    this.management = false;
    this.metric = 100;
    this.mode = "virtio";
    this.name = "data1";
    this.trace_host = '';
    this.type = "ethernet";
  }
}

export class NatInterface implements BasicEdgeInterface {
  enable: boolean
  ipv4_prefix: string
  ipv6_prefix: string
  name: string
  port_forwarding_all: boolean
  type: string
  wan_interface: []

  public constructor() {
    this.enable = false;
    this.ipv4_prefix = ""
    this.ipv6_prefix = ""
    this.name = "nat0"
    this.port_forwarding_all = true;
    this.type = "nat"
    this.wan_interface = []
  }
}

export class OvpnConfig {
  'concentrator-endpoint': string;
  'concentrator-port': string;
  public constructor() {
    this['concentrator-endpoint'] = "openvpn-concentrator"
    this['concentrator-port'] = '11944'
  }
}



export class Capabilities {
  "compute-nova": string
  "native-applications": string
  "worker-kubernetes": string
  public constructor() {
    this["compute-nova"] = "enabled"
    this["native-applications"] = "disabled"
    this["worker-kubernetes"] = "disabled"
  }
}

export class System {
  capabilities: Capabilities;
  config_server: Array<ConfigServer>;
  customer: string;
  homecalling: Homecalling = new Homecalling();
  hostname: string;
  network_mode: string;
  operator: string;
  project: string;
  service: string;
  site: string;

  public constructor() {
    this.capabilities = new Capabilities();
    this.config_server = new Array<ConfigServer>(new ConfigServer());
    this.customer = ''
    this.homecalling = new Homecalling();
    this.hostname = ''
    this.network_mode = 'vpp'
    this.operator = ''
    this.project = ''
    this.service = ''
    this.site = ''
  }
}

export class Server {
  name: string;
  constructor() {
    this.name = "time.nist.gov"
  }
}

export class NTP {
  //server:Server[]
  server: string;
  timezone: string;

  constructor() {
    //this.server= [new Server()]         //new Array<Server>();
    this.server = 'time.nist.gov'
    this.timezone = "Europe/Sofia"
  }
}

export enum Protocol {
  "ftp",
  "sftp"
}

export class BGPDConfig {
  "config-server": string;
  filename: string;
  "hash-sum": string;
  password: string;
  protocol: string;
  username: string;

  constructor(username: string, password: string) {

    this["config-server"] = '';
    this.filename = '';
    this["hash-sum"] = '';
    this.password = password;
    this.protocol = 'https';
    this.username = username;
  }
}

export class OspfdConfig {
  "config-server": string;
  filename: string;
  "hash-sum": string;
  password: string;
  protocol: string;
  username: string;
  constructor(username: string, password: string) {
    this["config-server"] = '';
    this.filename = '';
    this["hash-sum"] = '';
    this.password = password;
    this.protocol = 'https';
    this.username = username;
  }
}

export class Ospfd6Config {
  "config-server": string;
  filename: string;
  "hash-sum": string;
  password: string;
  protocol: string;
  username: string;
  constructor(username: string, password: string) {
    this["config-server"] = '';
    this.filename = '';
    this["hash-sum"] = '';
    this.password = password;
    this.protocol = 'https';
    this.username = username;
  }
}

export class ZebradConfig {
  "config-server": string;
  filename: string;
  "hash-sum": string;
  password: string;
  protocol: string;
  username: string;
  constructor(username: string, password: string) {
    this["config-server"] = '';
    this.filename = '';
    this["hash-sum"] = '';
    this.password = password;
    this.protocol = 'https';
    this.username = username;
  }
}


export class Quagga {
  server: string;
  protocol: string;
  "bgpd-config": BGPDConfig;
  "ospf6d-config": Ospfd6Config;
  "ospfd-config": OspfdConfig;
  'zebrad-config': ZebradConfig;
  enabled: boolean;
  username: string;
  password: string;

  quagga_zebrad: File;
  quagga_bgpd: File;
  quagga_ospfd6: File;
  quagga_ospfd: File;

  constructor() {
    this.server = ''
    this.protocol = ''
    this.username = 'anonymous'
    this.password = ''
    this["bgpd-config"] = new BGPDConfig(this.username, this.password);
    this["ospf6d-config"] = new Ospfd6Config(this.username, this.password);
    this["ospfd-config"] = new OspfdConfig(this.username, this.password);
    this["zebrad-config"] = new ZebradConfig(this.username, this.password);
    this.enabled = false;
  }
}


export class Upgrade {

  enabled: boolean;
  'target-version': string;
  'repo-server': string;
  // RepoServer[];
  'time-window': string;

  constructor() {
    this.enabled = false;
    this["repo-server"] = 'http://nfvt-repo'
    // this["repo-server"] = [];
    // this["repo-server"].push(new RepoServer());
    this["time-window"] = "anytime";
    this["target-version"] = 'loremipsum'
  }
}

export class RepoServer {
  url: string = "";
}

export enum TimeWindow {
  ANYTIME,
  DAY,
  WEEK,
  DATE
}


export class Authentication {
  "auth-type": string
  "tacacs-key": string
  "tacacs-server": string
  "radius-key": string
  "radius-server": string
  "radius-port": string
  "radius-timeout": string
  "fallback-auth": string
  "backup-radius-key": string
  "backup-radius-server": string
  "backup-radius-port": string
  "backup-radius-timeout": string

  public constructor() {
    this["auth-type"] = 'local';
    this["tacacs-key"] = '';
    this["tacacs-server"] = '';
    this["radius-key"] = '';
    this["radius-server"] = '';
    this["radius-port"] = '';
    this["radius-timeout"] = '';
    this["fallback-auth"] = '';
    this["backup-radius-key"] = '';
    this["backup-radius-server"] = '';
    this["backup-radius-port"] = '';
    this["backup-radius-timeout"] = '';
  }
}

export class WhilistAddress {
  address: string;
}

export class CliWhitelist {
  "access-source-ip": Array<WhilistAddress>
  enabled: boolean
  public constructor() {
    this["access-source-ip"] = Array<WhilistAddress>();
    this.enabled = false;
  }
}


export class Security {

  authentication: Authentication;

  "cli-whitelist": CliWhitelist;

  public constructor() {
    this.authentication = new Authentication()
    this["cli-whitelist"] = new CliWhitelist
  }
}


export class Sessionoptions {

  'hello-timeout': number;

  constructor() {
    this['hello-timeout'] = 600;
  }
}



export class Netconf {
  listen: Listen = new Listen();
  'session-options' = new Sessionoptions();

  constructor() {

  }
}


export class KubrenetesSSH {
  "tcp-server-parameters": {
    "keepalives": {
      "idle-time": 1,
      "max-probes": 10,
      "probe-interval": 5
    },
    "local-address": "0.0.0.0"
    "local-port": 830
  }
}


export class KubrenetesEndpoint {

  name: string;
  ssh = new Ssh();

  constructor() {
    this.name = 'default-ssh';
  }
}


export class KubrenetesNetconf {
  listen: Listen = new Listen();
  constructor() {

  }
}

export class KubrenetesListen {
  'idle-timeout': number = 3600;

  endpoint: Endpoint[];// = [new Endpoint()];

  constructor() {
    this.endpoint = new Array<Endpoint>();
    const endpointEntry = new Endpoint()
    endpointEntry.name = 'all-interfaces'
    endpointEntry.ssh.address = '0.0.0.0'
    endpointEntry.ssh.port = 830
    this.endpoint.push(endpointEntry)
  }
}






export class Listen {
  'idle-timeout': number = 3600;
  'max-sessions': number = 0;

  // list of endpoints is filling by FormGroup. See ucpe-wizard.component.
  endpoint: Endpoint[];// = [new Endpoint()];

  constructor() {
    this.endpoint = new Array<Endpoint>();
    const endpointEntry = new Endpoint()
    endpointEntry.name = 'all-interfaces'
    endpointEntry.ssh.address = '0.0.0.0'
    endpointEntry.ssh.port = 830
    this.endpoint.push(endpointEntry)
  }
}

export class Endpoint {

  name: string;
  ssh = new Ssh();

  constructor() {
    this.name = 'all-interfaces';
  }
}


export class Ssh {
  address: string;
  port: number;

  constructor() {
    this.address = '0.0.0.0';
    this.port = 830;
  }
}


export class Facilities {
  facilities = [{ id: 'all', name: "all" },
  { id: 'auth', name: "auth" },
  { id: 'authpriv', name: "authpriv" },
  { id: 'cron', name: "cron" },
  { id: 'daemon', name: "daemon" },
  { id: 'kern', name: "kern" },
  { id: 'lpr', name: "lpr" },
  { id: 'mail', name: "mail" },
  { id: 'mark', name: "mark" },
  { id: 'news', name: "news" },
  { id: 'syslog', name: "syslog" },
  { id: 'user', name: "user" },
  { id: 'uucp', name: "uucp" },
  { id: 'local0', name: "local0" },
  { id: 'local1', name: "local1" },
  { id: 'local2', name: "local2" },
  { id: 'local3', name: "local3" },
  { id: 'local4', name: "local4" },
  { id: 'local5', name: "local5" },
  { id: 'local6', name: "local6" },
  { id: 'local7', name: "local7" }];
}

export class SystemResources {
  'dpdk-reserved-cpus': string;
  'host-oversubscribe-enabled': boolean;
  'host-reserved-cpus': string;
  'host-reserved-memory': number;
  'huge-page-size': number;
  'huge-page-count': number;

  constructor() {
    this['dpdk-reserved-cpus'] = '1';
    this['host-oversubscribe-enabled'] = true;
    this['host-reserved-cpus'] = '0,2';
    this['host-reserved-memory'] = 3584;
    this['huge-page-size'] = 2;
  }
}




export class ConfigForm {
  started: boolean = true;
  name: string = ''
  template_id: number = 0;
  instanceId: number = 0;
  description: string = '';

}

export class AppConfigForm extends ConfigForm {
  appTemplateForm: string = '';
  app_template: string = '';
  user_data: string = '';
}

export class ServiceConfigForm extends ConfigForm {
  params: [] = [];
}

export class EdgeConfig {
  smartProtocolExist: boolean;
  currentStep: number;
  previousStep: number;

  edgeId: number;
  edgeConfigJson: any;

  dhcprelay: DHCPRelay;
  dhcpServer: DHCPServer;
  ntp: NTP;
  quagga: Quagga;
  security: Security;
  upgrade: Upgrade;
  snmp: SnmpServer;
  system: System;
  vpn: Vpn;
  systemResources: SystemResources;

  remoteServes: SyslogModel[] = [];
  netconf: Netconf;

  port_mirrorings: Array<BasicEdgeInterface> = new Array<BasicEdgeInterface>()

  operatorObj: Operator;
  customerObj: Customer;
  serviceObj: Service;
  siteObj: Site;
  controller_kuber: Controller;
  //edge definition
  hostname: string;
  hwType: HWType;
  // hwtype: string;
  // hwtype_id:number;
  project: Project;

  site_id: number;
  uid: string;
  // project_id: number;
  // project: string;

  site: string;
  customer_id: number
  customer: string;
  service_id: number
  service: string;
  operator_id: number
  operator: string;
  description: string;
  staticVpnIP: string;
  license_pack: string;

  EthernetInterfaces: Array<EthernetInterface>;
  EdgeInterfaces: Array<BasicEdgeInterface>;
  selectedInterface: string;

  isSystemResourceChanged: boolean;
  isSystemConfiguration: boolean;
  isLocalInterfaceChanged: boolean;

  manager_hostname: string;
  // validationError:boolean;
  isKubernetisDevice: boolean;
  isNativeApplicationEnabled: boolean;
  isOpenstackDevice: boolean;

  isLegacyDevice: boolean;

  kubernetisConfig: KubernetisConfig;
  openstackConfig: OpenstackConfig;

  name?: string;
  serviceConfig: ServiceConfigForm;
  AppConfigForm: AppConfigForm;
  vrrp: object;
  services: ServiceConfigForm[];
  applications: AppConfigForm[];
  username: string = '';
  password: string = '';
  sshPort: number = 22222;
  shemaVersion: number = 69;


  public constructor() {
    this.currentStep = 0
    this.previousStep = 0
    this.dhcprelay = new DHCPRelay();
    this.dhcpServer = new DHCPServer();
    this.ntp = new NTP();
    this.quagga = new Quagga();
    this.security = new Security();
    this.upgrade = new Upgrade();
    this.system = new System();
    this.snmp = new SnmpServer();
    this.vpn = new Vpn();
    this.systemResources = new SystemResources();
    this.isSystemResourceChanged = false;
    this.isSystemConfiguration = false;
    this.isLocalInterfaceChanged = false;

    this.remoteServes[0] = new SyslogModel(false, "all", "all", "", 514, 1);
    this.remoteServes[1] = new SyslogModel(false, "all", "all", "", 514, 2);
    this.remoteServes[2] = new SyslogModel(false, "all", "all", "", 514, 3);
    this.remoteServes[3] = new SyslogModel(false, "all", "all", "", 514, 4);
    this.remoteServes[4] = new SyslogModel(false, "all", "all", "", 514, 5);

    this.netconf = new Netconf();

    this.EthernetInterfaces = new Array<EthernetInterface>();
    this.EdgeInterfaces = new Array<BasicEdgeInterface>();
    this.hwType = new HWType();
    // this.hwtype_id=0;
    // this. hwtype=''
    this.selectedInterface = '';
    // this.project_id=1;
    this.project = new Project()
    this.site = ''
    this.customer = ''
    this.service = ''
    this.operator = ''
    this.description = ''
    this.staticVpnIP = ''
    this.manager_hostname = ''
    // this.validationError=false;
    this.isLegacyDevice = false;
    this.isKubernetisDevice = false;
    this.isOpenstackDevice = false;
    this.isNativeApplicationEnabled = false;
    this.controller_kuber = new Controller();
    this.kubernetisConfig = new KubernetisConfig();
    this.openstackConfig = new OpenstackConfig();

    this.serviceConfig = new ServiceConfigForm();
    this.vrrp = new Object();
    this.services = new Array<any>();
    this.applications = new Array<any>();
  }



  getNewRepoNetconfig() {
    const newtConf = this.netconf.listen.endpoint.map(endpoint => {
      const newEndpoint = {
        "name": endpoint.name || "default-ssh",
        "ssh": {
          "tcp-server-parameters": {
            "keepalives": {
              "idle-time": 1,
              "max-probes": 10,
              "probe-interval": 5
            },
            "local-address": endpoint.ssh.address,
            "local-port": endpoint.ssh.port
          }

        }
      }
      return newEndpoint;
    })

    return newtConf
  }

  getAddEdgeJsonConfig() {
    const jsonConfig = {
      "hostname": this.hostname,
      "hwtype": this.hwType.name,
      "hwtype_id": this.hwType.id,
      "manager_config": {

        "ietf-netconf-server:netconf-server": {
          "listen": {
            "endpoint": this.isLegacyDevice ? this.netconf.listen.endpoint : this.getNewRepoNetconfig(),
            "idle-timeout": this.netconf.listen["idle-timeout"],
            "max-sessions": this.isLegacyDevice ? this.netconf.listen["max-sessions"] : undefined
          }
        },
        "ucpe:config": this.getJsonConfig(),
        "ucpe:status": {
          "system:system": {
            "hwtype": this.hwType.name
          }
        }
      }
      ,
      "project_id": this.project.id,
      "site_id": this.siteObj && this.siteObj.id || '',
      "uid": this.uid,
      "device_description": this.description,
      "username": this.isLegacyDevice ? this.username : undefined,
      "password": this.isLegacyDevice ? this.password : undefined,
      "is_legacy": this.isLegacyDevice,
      "vpn_static_address": this.staticVpnIP
      // "version":this.version
    }

    this.isLegacyDevice ? jsonConfig['manager_config']["ietf-netconf-server:netconf-server"]["session-options"] = {
      "hello-timeout": this.netconf["session-options"]["hello-timeout"]
    } : null;

    return jsonConfig;
  }

  //{dpdk-reserved-cpus: '1', host-oversubscribe-enabled: true, host-reserved-cpus: '2', host-reserved-memory: 1792, huge-page-size: 1024}
  getJsonConfig() {
    const ucpeJsonConfig = {
      "dhcprelay:dhcp-relay": { "dhcp-relay-interface": this.dhcprelay.dhcp_relay_interface, "dhcp-relay-server": this.dhcprelay.dhcp_relay_server, enabled: this.dhcprelay.enabled, "link-selection-sub-option": this.dhcprelay.link_selection_sub_option },
      "dhcpserver:dhcp-server": {
        "default-lease-time": this.dhcpServer.default_lease_time, "dhcp-bind-interface": this.dhcpServer.dhcp_bind_interface, "dhcp-range": this.dhcpServer.dhcp_range, "enabled": this.dhcpServer.enabled, "max-lease-time": this.dhcpServer.max_lease_time, "router": this.dhcpServer.router,
        "domain-name-servers": this.dhcpServer.domain_name_servers
      },
      "system-resources:system-resources": {
        "dpdk-reserved-cpus": this.systemResources["dpdk-reserved-cpus"], "host-reserved-cpus": this.systemResources["host-reserved-cpus"], "host-reserved-memory": this.systemResources["host-reserved-memory"], "huge-page-size": this.systemResources["huge-page-size"], "host-oversubscribe-enabled": this.systemResources["host-oversubscribe-enabled"]
      },
      "system:system": {
        "capabilities": this.system.capabilities,
        "config-server": [{ "server": this.system.config_server[0].server }],
        "customer": this.customerObj ? this.customerObj.name : '',
        "homecalling": {
          "enabled": this.system.homecalling.enabled,
          "timeout": this.system.homecalling.timeout
        },
        "hostname": this.hostname,
        "network-mode": this.system.network_mode,
        "operator": this.operatorObj ? this.operatorObj.name : '',
        "project": this.project.name,
        "service": this.serviceObj ? this.serviceObj.name : '',
        "site": this.siteObj ? this.siteObj.name : ''
      },
      "interfaces:interface":
        this.ConcatAllInterface()
      ,
      "ntp:ntp": {
        "server":

          [{ 'name': this.ntp.server }]

        ,
        "timezone": this.ntp.timezone
      },

      "quagga:quagga": {
        "bgpd-config": {
          "config-server": this.quagga["bgpd-config"]["config-server"],
          "filename": '',//this.quagga["bgpd-config"].filename,
          "hash-sum": '', //this.quagga["bgpd-config"]["hash-sum"],
          "password": '', //this.quagga["bgpd-config"].password,
          "protocol": this.quagga["bgpd-config"].protocol,
          "username": this.quagga["bgpd-config"].username
        },
        "enabled": this.quagga.enabled,
        "ospf6d-config": {
          "config-server": this.quagga["ospf6d-config"]["config-server"],
          "filename": '',//this.quagga["ospf6d-config"].filename,
          "hash-sum": '',//this.quagga["ospf6d-config"]["hash-sum"],
          "password": '',//this.quagga["ospf6d-config"].password,
          "protocol": this.quagga["ospf6d-config"].protocol,
          "username": this.quagga["ospf6d-config"].username
        },
        "ospfd-config": {
          "config-server": this.quagga["ospfd-config"]["config-server"],
          "filename": '',// this.quagga["ospfd-config"].filename,
          "hash-sum": '',//this.quagga["ospfd-config"]["hash-sum"],
          "password": '',//this.quagga["ospfd-config"].password,
          "protocol": this.quagga["ospfd-config"].protocol,
          "username": this.quagga["ospfd-config"].username
        },
        "zebrad-config": {
          "config-server": this.quagga["zebrad-config"]["config-server"],
          "filename": this.quagga["zebrad-config"].filename,
          "hash-sum": this.quagga["zebrad-config"]["hash-sum"],
          "password": this.quagga["zebrad-config"].password,
          "protocol": this.quagga["zebrad-config"].protocol,
          "username": this.quagga["zebrad-config"].username
        }


      },
      "security:security": {
        "authentication": {
          "auth-type": this.security.authentication["auth-type"],
          "tacacs-key": this.security.authentication["tacacs-key"],
          "tacacs-server": this.security.authentication["tacacs-server"],
          "radius-key": this.security.authentication['radius-key'],
          "radius-server": this.security.authentication['radius-server'],
          "radius-port": this.security.authentication["radius-port"] == '' && this.isLegacyDevice ? undefined : this.security.authentication["radius-port"]?.toString(),
          "radius-timeout": this.security.authentication["radius-timeout"] == '' && this.isLegacyDevice ? undefined : this.security.authentication["radius-timeout"]?.toString(),
          "fallback-auth": this.security.authentication["fallback-auth"]?.toString(),
          "backup-radius-key": this.security.authentication["backup-radius-key"] == '' && this.isLegacyDevice ? undefined : this.security.authentication["backup-radius-key"],
          "backup-radius-server": this.security.authentication["backup-radius-server"] == '' && this.isLegacyDevice ? undefined : this.security.authentication["backup-radius-server"]?.toString(),
          "backup-radius-port": this.security.authentication["backup-radius-port"] == '' && this.isLegacyDevice ? undefined : this.security.authentication["backup-radius-port"]?.toString(),
          "backup-radius-timeout": this.security.authentication["backup-radius-timeout"] == '' && this.isLegacyDevice ? undefined : this.security.authentication["backup-radius-timeout"]?.toString()
        },
        "cli-whitelist": {
          "access-source-ip": this.security["cli-whitelist"]["access-source-ip"],
          "enabled": this.security['cli-whitelist'].enabled
        }
      },
      "snmp-server:snmp-server": {
        "authentication-failure-trap-enabled": this.snmp["authentication-failure-trap-enabled"],
        "bind-address": this.snmp["bind-address"],
        "enable": this.snmp.enable,
        "port": this.snmp.port,
        "system-contact": this.snmp["system-contact"],
        "system-description": this.snmp["system-description"],
        "system-location": this.snmp["system-location"],
        "system-name": this.snmp["system-name"],
        "target-address": [
          {
            "dst-address": this.snmp["target-address"][0]["dst-address"],
            "dst-port": this.snmp["target-address"][0]["dst-port"],
            "enable": this.snmp["target-address"][0].enable,
            "id": 1,
            "message-model": this.snmp["target-address"][0]["message-model"],
            "retry-count": this.snmp["target-address"][0]["retry-count"],
            "security-level": this.snmp["target-address"][0]["security-level"],
            "security-name": this.snmp["target-address"][0]["security-name"],
            "target-addr-name": this.snmp["target-address"][0]["target-addr-name"],
            "timeout": this.snmp["target-address"][0].timeout,
            "type": this.snmp["target-address"][0].type
          },
          {
            "dst-address": this.snmp["target-address"][1]["dst-address"],
            "dst-port": this.snmp["target-address"][1]["dst-port"],
            "enable": this.snmp["target-address"][1].enable,
            "id": 2,
            "message-model": this.snmp["target-address"][1]["message-model"],
            "retry-count": this.snmp["target-address"][1]["retry-count"],
            "security-level": this.snmp["target-address"][1]["security-level"],
            "security-name": this.snmp["target-address"][1]["security-name"],
            "target-addr-name": this.snmp["target-address"][1]["target-addr-name"],
            "timeout": this.snmp["target-address"][1].timeout,
            "type": this.snmp["target-address"][1].type
          },
          {
            "dst-address": this.snmp["target-address"][2]["dst-address"],
            "dst-port": this.snmp["target-address"][2]["dst-port"],
            "enable": this.snmp["target-address"][2].enable,
            "id": 3,
            "message-model": this.snmp["target-address"][2]["message-model"],
            "retry-count": this.snmp["target-address"][2]["retry-count"],
            "security-level": this.snmp["target-address"][2]["security-level"],
            "security-name": this.snmp["target-address"][2]["security-name"],
            "target-addr-name": this.snmp["target-address"][2]["target-addr-name"],
            "timeout": this.snmp["target-address"][2].timeout,
            "type": this.snmp["target-address"][2].type
          },
          {
            "dst-address": this.snmp["target-address"][3]["dst-address"],
            "dst-port": this.snmp["target-address"][3]["dst-port"],
            "enable": this.snmp["target-address"][3].enable,
            "id": 4,
            "message-model": this.snmp["target-address"][3]["message-model"],
            "retry-count": this.snmp["target-address"][3]["retry-count"],
            "security-level": this.snmp["target-address"][3]["security-level"],
            "security-name": this.snmp["target-address"][3]["security-name"],
            "target-addr-name": this.snmp["target-address"][3]["target-addr-name"],
            "timeout": this.snmp["target-address"][3].timeout,
            "type": this.snmp["target-address"][3].type
          },
          {
            "dst-address": this.snmp["target-address"][4]["dst-address"],
            "dst-port": this.snmp["target-address"][4]["dst-port"],
            "enable": this.snmp["target-address"][4].enable,
            "id": 5,
            "message-model": this.snmp["target-address"][4]["message-model"],
            "retry-count": this.snmp["target-address"][4]["retry-count"],
            "security-level": this.snmp["target-address"][4]["security-level"],
            "security-name": this.snmp["target-address"][4]["security-name"],
            "target-addr-name": this.snmp["target-address"][4]["target-addr-name"],
            "timeout": this.snmp["target-address"][4].timeout,
            "type": this.snmp["target-address"][4].type
          }
        ],
        "users": [
          {
            "auth-password": this.snmp.users[0]["auth-password"],
            "auth-type": this.snmp.users[0]["auth-type"],
            "id": 1,
            "message-model": this.snmp.users[0]["message-model"],
            "notify-view": this.snmp.users[0]["notify-view"],
            "priv-password": this.snmp.users[0]["priv-password"],
            "priv-type": this.snmp.users[0]["priv-type"],
            "read-view": this.snmp.users[0]["read-view"],
            "remote-engine-id": this.snmp.users[0]["remote-engine-id"],
            "security-level": this.snmp.users[0]["security-level"],
            "user-name": this.snmp.users[0]["user-name"],
            "write-view": this.snmp.users[0]["write-view"]
          },
          {
            "auth-password": this.snmp.users[1]["auth-password"],
            "auth-type": this.snmp.users[1]["auth-type"],
            "id": 2,
            "message-model": this.snmp.users[1]["message-model"],
            "notify-view": this.snmp.users[1]["notify-view"],
            "priv-password": this.snmp.users[1]["priv-password"],
            "priv-type": this.snmp.users[1]["priv-type"],
            "read-view": this.snmp.users[1]["read-view"],
            "remote-engine-id": this.snmp.users[1]["remote-engine-id"],
            "security-level": this.snmp.users[1]["security-level"],
            "user-name": this.snmp.users[1]["user-name"],
            "write-view": this.snmp.users[1]["write-view"]
          }
        ],
        "views": [
          {
            "id": 1,
            "name": this.snmp.views[0].name,
            "oid": this.snmp.views[0].oid,
            "type": this.snmp.views[0].type
          },
          {
            "id": 2,
            "name": this.snmp.views[1].name,
            "oid": this.snmp.views[1].oid,
            "type": this.snmp.views[1].type
          }
        ]
      },
      "upgrade:upgrade": {
        "enabled": this.upgrade.enabled,
        "repo-server": [{ "url": this.upgrade["repo-server"] }], //this.upgrade["repo-server"][0].url,
        "target-version": this.upgrade["target-version"],
        "time-window": this.upgrade["time-window"],
        "download-time-window": this.upgrade?.["download-time-window"]
      },
      "syslog:syslog":
      {
        "remote-servers": [
          {
            "enable": this.remoteServes[0].enable,
            "severity": this.remoteServes[0].severity,
            "facility": this.remoteServes[0].facility,
            "ip-address": this.remoteServes[0]["ip-address"],
            "port": this.remoteServes[0].port,
            "id": 1
          },
          {
            "enable": this.remoteServes[1].enable,
            "severity": this.remoteServes[1].severity,
            "facility": this.remoteServes[1].facility,
            "ip-address": this.remoteServes[1]["ip-address"],
            "port": this.remoteServes[1].port,
            "id": 2
          },
          {
            "enable": this.remoteServes[2].enable,
            "severity": this.remoteServes[2].severity,
            "facility": this.remoteServes[2].facility,
            "ip-address": this.remoteServes[2]["ip-address"],
            "port": this.remoteServes[2].port,
            "id": 3
          },
          {
            "enable": this.remoteServes[3].enable,
            "severity": this.remoteServes[3].severity,
            "facility": this.remoteServes[3].facility,
            "ip-address": this.remoteServes[3]["ip-address"],
            "port": this.remoteServes[3].port,
            "id": 4
          },
          {
            "enable": this.remoteServes[4].enable,
            "severity": this.remoteServes[4].severity,
            "facility": this.remoteServes[4].facility,
            "ip-address": this.remoteServes[4]["ip-address"],
            "port": this.remoteServes[4].port,
            "id": 5
          }
        ]
      }
    }

    if (!this.isLegacyDevice && this.shemaVersion > 68) {
      ucpeJsonConfig["security:security"]["ssh-server"] = {
        "listen-port": this.sshPort
      }
    }

    if (this.isLegacyDevice && this.port_mirrorings && this.port_mirrorings.length > 0) {
      ucpeJsonConfig["port-mirroring:port-mirroring"] = this.port_mirrorings;
    } else if (!this.isLegacyDevice) {
      ucpeJsonConfig["port-mirroring:port-mirroring"] = this.port_mirrorings;
    }

    if (this.systemResources["huge-page-count"]) {
      ucpeJsonConfig["system-resources:system-resources"]['huge-page-count'] = this.systemResources["huge-page-count"];
    }

    const openstackConfig = {
      "bind-interface": this.openstackConfig.bindInterface,
      "cbox": {
        "enabled": this.openstackConfig.isCboxEnable
      },
      "controller": this.openstackConfig.hostname,
      "router-speaker": {
        "enabled": this.openstackConfig.routerSpeakerEnabled,
        "speaker-type": this.openstackConfig.speakerType
      },
      "vnf-tunneling": {
        "child-ts-type": this.openstackConfig.childTsType
      }
    }

    if (this.openstackConfig.routerSpeakerEnabled && this.openstackConfig.ipPrefixScope != '') {
      openstackConfig["router-speaker"]["ip-prefix-scope"] = this.openstackConfig.ipPrefixScope;
    }

    if (this.openstackConfig.routerSpeakerEnabled && this.openstackConfig.speakerType == "openvpn") {
      openstackConfig["router-speaker"]["concentrator-vpn"] = { "concentrator-port": this.openstackConfig.openvpnPort, "concentrator": this.openstackConfig.openvpnIP };
    }

    if (this.openstackConfig.routerSpeakerEnabled && this.openstackConfig.speakerType == 'quagga-bgp') {
      openstackConfig["router-speaker"]["quagga-bgp"] = { "host": this.openstackConfig.quaggaHost, "login-password": this.openstackConfig.quaggaLoginPass, "local-as": this.openstackConfig.quaggaLocalAs };
    }


    const kubernetisConfig = {
      "bind-interface": this.kubernetisConfig.bindInterface,
      "cbox": {
        "enabled": this.kubernetisConfig.isCboxEnable
      }
    }

    this.controller_kuber.hostname ? kubernetisConfig["controller"] = this.controller_kuber.hostname : null

    this.isLegacyDevice ? ucpeJsonConfig["openstack:openstack"] = openstackConfig : null;

    !this.isLegacyDevice ? ucpeJsonConfig["kubernetes:kubernetes"] = kubernetisConfig : null;
    !this.isLegacyDevice ? ucpeJsonConfig['vrrp:vrrp'] = this.vrrp : null;

    return ucpeJsonConfig;
  }



  convertEthInterfaceToJson(ethInterfaces: Array<EthernetInterface>) {
    const ethList = new Array<any>();


    ethInterfaces.forEach(ethInterface => {
      ethList.push(
        {
          "bootproto": ethInterface.bootproto,
          "dns": ethInterface.dns,
          "dns6": ethInterface.dns6,
          "gateway": ethInterface.gateway,
          "gateway6": ethInterface.gateway6,
          "ip-passthrough": ethInterface.ip_passthrough,
          "ipv4-prefix": ethInterface.ipv4_prefix,
          "ipv6-prefix": ethInterface.ipv6_prefix,
          "link-state-propagation": ethInterface.link_state_propagation,
          "management": ethInterface.management,
          "metric": ethInterface.metric,
          "mode": ethInterface.mode,
          "name": ethInterface.name,
          "trace-host": ethInterface,
          "type": ethInterface.type
        }
      )
    });
    return ethList;
  }

  convertEdgeInterfaceToJson(edgeInterfaces: Array<BasicEdgeInterface>) {
    const ethList = new Array<any>();
    this.port_mirrorings = []


    edgeInterfaces.forEach(edgeInterface => {


      if (edgeInterface['tracehost'] && edgeInterface['tracehost']['probes'] != undefined) {
        Object.values(edgeInterface['tracehost']['probes']).forEach(value => {
          if (value['min-download'] || value['min-download'] == null || value['min-download'] == '') {
            if ([null, ''].includes(value['min-download'])) delete value['min-download'];
            if (value['min-download'] != null && value['min-download'] != '') {
              value['min-download'] = value['min-download'].toString();
            }
          }
          if (value['min-upload'] || value['min-upload'] == null || value['min-upload'] == '') {
            [null, ''].includes(value['min-upload']) ? delete value['min-upload'] : null;
            if (value['min-upload'] != null && value['min-upload'] != '') {
              value['min-upload'] = value['min-upload'].toString();
            }
          }
          if (value['max-rtt'] || value['max-rtt'] == null || value['max-rtt'] == '') {
            [null, ''].includes(value['max-rtt']) ? delete value['max-rtt'] : null;
          }
        });
      }

      if (edgeInterface.type == 'ethernet' || edgeInterface.type == 'vlan') {
        if (edgeInterface.name == 'mgmt0') {
          this.isLegacyDevice && edgeInterface["ipv4-prefix"] == "" ? delete edgeInterface["ipv4-prefix"] : null;
        }


        const inter = {
          "bootproto": edgeInterface['bootproto'],
          "dns": edgeInterface['dns'],
          "dns6": edgeInterface['dns6'],
          "gateway": edgeInterface['gateway'],
          "gateway6": edgeInterface['gateway6'],
          "ip-passthrough": edgeInterface['ip-passthrough'],
          "enable": edgeInterface['enable'],
          "ipv4-prefix": edgeInterface['ipv4-prefix'],
          "ipv6-prefix": edgeInterface['ipv6-prefix'],
          "link-state-propagation": edgeInterface['link-state-propagation'],
          "management": edgeInterface['management'],
          "metric": edgeInterface['metric'],
          "mode": edgeInterface['mode'],
          "name": edgeInterface.name,
          "trace-host": edgeInterface['trace-host'],
          "tracehost": edgeInterface['tracehost'],
          "vswitch": edgeInterface['vswitch'],
          // "vlan":edgeInterface['vlan'],
          "type": edgeInterface.type
        }
        if (edgeInterface.type == 'vlan') {
          delete inter['link-state-propagation'];
        }
        if (this.smartProtocolExist && edgeInterface['smart-protocol-passthrough']?.[0]?.enable){
          inter['smart-protocol-passthrough'] = edgeInterface['smart-protocol-passthrough']
          if (inter['smart-protocol-passthrough']?.[0]['tracehost-interface']=='undefined'){
            inter['smart-protocol-passthrough'][0]['tracehost-interface'] = ''
          }
        }
        if (edgeInterface['vlan'] && edgeInterface.type !='ethernet') {
          inter['vlan'] = edgeInterface['vlan']
        }
        ethList.push(inter)
      }

      if (edgeInterface.type == 'lte') {
        edgeInterface['tracehost']['probes'] && [null, ''].includes(edgeInterface['tracehost']['probes'][0]['max-rtt']) ? delete edgeInterface['tracehost']['probes'][0]['max-rtt'] : null;
        if (!this.smartProtocolExist){
          delete edgeInterface['smart-protocol-passthrough']
        }
        if (this.smartProtocolExist && !edgeInterface['smart-protocol-passthrough']?.[0].enable){
          delete edgeInterface['smart-protocol-passthrough']
        }
        const interJson = JSON.stringify(edgeInterface)
        const parsed = JSON.parse(interJson);
        ethList.push(parsed)
      }



      if (edgeInterface.type == 'openvpn') {

        const opnConfig = edgeInterface['ovpn-cfg']['concentrator-port'] == '' ?
          {
            "concentrator-endpoint": edgeInterface['ovpn-cfg']['concentrator-endpoint'],
          } : {
            "concentrator-endpoint": edgeInterface['ovpn-cfg']['concentrator-endpoint'],
            "concentrator-port": `${edgeInterface['ovpn-cfg']['concentrator-port']}`
          };

        ethList.push(
          {
            "enable": edgeInterface['enable'],
            "management": edgeInterface['management'],
            "name": edgeInterface['name'],
            "ovpn-cfg": opnConfig,
            "type": edgeInterface['type']
          }
        )
      }

      if (edgeInterface.type == 'mirroring' && edgeInterface.name != 'Mirroring+') {
        delete edgeInterface['mirroringID'];
        delete edgeInterface['type'];
        const interJson = JSON.stringify(edgeInterface);
        const parsed = JSON.parse(interJson);
        this.port_mirrorings.push(parsed)


      }

      if (edgeInterface.type == 'vxlan' && edgeInterface.name != 'VxLAN+') {
        delete edgeInterface['vxlanID'];

        // if(edgeInterface['local']['vtep-addr'] == undefined || (edgeInterface['local']['vtep-addr']!=undefined && edgeInterface['local']['vtep-addr'] === '')){
        //   delete edgeInterface['local']
        // }

        if (edgeInterface['remote']["vtep-addrs"]?.length == 0) {
          delete edgeInterface['remote']["vtep-addrs"]
        }

        // if(edgeInterface['dns6']==''){
        //   delete edgeInterface['dns6']
        // }

        const interJson = JSON.stringify(edgeInterface)
        const parsed = JSON.parse(interJson);

        ethList.push(parsed)
      }
      if (edgeInterface.type == 'pppoe' && edgeInterface.name != 'PPPoE+') {
        if (!this.smartProtocolExist){
          delete edgeInterface['smart-protocol-passthrough']
        }
        if (this.smartProtocolExist && !edgeInterface['smart-protocol-passthrough']?.[0].enable){
          delete edgeInterface['smart-protocol-passthrough']
        }
        edgeInterface['tracehost']['probes'] && [null, ''].includes(edgeInterface['tracehost']['probes'][0]['max-rtt']) ? delete edgeInterface['tracehost']['probes'][0]['max-rtt'] : null;
        delete edgeInterface['pppoeID'];
        const interJson = JSON.stringify(edgeInterface)
        const parsed = JSON.parse(interJson);
        ethList.push(parsed)
      }

      if (edgeInterface.type == 'loopback' && edgeInterface.name != 'Loopback+') {
        delete edgeInterface['loopbackID'];
        const interJson = JSON.stringify(edgeInterface)
        const parsed = JSON.parse(interJson);
        ethList.push(parsed)
      }

      //nat

      if (edgeInterface.type == 'nat') {
        if (this.isLegacyDevice) {
          edgeInterface["ipv4-prefix"] == "" ? delete edgeInterface["ipv4-prefix"] : null;
          edgeInterface["ipv6-prefix"] == "" ? delete edgeInterface["ipv6-prefix"] : null;
          edgeInterface["wan-interface"] && edgeInterface["wan-interface"].length == 0 ? delete edgeInterface["wan-interface"] : null;
        }

        const interJson = JSON.stringify(edgeInterface)
        const parsed = JSON.parse(interJson);
        ethList.push(parsed)
      }

      //wifi
      if (edgeInterface.type == 'wifi') {
        if (!this.smartProtocolExist){
          delete edgeInterface['smart-protocol-passthrough']
        }
        if (this.smartProtocolExist && !edgeInterface['smart-protocol-passthrough']?.[0].enable){
          delete edgeInterface['smart-protocol-passthrough']
        }
        edgeInterface['tracehost']['probes'] && [null, ''].includes(edgeInterface['tracehost']['probes'][0]['max-rtt']) ? delete edgeInterface['tracehost']['probes'][0]['max-rtt'] : null;
        const interJson = JSON.stringify(edgeInterface)
        const parsed = JSON.parse(interJson);
        ethList.push(parsed)
      }

      if (edgeInterface.type == 'ipsecvpn') {
        const interJson = JSON.stringify(edgeInterface)
        const parsed = JSON.parse(interJson);
        ethList.push(parsed)
      }
    })



    // ethList.push("port-mirroring:port-mirroring": port_mirrorings )



    return ethList;
  }

  ConcatAllInterface() {
    // var allInterfaces=[]

    //  var ethInterfaces=  this.convertEthInterfaceToJson(this.EthernetInterfaces);
    // {
    //   "bootproto": "dhcp",
    //   "enable": false,
    //   "ip-passthrough": false,
    //   "ipv4-prefix": "",
    //   "ipv6-prefix": "",
    //   "management": false,
    //   "metric": 120,
    //   "name": "wifi0",
    //   "nat": false,
    //   "radio-settings": {
    //     "band": "2.4GHz",
    //     "channel": 4,
    //     "country": "US",
    //     "transmit-power": 100
    //   },
    //   "security": {
    //     "hidden": false,
    //     "isolation": false,
    //     "key-mgmt": "AUTO",
    //     "mac-acl": "none",
    //     "mac-allow": [],
    //     "mac-deny": [],
    //     "max-clients": 255,
    //     "passphrase": ""
    //   },
    //   "ssid": "",
    //   "trace-host": "",
    //   "type": "wifi",
    //   "wifi-mode": "client",
    //   "wifi-wan-interface": []
    // },


    // ,
    //     {
    //       "ipv4-prefix": "",
    //       "link-state-propagation": true,
    //       "name": "mgmt0",
    //       "type": "ethernet"
    //     },
    //     {
    //       "config-mode": "pull",
    //       "enable": false,
    //       "ike-phase-1": {
    //         "dh-groups": [],
    //         "local": {
    //           "rounds": []
    //         },
    //         "proposals": [
    //           {
    //             "id": 1
    //           }
    //         ],
    //         "remote": {
    //           "rounds": []
    //         }
    //       },
    //       "ike-phase-2": {
    //         "dh-groups": [],
    //         "proposals": [
    //           {
    //             "id": 1
    //           }
    //         ]
    //       },
    //       "ike-version": "2",
    //       "install-virtual-ip": true,
    //       "management": true,
    //       "name": "vti0",
    //       "protocol-mode": "tunnel",
    //       "type": "ipsecvpn",
    //       "virtual-ip": "0.0.0.0"
    //     }

    // {
    //   "enable": false,
    //   "ipv4-prefix": "",
    //   "ipv6-prefix": "",
    //   "name": "nat4",
    //   "port-forwarding-all": true,
    //   "type": "nat",
    //   "wan-interface": []
    // }

    const ethInterfaces = this.convertEdgeInterfaceToJson(this.EdgeInterfaces);

    const leftInterfaces = [



    ]



    const allInterfaces = ethInterfaces.concat(leftInterfaces);

    return allInterfaces;
  }

  generateEthernetInterfacesFromHwType(hwType: HWType) {
    const interfaceCount = hwType.interfaces;
    // this.EthernetInterfaces=new Array<EthernetInterface>();
    for (let i = 0; i < interfaceCount; i++) {
      const ethx = new interfaceModel(i, 'none', false);
      ethx.name = `data${i}`;
      ethx.vswitch = `br-data${i}`;
      if (i == 0) {
        ethx.bootproto = 'dhcp';
        ethx.metric = 100;
        ethx.management = true;
      }
      //   this.EthernetInterfaces.push(ethx)
      this.EdgeInterfaces.push(ethx)
    }
  }


  generateLteInerfaces(count: number = 3) {
    const interfaceCount = count
    for (let i = 0; i < interfaceCount; i++) {
      const ltex = new Lte();
      ltex.name = `lte${i}`;
      ltex.enable = true;

      if (i == 1) {
        ltex.metric = 130;
      }

      if (i == 2) {
        ltex.enable = false;
        ltex.metric = 140;
      }

      this.EdgeInterfaces.push(ltex)
    }
  }


  generateWifiInerfaces(count: number = 1) {
    const interfaceCount = count
    for (let i = 0; i < interfaceCount; i++) {
      const wifix = new Wifi();
      wifix.name = `wifi${i}`;

      this.EdgeInterfaces.push(wifix)
    }
  }

  generateVpnInerfaces(count: number = 1) {
    const interfaceCount = count
    for (let i = 0; i < interfaceCount; i++) {
      const vpnx = new Vpn();
      vpnx.name = `tun${i}`;
      if (i > 0) {
        //vpnx.metric=ltex.metric+10; upset metrik?
      }
      this.EdgeInterfaces.push(vpnx)
    }
  }

  generateNatInerfaces(count: number = 5) {
    const interfaceCount = count
    for (let i = 0; i < interfaceCount; i++) {
      const natx = new Nat(i);
      // natx.name=`nat${i}`;

      this.EdgeInterfaces.push(natx)
    }
  }


  generateIPsecInerfaces(count: number = 1) {
    const interfaceCount = count
    for (let i = 0; i < interfaceCount; i++) {
      const ipsecx = new Ipsec();
      ipsecx.name = `vti${i}`;

      this.EdgeInterfaces.push(ipsecx)
    }
  }



  generateVxlanInerfaces() {
    const vxlan = new Vxlan();
    vxlan.vxlanID = 0;
    vxlan.name = 'VxLAN+'
    this.EdgeInterfaces.push(vxlan)
  }
  generateLoopbackInerfaces() {
    const loopback = new Loopback();
    loopback.loopbackID = '';
    loopback.name = 'Loopback+'
    this.EdgeInterfaces.push(loopback)

  }
  generatePPOEsecInerfaces() {
    const pppoe = new Pppoe();
    pppoe.pppoeID = '';
    pppoe.name = 'PPPoE+'
    this.EdgeInterfaces.push(pppoe)

  }
  generateMirroringInerfaces() {
    const mirroring = new Mirroring();
    mirroring.mirroringID = '';
    mirroring.name = 'Mirroring+'
    this.EdgeInterfaces.push(mirroring)

  }


  generateMngmtInterface() {

    const mngmt = new Mgmt()
    this.EdgeInterfaces.push(mngmt)

  }



}






