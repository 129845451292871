<div *ngIf='resourceService.isResourcesLoaded'>
    <mat-toolbar class="mat-toolbar-single-row">
        <button mat-icon-button (click)="toggleMainSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
        <img [src]="logPath" (click)="redirectToDashboard()">
        <div class="icons"> 
            <button mat-icon-button (click)="toggleLoginSidenav()">
                <mat-icon>person</mat-icon>
            </button>
            <button mat-icon-button (click)="redirectToAlertsMobile()">
                <mat-icon>notifications</mat-icon>
                <span class="badge-block" *ngIf="alertCount != 0">{{ alertCount }}</span>
            </button>
        </div>
    </mat-toolbar>
    <div class="overlay" [class.visible]="isOverlayVisible" (click)="overlayClick()"></div>
    <telco-sidenav-mobile #sidenavMobile 
        [isOverlayVisible]="isOverlayVisible"
        (sidenavStateChange)="updateOverlayState($event)">
    </telco-sidenav-mobile>
    <telco-alerts-dropdown-mobile 
        (alertCountChanged)="handleAlertCount($event)">
    </telco-alerts-dropdown-mobile>
    <telco-login-dropdown-mobile #MobileLoginDropdown 
        [isOverlayVisible]="isOverlayVisible"
        (loginSidenavStateChange)="updateOverlayState($event)">
    </telco-login-dropdown-mobile>
</div>