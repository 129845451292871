import {
    AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, ViewChild
} from "@angular/core";
import * as echarts from "echarts";
import { ECharts } from "echarts";

import { colors, EdgeStatuses } from "../../../../models";

@Component({
    selector: "telco-mobile-ucpe-charts",
    templateUrl: "./mobile-ucpe-charts.component.html",
    styleUrls: ["./mobile-ucpe-charts.component.scss"]
})
export class MobileUcpeChartsComponent implements OnChanges, AfterViewInit {
    @ViewChild("edgeChartSelector", { read: ElementRef, static: false }) edgeChartSelector: ElementRef;
    @Input() ucpeCharData: EdgeStatuses;
    @Input() typeChart: string = "";

    // TODO: Make the chart responsive min-height + width 100%
    // this.resizeObserver = new ResizeObserver(() => {
    //  this.myChart.resize();
    // });
    // this.resizeObserver.observe(this.edgeChartSelector.nativeElement);

    colors: typeof colors = colors;
    isExpanded = false;
    selectedLegend: object;
    chartName = "";
    chartHeader: string;
    myChart: ECharts;
    vnfTypesSum = 0;
    convertedNameValueArray: object;

    ucpeChart: any;

    constructor(private ngZone: NgZone) {}

    ngOnChanges(): void {
        if (this.ucpeCharData) {
            this.ngZone.runOutsideAngular(() => {
                this.initHeaderChart();
                this.initChart();
            });
        }
    }

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.myChart = echarts.init(this.edgeChartSelector.nativeElement);
            this.resizeChart();
            this.setupEventHandlers();
        });
    }

    setupEventHandlers(): void {
        this.myChart.on("click", this.onChartClick.bind(this));
    }

    initHeaderChart(): void {
        const chartMapping = {
            ucpe: {
                chartHeader: "Edge Operational\nStatuses",
                chartName: "operStatuses"
            },
            controller: {
                chartHeader: "Controller Operational\nStatuses",
                chartName: "controllerStatuses"
            }
        };

        if (this.typeChart && chartMapping[this.typeChart]) {
            this.chartHeader = chartMapping[this.typeChart].chartHeader;
            this.chartName = chartMapping[this.typeChart].chartName;
        }
    }

    calculateInit(edgeData: any): number {
        const keysOfInterest = [
            "busy", "running", "unreachable", "starting", "rebooting",
            "degraded", "unlicensed", "upgrading", "not contacted", "stopping"
        ];

        return keysOfInterest.reduce((acc, key) => acc + (edgeData[key] || 0), 0);
    }

    recenter(value: number) {
        const thresholds = [
            { max: 10, percent: "17.5%" },
            { max: 100, percent: "16%" },
            { max: 1000, percent: "14.5%" },
            { max: 10000, percent: "13.5%" },
            { max: 100000, percent: "12.5%" },
            { max: 1000000, percent: "11.5%" }
        ];

        const foundThreshold = thresholds.find((element) => value < element.max);
        return foundThreshold ? foundThreshold.percent : "17.5%";
    }

    convertNameValueArray(chartData: EdgeStatuses) {
        const statusColorMap = {
            busy: { name: "Configuring", color: colors.LIGHT_BLUE },
            running: { name: "Running", color: colors.GREEN },
            unreachable: { name: "Unreachable", color: colors.RED },
            starting: { name: "Starting", color: colors.YELLOW },
            rebooting: { name: "Rebooting", color: colors.ORANGE },
            unlicensed: { name: "Unlicensed", color: colors.PINK },
            upgrading: { name: "Upgrading", color: colors.BLUE },
            degraded: { name: "Degraded", color: colors.VIOLET },
            "not contacted": { name: "Not contacted", color: colors.GRAY },
            stopping: { name: "Stopping", color: colors.OLIVE }
        };

        const newArrayData = Object.keys(statusColorMap)
            .filter((status) => chartData[status])
            .map((status) => ({
                name: statusColorMap[status].name,
                value: chartData[status],
                itemStyle: { color: statusColorMap[status].color }
            }));

        this.resizeChart();
        this.convertedNameValueArray = newArrayData;
        return newArrayData;
    }

    onChartClick() {
        this.toggleExpandState();
    }

    toggleExpandState(): void {
        this.isExpanded = !this.isExpanded;
        this.ngZone.runOutsideAngular(() => {
            this.initChart();
        });
    }

    public initChart(): void {
        let newChartData: EdgeStatuses = new EdgeStatuses();
        newChartData = this.ucpeCharData;

        const initCount = this.calculateInit(newChartData);
        this.ucpeChart = {
            title: [{
                text: initCount,
                top: "center",
                left: this.recenter(initCount),
                textStyle: {
                    fontSize: 12,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            {
                text: this.chartHeader,
                top: "center",
                left: 120,
                textStyle: {
                    fontSize: 14,
                    align: "center",
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                },
            }],
            tooltip: {
                trigger: "item",
                textStyle: {
                    color: colors.TEXT,
                    fontFamily: colors.FONT,
                }
            },
            legend: {
                show: false
            },
            series: [{
                type: "pie",
                radius: ["35%", "70%"],
                center: ["20%", "50%"],
                label: {
                    show: true,
                    color: colors.TEXT,
                    position: "inner",
                    fontFamily: colors.FONT,
                    formatter: (params) => params.value
                },
                labelLine: {
                    normal: {
                        show: false,
                    }
                },
                hoverAnimation: false,
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: "#fff",
                },
                silent: true,

                data: this.convertNameValueArray(this.ucpeCharData)
            }]
        };
    }

    resizeChart() {
        if (this.myChart) {
            this.myChart.resize({ width: 300, height: 150 });
        }
    }
}
