import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs/';
import { map } from 'rxjs/operators';
import { save } from '../components/helpers/disable-save.interceptor';
import { CommandResponse } from '../models/commandResponse';
import { Controller } from '../models/controller';
import { ControllerImage } from '../models/ControllerImage';
import { HistoryResponse } from '../models/historyResponse';
import { KubernetesService } from '../models/KubernetesService';
import { ServiceTemplate } from '../models/ServiceTemplate';
import { Response } from '../models/types/common';
import { ResourceService } from './resource.service';

const headers = new HttpHeaders().set(
  'Content-Type',
  'application/text; charset=utf-8'
);

@Injectable()
export class KuberService {
  controllers: Controller[];

  images: ControllerImage[];

  constructor(private resourceService: ResourceService, private http: HttpClient, private route: ActivatedRoute
  ) { }

  //K8s Images
  getServiceImages(): Observable<ControllerImage[]> {
    return this.http.get<ControllerImage[]>(
      this.resourceService.getApiUrl() +
      '/k8s/image?customer_id=0&page=1&count=20&id_only=false'
    );
  }

  getServiceImagesByType(imageType: 'VM' | 'Pod', customer_id: number): Observable<ControllerImage[]> {
    return this.http.get<ControllerImage[]>(
      this.resourceService.getApiUrl() +
      `/k8s/image?customer_id=${customer_id}&page=1&count=200&id_only=false&image_type=${imageType}`
    );
  }

  getServiceImagesAdvanced(id_only: boolean, page: number, count: number, order: string, direction: string, filterData: any, controllerID?): Observable<ControllerImage[]> {
    let requestString = `/k8s/image/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    if (controllerID) {
      requestString += `&controller_id=${controllerID}`;
    }
    return this.http.post<ControllerImage[]>(
      this.resourceService.getApiUrl() + requestString,
      { controller_id: controllerID, id_only, filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData }
    );
  }

  getServiceImageById(imageId: number): Observable<ControllerImage> {
    return this.http.get<ControllerImage>(
      this.resourceService.getApiUrl() + '/k8s/image/' + imageId
    );
  }

  createControllerImage(controlerImage: ControllerImage, file?: File) {
    const context = new HttpContext().set(save, true);
    const formData: FormData = new FormData();
    formData.append('jsonData', JSON.stringify(controlerImage));
    file ? formData.append('fileName', file, file.name) : null;
    return this.http.put(this.resourceService.getApiUrl() + '/k8s/image', formData, { context, reportProgress: true, observe: 'events' });
  }

  updateControllerImage(imageId: number, controlerImage: ControllerImage, file: File) {
    const context = new HttpContext().set(save, true);
    const formData: FormData = new FormData();
    formData.append('jsonData', JSON.stringify(controlerImage));

    if (file != undefined) {
      formData.append('fileName', file, file.name);
    }

    return this.http.post(
      this.resourceService.getApiUrl() + '/k8s/image/' + imageId,
      formData,
      { context, reportProgress: true, observe: 'events' }
    );
  }

  deleteControllerImage(imageId: number): Observable<any> {
    return this.http.delete(
      this.resourceService.getApiUrl() + '/k8s/image/' + imageId
    );
  }

  deleteControllerImages(controllerImages: number[]): Observable<any> {
    return this.http.post(
      this.resourceService.getApiUrl() + '/k8s/image/advanced',
      { delete: controllerImages }
    );
  }

  detachedImages(page: number, count: number, imageListToAttache: number[], controller_ids: number[], observe = 'body' as any, openstackMode: boolean = false) {
    return this.http.post(this.resourceService.getApiUrl() + `/${openstackMode ? 'os' : 'k8s'}/image/advanced?page=${page}&count=${count}`,
      { detach: imageListToAttache, controller_list: controller_ids },
      { observe }
    );
  }

  attachedImages(page: number, count: number, imageListToDetache: number[], controller_ids: number[], observe = 'body' as any, openstackMode: boolean = false) {
    return this.http.post(
      this.resourceService.getApiUrl() +
      `/${openstackMode ? 'os' : 'k8s'}/image/advanced?page=${page}&count=${count}`,
      { attach: imageListToDetache, controller_list: controller_ids },
      { observe }
    );
  }

  storeImages(imageIds: Array<ControllerImage>) {
    this.images = imageIds;
  }

  getstoredImages() {
    return this.images;
  }

  clearImages() {
    this.images = [];
  }

  storeControllers(controllers: Array<Controller>) {
    this.controllers = controllers;
  }

  getStoredControllers() {
    return this.controllers;
  }

  clearStoredControllers() {
    this.controllers = [];
  }

  exportServiceImage(serviceImageId: number, serviceImageName: string): Observable<any> {
    return this.http
      .get(
        this.resourceService.getApiUrl() +
        `/k8s/image/package/${serviceImageId}`,
        { responseType: 'blob', observe: 'response' }
      )
      .pipe(
        map((result: HttpResponse<Blob>) => {
          console.log(result);
          saveAs(
            new File([ result['body'] ], `${serviceImageName}`, {
              type: result['body']['type'],
            })
          );
          return result;
        })
      );
  }

  getControllerPods(controllerId: number): Observable<string> {
    return this.http.get(
      this.resourceService.getApiUrl() + `/controller/${controllerId}/k8s/pods`,
      { headers, responseType: 'text' }
    );
  }

  //K8s Templates
  getServiceTemplates(customer_id: number = undefined, id_only: boolean = false): Observable<ServiceTemplate[]> {
    let requestString = `/k8s/template?page=1&count=5000&id_only=${id_only}`
    if (customer_id) {
      requestString += `&customer_id=${customer_id}`;
    }

    return this.http.get<ServiceTemplate[]>(this.resourceService.getApiUrl() + requestString);
  }




  getServiceTemplatesAdvanced(id_only: boolean, page: number, count: number, order: string, direction: string, filterData: any
  ): Observable<Response<ServiceTemplate>> {
    const requestString = `/k8s/template/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    return this.http.post<Response<ServiceTemplate>>(this.resourceService.getApiUrl() + requestString, {
      id_only,
      filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData
    });
  }

  getServiceTemplateById(templateId: number): Observable<ServiceTemplate> {
    return this.http.get<ServiceTemplate>(this.resourceService.getApiUrl() + '/k8s/template/' + templateId);
  }

  /**
   template from Templates Catalog but with modified parameters(by BE),
   according selected UCPE
   */
  getServiceTemplateByIdAndNodeId(templateId: number, node_id: number): Observable<ServiceTemplate> {
    let reqString = `/k8s/template/${templateId}`;
    if (node_id) {
      reqString += `?node_id=${node_id}`;
    }

    return this.http.get<ServiceTemplate>(this.resourceService.getApiUrl() + reqString);
  }

  getServiceTemplatesByNodeId(node_id: number, customer_id: number = 0, page: number = 1, count: number = 0, order: string = 'name', id_only: boolean = false): Observable<ServiceTemplate[]> {
    const requestString = `/k8s/template?customer_id=${customer_id}&page=${page}&count=${count}&order=${order}&id_only=${id_only}&node_id=${node_id}`
    return this.http.get<ServiceTemplate[]>(this.resourceService.getApiUrl() + requestString);
  }

  createServiceTemplate(serviceTemplate: ServiceTemplate, saveDisable: boolean = false) {
    const context = new HttpContext().set(save, saveDisable);
    return this.http.put(
      this.resourceService.getApiUrl() + '/k8s/template',
      serviceTemplate,
      { context }
    );
  }

  updateServiceTemplate(templateId: number, serviceTemplate: ServiceTemplate, saveDisable: boolean = false) {
    const context = new HttpContext().set(save, saveDisable);
    return this.http.post(
      this.resourceService.getApiUrl() + '/k8s/template/' + templateId,
      serviceTemplate,
      { context }
    );
  }

  deleteServiceTemplate(templateId: number): Observable<any> {
    return this.http.delete(
      this.resourceService.getApiUrl() + '/k8s/template/' + templateId
    );
  }

  deleteServiceTemplates(templatesList: number[]): Observable<any> {
    return this.http.post(
      this.resourceService.getApiUrl() + '/k8s/template/advanced',
      { delete: templatesList }
    );
  }

  cloneServiceTemplate(templateId: number) {
    return this.http.post(this.resourceService.getApiUrl() + `/k8s/template/${templateId}/clone`, null);
  }

  //kuber services
  getKuberServices(customer_id: number = 0, page: number = 1, count: number = 20, id_only: boolean = false
  ): Observable<any[]> {
    return this.http.get<KubernetesService[]>(
      this.resourceService.getApiUrl() +
      `/k8s/service?customer_id=${customer_id}&page=${page}&count=${count}&id_only=${id_only}`
    );
  }

  getKuberServiceById(kuberServiceId: number): Observable<KubernetesService> {
    return this.http.get<KubernetesService>(
      this.resourceService.getApiUrl() + '/k8s/service/' + kuberServiceId
    );
  }


  getKuberServicesAdvanced(id_only: boolean, page: number, count: number, order: string, direction: string, filterData: any, id?: any): Observable<any> {
    let requestString = `/node/k8s/service/advanced?page=${page}&count=${count}&order=${order}&direction=${direction}`;
    if (id) {
      requestString += `&node_id=${id}`;
    }
    return this.http.post(
      this.resourceService.getApiUrl() + requestString,
      { id_only, filter: (filterData[0] && Object.keys(filterData[0]).length == 1) ? [] : filterData }
    );
  }

  startKuberServicesAdvanced(serviceIds: Array<number>): Observable<any> {
    const requestString = `/node/k8s/service/advanced`;
    return this.http.post(this.resourceService.getApiUrl() + requestString, { start: serviceIds }, { observe: 'body' });
  }

  stopKuberServicesAdvanced(serviceIds: Array<number>): Observable<KubernetesService[]> {
    const requestString = `/node/k8s/service/advanced`;
    return this.http.post<KubernetesService[]>(this.resourceService.getApiUrl() + requestString, { stop: serviceIds }, { observe: 'body' });
  }

  deleteKuberServicesAdvanced(serviceIds: Array<number>): Observable<KubernetesService[]> {
    const requestString = `/node/k8s/service/advanced`;
    return this.http.post<KubernetesService[]>(
      this.resourceService.getApiUrl() + requestString,
      { delete: serviceIds },
      { observe: 'body' }
    );
  }

  getKuberServicesByNodeId(nodeId: number): Observable<KubernetesService> {
    return this.http.get<KubernetesService>(
      this.resourceService.getApiUrl() + '/node/k8s/service/' + nodeId
    );
  }

  createKuberService(kubernetesService: KubernetesService, nodeId: number) {
    return this.http.put(
      this.resourceService.getApiUrl() + `/node/k8s/service/${nodeId}`,
      kubernetesService
    );
  }

  createKuberServiceBulk(
    kuberServiceIds: number[],
    k8s_service: KubernetesService
  ) {
    return this.http.post(
      `${this.resourceService.getApiUrl()}/node/k8s/service/advanced?page=1&count=20&id_only=false`,
      { deploy: kuberServiceIds, k8s_service }
    );
  }

  modifyKuberService(kubernetesService: KubernetesService, nodeId: number, k8s_service_id: number, saveDisable: boolean = false) {
    const context = new HttpContext().set(save, saveDisable);
    return this.http.post(
      this.resourceService.getApiUrl() + `/node/k8s/service/${nodeId}/${k8s_service_id}`,
      kubernetesService,
      { context }
    );
  }

  getKuberServiceByNodeServiceId(
    nodeId: number,
    k8serviceId: number
  ): Observable<KubernetesService> {
    return this.http.get<KubernetesService>(
      this.resourceService.getApiUrl() +
      `/node/k8s/service/${nodeId}/${k8serviceId}`
    );
  }

  deleteKuberServiceByNodeServiceId(
    nodeId: number,
    k8serviceId: number
  ): Observable<KubernetesService> {
    return this.http.delete<KubernetesService>(
      this.resourceService.getApiUrl() +
      `/node/k8s/service/${nodeId}/${k8serviceId}`
    );
  }

  startKuberServiceByNodeServiceId(
    nodeId: number,
    k8serviceId: number
  ): Observable<KubernetesService> {
    return this.http.post<KubernetesService>(this.resourceService.getApiUrl() + `/node/k8s/service/${nodeId}/${k8serviceId}/start`, null);
  }

  stopKuberServiceByNodeServiceId(
    nodeId: number,
    k8serviceId: number
  ): Observable<KubernetesService> {
    return this.http.post<KubernetesService>(this.resourceService.getApiUrl() + `/node/k8s/service/${nodeId}/${k8serviceId}/stop`, null);
  }

  getK8ServiceDescribeByNodeServiceId(
    nodeId: number,
    k8serviceId: number
  ): Observable<string> {
    return this.http.get(
      this.resourceService.getApiUrl() +
      `/node/k8s/service/${nodeId}/${k8serviceId}/describe`,
      { headers, responseType: 'text' }
    );
  }

  getK8ServiceLogsByNodeServiceId(
    nodeId: number,
    k8serviceId: number
  ): Observable<string> {
    return this.http.get(
      this.resourceService.getApiUrl() +
      `/node/k8s/service/${nodeId}/${k8serviceId}/logs`,
      { headers, responseType: 'text' }
    );
  }

  getVMConsoleNames(serviceId: number) {
    return this.http.get<any>(
      this.resourceService.getApiUrl() + `/k8s/service/${serviceId}/vm`
    );
  }

  getConsoleUrl(nodeId: number, serviceId: number, vmName: string) {
    return this.http.get(
      this.resourceService.getApiUrl() +
      `/node/k8s/service/${nodeId}/${serviceId}/console/${vmName}`,
      { headers, responseType: 'text' }
    );
  }

  executePodCommand(nodeId: number, k8serviceId: number, podName: string, container: string, command: string): Observable<CommandResponse[]> {
    return this.http.post<CommandResponse[]>(
      this.resourceService.getApiUrl() + `/node/k8s/service/${nodeId}/${k8serviceId}/commands/${podName}/${container}`, command
    );
  }

  getPodHistory(nodeId: number, k8serviceId: number, podName: string, container: string): Observable<HistoryResponse[]> {
    return this.http.get<HistoryResponse[]>(
      this.resourceService.getApiUrl() +
      `/node/k8s/service/${nodeId}/${k8serviceId}/commands/${podName}/${container}/history`
    );
  }


  detachedFlavors(page: number, count: number, flavorsListToDetache: number[], controller_ids: number[], observe = 'body' as any) {
    return this.http.post(this.resourceService.getApiUrl() + `/os/flavor/advanced?page=${page}&count=${count}`,
      { detach: flavorsListToDetache, controller_list: controller_ids },
      { observe }
    );
  }

  getTemplatePreview(k8sTemplate: ServiceTemplate) {
    return this.http.put(this.resourceService.getApiUrl() + `/k8s/template/preview`, k8sTemplate);
  }

}
