<div *ngIf='resourceService.isResourcesLoaded'>
	<mat-toolbar #toolbarRef>
		<div class="imgWrapper" (click)="navigateToDashboard()">
			<img [src]="logPath" weight="150" height="150">
		</div>
		<div class="edgility-text">{{themeService.title || 'Edge'}} Management and Orchestration</div>
	
		<div class="right-section">
			<app-login-dropdown></app-login-dropdown>
			<app-alerts-dropdown></app-alerts-dropdown>
			<telco-user-operations-dropdown></telco-user-operations-dropdown>
		</div>
	</mat-toolbar>
</div>