<button mat-icon-button class="badge-block" [matMenuTriggerFor]="menu" #t="matMenuTrigger">
  <mat-icon
    [matTooltipShowDelay]="800"
    matTooltipClass="tooltipLook"
    matTooltip="User operations"
    [matBadge]="pageCount > 0 ? pageCount : null"
    matBadgeOverlap="false">
    play_circle_outline
  </mat-icon>
</button>
<mat-menu #menu="matMenu" [class]="'alertDropDown'">
  <mat-card appearance="outlined" *ngIf='pageCount != 0'>
    <div style=" display: flex; flex-direction: column;position: relative;">
      <div (click)="$event.stopPropagation()" style="max-height: 40rem; width: 90rem; overflow-x: hidden;">
        <telco-custom-dropdown-table #table [initialColumns]="initialColumns" [pageCount]='pageCount' [dataSource]="tableData$"
          (newItemEvent)="getSelectedElement($event)" (sortChangeEvent)="onSortChanged($event)"
          (allItemCheckedEvent)="isAllCheckboxCheckedControl($event)"
          (pageChangeEvent)="onPageChanged($event)"></telco-custom-dropdown-table>
      </div>
      <div style="margin-top: 1rem; display: flex; justify-content: end;">
        <button *ngIf="showAckBtn" class="acknowledgeButton" mat-flat-button (click)="acknowledgeAlarm($event)" [disabled]="!table.selection.selected.length">
          <mat-icon>assignment_turned_in</mat-icon>
          Acknowledge
        </button>
        &nbsp;
        <button class="clearButton" mat-flat-button (click)="cancelAction($event)" [disabled]="!table.selection.selected.length">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>
        &nbsp;
      </div>
    </div>
  </mat-card>

  <mat-card *ngIf='pageCount == 0'>
    <p style="font-size: 1.4rem;">No active user operations</p>
  </mat-card>
</mat-menu>
