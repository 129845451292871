import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-acknowledge-dialog',
  templateUrl: './acknowledge-dialog.component.html',
  styleUrls: ['./acknowledge-dialog.component.scss']
})
export class AcknowledgeDialogComponent{

  message: string = ""
  alert_type:string=""
  confirmButtonText= ""
  cancelButtonText = ""

  acknowledge_reason=''

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AcknowledgeDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.alert_type = data.alert_type || this.alert_type;

      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        if(data.buttonText.confirm){
          this.confirmButtonText=data.buttonText.confirm
        }

        if(data.buttonText.cancel){
          this.cancelButtonText=data.buttonText.cancel
        }
      }

    }
    // this.dialogRef.updateSize('300vw','300vw')

    this.dialogRef.afterClosed().subscribe();
  }
}